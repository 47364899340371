import {PayloadAction, createSlice} from '@reduxjs/toolkit'
import {SettingReferenceParamsModel} from '../models/SettingReferenceParamsModel'
import {SettingReferenceModel} from '../models/SettingReferenceModel'
import {FeatureModel} from '../models/FeatureModel'
import {SimpleListModel} from '../../../shared/models/SimpleListModel'
import {ListResponseModel} from '../../../shared/models/ListResponseModel'
import {toast} from 'react-toastify'
import {RootState} from '../../../../setup/redux/Store'
import {SettingReferenceStatus} from '../constants'

export interface ISettingReferenceState {
  list: SettingReferenceModel[]
  filter: SettingReferenceParamsModel
  loading: boolean
  serviceList: SimpleListModel[]
  categoryList: SimpleListModel[]
  featureList: FeatureModel[]
  current: SettingReferenceModel | undefined
}

const initialState: ISettingReferenceState = {
  list: [],
  filter: {
    name: undefined,
    categoryId: undefined,
    serviceId: undefined,
    page: 1,
    take: Number(process.env.REACT_APP_DEFAULT_PAGE_SIZE),
    itemCount: 0,
    pageCount: 1,
  } as SettingReferenceParamsModel,
  loading: false,
  serviceList: [],
  categoryList: [],
  featureList: [],
  current: undefined,
}

export const settingReferenceSlice = createSlice({
  name: 'settingReference',
  initialState,
  reducers: {
    getAll: (state, action: PayloadAction<any | undefined>) => {
      state.loading = true
    },
    getCurrent: (state, action: PayloadAction<string>) => {
      state.loading = true
    },
    setCurrent: (state, action: PayloadAction<SettingReferenceModel|undefined>) => {
      state.loading = false
      state.current = action.payload
    },
    setList: (state, action: PayloadAction<ListResponseModel<SettingReferenceModel>>) => {
      state.loading = false
      state.list = action.payload.data
      state.filter = action.payload.meta
    },
    filterDataChange: (state, action: PayloadAction<SettingReferenceParamsModel>) => {
      state.loading = true
      state.filter = action.payload
    },
    setCategories: (state, action: PayloadAction<SimpleListModel[]>) => {
      state.loading = false
      state.categoryList = action.payload
    },
    setServices: (state, action: PayloadAction<SimpleListModel[]>) => {
      state.loading = false
      state.serviceList = action.payload
    },
    updateServiceStatus: (
      state,
      action: PayloadAction<{serviceId: number; newStatus: SettingReferenceStatus}>
    ) => {},
    setFeatures: (state, action: PayloadAction<FeatureModel[]>) => {
      state.loading = false
      state.featureList = action.payload
    },
    getAllServices: (state, action: PayloadAction<boolean>) => {
      state.loading = true
    },
    getServiceCategories: (state, action: PayloadAction<string>) => {
      state.loading = true
    },
    getAllFeatureForService: (state, action: PayloadAction<string>) => {
      state.loading = true
    },
    createSetting: (state, action: PayloadAction<SettingReferenceModel>) => {
      state.loading = true
    },
    updateSetting: (state, action: PayloadAction<SettingReferenceModel>) => {
      state.loading = true
    },
    setError: (state, action: PayloadAction<string>) => {
      state.loading = false
      toast.error(action.payload)
    },
    setSuccess: (state, action: PayloadAction<string>) => {
      state.loading = false
      toast.success(action.payload)
    },
  },
})
export const {
  setSuccess,
  getAll,
  setList,
  getCurrent,
  setCurrent,
  updateServiceStatus,
  filterDataChange,
  setCategories,
  setServices,
  setFeatures,
  getAllServices,
  setError,
  getServiceCategories,
  getAllFeatureForService,
  createSetting,
  updateSetting,
} = settingReferenceSlice.actions
export const selectSettingReferenceLoading = (state: RootState) => state.settingReference.loading
export const selectSettingReferenceList = (state: RootState) => state.settingReference.list
export const selectSettingReferenceFilter = (state: RootState) => state.settingReference.filter
export const selectServicesList = (state: RootState) => state.settingReference.serviceList
export const selectCategoriesList = (state: RootState) => state.settingReference.categoryList
export const selectFeatureList = (state: RootState) => state.settingReference.featureList
export const selectCurrentSettingReference = (state: RootState) => state.settingReference.current

export default settingReferenceSlice.reducer
