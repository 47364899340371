export enum OrderDownloadType {
    OUTPUT = 'output',
    ALL = 'all',
    SELECTED = 'selected',
}
export enum OrderPaymentMethod {
    CREDIT = 'credit',
    PAYPAL = 'paypal',
}
export enum OrderType {
    AI_ONLY = 'ai_only',
    PROFESSIONAL = 'professional',
}
export enum ImageThumbnailSize {
    SMALL = 'tiny',
    MEDIUM = 'small',
    LARGE = 'medium',
}
export enum OrderStatus {
    UPLOADED = 1,
    AI_PROCESSING = 2,
    PENDING = 3,
    REJECTED = 4,
    PROCESSING = 5,
    COMPLETED = 6,
    AI_FAILED = 7,
    Analyzing = 8
}

export enum OrderSettingType {
    PRE_SETTING = "1",
    ADVANCE = "2",

}
export enum OrderPriority {
    HIGH = 1,
    MEDIUM = 2,
    LOW = 3,
}
export enum ItemOptionStatus {
    ACTIVE = 1,
    INACTIVE = 2,
}
export enum ItemPriority {
    HIGH = 1,
    MEDIUM = 2,
    LOW = 3,
}

export type OrderStatusModel = {
    key: React.Key
    displayValue: string
}

export enum OrderActionType {
    Download = 1,
    Retry = 2
}
export enum OrderDisplayMode {
    List = 1,
    Grid = 2
}

export enum DownloadStatus {
    DOWNLOADING = 'DOWNLOADING',
    DONE = 'DONE',
    FAIL = 'FAIL',
}

export enum ItemStatus {
    UPLOADED = 1,
    AI_PROCESSING = 2,
    PENDING = 3,
    EDITING = 4,
    REVIEWING = 5,
    DONE = 6,
    FAIL = 7,
    ANALYZING = 8,
}

export enum PaymentStatus {
    NOT_PAID = 1,
    PAID = 2,
    FREE = 3,
    PARTIAL_PAID = 4,
}

export enum DIMENSIONS_OPTIONS {
    ORIGINAL = 'original',
    CROP = 'crop',
    LONG_EDGE = 'longEdge',
    SHORT_EDGE = 'shortEdge'
}

export const MAX_DIMENSIONS_SIZE = 20000
export const MIN_DIMENSIONS_SIZE = 1
export enum ORDER_ACTION_VIEW_MODE {
    MINIFY = 1,
    FULL = 2
}

export const PROFESSIONAL_RETOUCH_JP_PRICE = 40
export const PROFESSIONAL_RETOUCH_US_PRICE = 0.3
export const TIMEOUT_SHOW_ADS = 3000

export const ORDER_NAME_FORMAT = 'DDMMYY_hhmm_a'

export const ORDER_IMAGE_SUPPORTED_TYPE = ['image/png', 'image/jpeg', 'image/jpg']