import {FC, useEffect, useRef} from 'react'
import {useDispatch} from 'react-redux'
import {useAppSelector} from '../../../../setup/redux/hooks'
import {
  changeOrderDisplayMode,
  filterDataChange,
  getAll,
  getOrderStatusList,
  selectOrderFilter,
  selectOrderStatus,
} from '../redux/OrderRedux'
import {SimpleListModel} from '../../../shared/models/SimpleListModel'
import {OrderListFilterModel} from '../models/OrderListFilterModel'
import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'
import {Link} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {ALL_KEY} from '../../../shared/constants'

type Props = {
  className?: string
}
const OrderTableFilter: FC<Props> = () => {
  const intl = useIntl()
  const didRequest = useRef(false)
  const dispatch = useDispatch()
  const orderStatus: SimpleListModel[] = useAppSelector(selectOrderStatus)
  const orderFilter: OrderListFilterModel = useAppSelector(selectOrderFilter)
  useEffect(() => {
    if (!didRequest.current) {
      dispatch(getOrderStatusList())
      didRequest.current = false
    }
  }, [])
  const handleSearchTextChanged = (e?: any | undefined) => {
    if (e) {
      const value = e.target.value
      dispatch(filterDataChange({...orderFilter, text: value, page: 1}))
    } else {
      dispatch(getAll())
    }
  }
  const handleOrderStatusChanged = (e: any) => {
    const value = e.target.value
    if (value > 0) {
      dispatch(filterDataChange({...orderFilter, status: value, page: 1}))
    } else {
      dispatch(filterDataChange({...orderFilter, status: undefined, page: 1}))
    }
  }
  const handleViewModeChange = () => {
    dispatch(changeOrderDisplayMode())
  }
  return (
    <div className='card-header border-0 pt-3'>
      <div className='d-flex align-items-center'>
        <div className='d-flex align-items-center position-relative me-4'>
          <span className='position-absolute cursor-pointer ms-3' onClick={handleSearchTextChanged}>
            <KTSVG path='/media/icons/duotune/general/gen021.svg' className='svg-icon-3' />
          </span>
          <input
            type='text'
            id='kt_filter_search'
            className='form-control form-control-white form-control-sm w-150px ps-9'
            placeholder='Search'
            onChange={handleSearchTextChanged}
            onKeyDown={(e: any) => {
              if (e.key === 'Enter') {
                handleSearchTextChanged()
              }
            }}
          />
        </div>
      </div>
      <div className='card-toolbar'>
        <div className='d-flex my-2'>
          <select
            name='status'
            data-hide-search='true'
            className='form-select form-select-white form-select-sm w-125px me-4'
            defaultValue={0}
            onChange={handleOrderStatusChanged}
          >
            <option value={ALL_KEY}>{intl.formatMessage({id: 'SYSTEM.ALL'})}</option>
            {orderStatus &&
              orderStatus.map((item: SimpleListModel) => (
                <option key={item.id} value={item.id}>
                  {intl.formatMessage({id: item.label.toUpperCase()})}
                </option>
              ))}
          </select>

          <Link to='#' className='ms-5 mb-3 align-self-end' onClick={handleViewModeChange}>
            <KTSVG
              path='/media/icons/duotune/general/gen008.svg'
              className=' svg-icon svg-icon-1x svg-icon-dark'
            />
          </Link>
        </div>
      </div>
    </div>
  )
}
export default OrderTableFilter
