/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import ReactPaginate from 'react-paginate'

interface Props {
  currentPage?: number
  totalPages: number
  onPageChange: (current: number) => void
}

const Paginator = (props: Props) => {
  const {totalPages, onPageChange, currentPage: current} = props
  const [currentPage, setCurrentPage] = useState(current)

  const handlePageClick = (e: any) => {
    setCurrentPage(e.selected + 1)
    if (onPageChange) {
      onPageChange(e.selected + 1)
    }
  }

  useEffect(() => {
    current && setCurrentPage(current)
  }, [current])

  if (totalPages <= 1) {
    return <></>
  }

  return (
    <ReactPaginate
      nextLabel={<i className='next'></i>}
      previousLabel={<i className='previous'></i>}
      onPageChange={handlePageClick}
      marginPagesDisplayed={0}
      pageRangeDisplayed={5}
      pageCount={totalPages}
      forcePage={(currentPage || 1) - 1}
      breakLabel=''
      pageClassName='page-item'
      pageLinkClassName='page-link'
      previousClassName='page-item'
      previousLinkClassName='page-link'
      nextClassName='page-item'
      nextLinkClassName='page-link'
      breakClassName='page-item'
      breakLinkClassName='page-link'
      containerClassName='pagination'
      activeClassName='active'
    />
  )
}

export default Paginator
