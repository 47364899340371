import { OrderListFilterModel } from '../models/OrderListFilterModel'
import { OrderModel } from '../models/OrderModel'
import { cloneDeep, set } from 'lodash'
import { RootState } from '../../../../setup/redux/Store'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ListResponseModel } from '../../../shared/models/ListResponseModel'
import { SimpleListModel } from '../../../shared/models/SimpleListModel'
import { ItemStatus, OrderDisplayMode, OrderStatus } from '../constants'
import { OrderRequestModel } from '../models/OrderRequestModel'
import { toast } from 'react-toastify'
import { OrderItemModel } from '../models/OrderItemModel'
import { PaginateModel } from '../../../shared/models/PaginateModel'
import { OrderDownloadRequestModel } from '../models/orderDownloadRequestModel'
import { OrderActionModel } from '../models/OrderActionModel'
import { OrderDownloadModel } from '../models/OrderDownloadModal'
import { intl } from '../../../../_metronic/i18n/i18nProvider'
export interface IOrderState {
    loading: boolean
    list: OrderModel[]
    filter: OrderListFilterModel
    current: OrderModel | undefined
    orderStatus: SimpleListModel[]
    pendingOrders: OrderModel[]
    displayMode: OrderDisplayMode
    isShowOrderSuccess: boolean
    asyncWorkingOrders: OrderActionModel[]
    currentOrderItems: OrderItemModel[]
    currentOrderItemFilter: PaginateModel
    selectMode: boolean
    selectedItems: number[]
    currentDownloadCredit: OrderDownloadModel | undefined
    isAiOverLoad: boolean
}
const initialState: IOrderState = {
    list: [],
    asyncWorkingOrders: [],
    loading: false,
    pendingOrders: [],
    currentOrderItems: [],
    isAiOverLoad: false,
    displayMode: OrderDisplayMode.Grid,
    filter: {
        text: undefined,
        serviceId: undefined,
        status: undefined,
        page: 1,
        take: Number(process.env.REACT_APP_DEFAULT_PAGE_SIZE),
        itemCount: 0,
        pageCount: 1,
    } as OrderListFilterModel,
    current: undefined,
    orderStatus: [],
    isShowOrderSuccess: false,
    currentOrderItemFilter: {
        page: 1,
        take: Number(process.env.REACT_APP_DEFAULT_PAGE_SIZE),
        itemCount: 0,
        pageCount: 1,
    },
    selectMode: false,
    selectedItems: [],
    currentDownloadCredit: undefined
}

export const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        getAiServiceStatus: (state) => { },
        setAiServiceStatus: (state, action: PayloadAction<boolean>) => {
            state.isAiOverLoad = action.payload
        },
        getCurrentDownloadOrderCredit: (state, action: PayloadAction<{ orderId: number }>) => {

        },
        setCurrentDownloadOrderCredit: (state, action: PayloadAction<OrderDownloadModel | undefined>) => {
            state.currentDownloadCredit = action.payload
        },
        setSelectMode: (state, action: PayloadAction<boolean>) => {
            state.selectMode = action.payload
            state.selectedItems = []
        },
        setSelectedItem: (state, action: PayloadAction<{ id: number, isAdd: boolean }>) => {
            let selectedItems = cloneDeep(state.selectedItems)
            const index = selectedItems.indexOf(action.payload.id)
            const isExist = index >= 0
            if (!isExist && action.payload.isAdd) {
                selectedItems.push(action.payload.id)
            }
            if (isExist && !action.payload.isAdd) {
                selectedItems.splice(index, 1)
            }
            state.selectedItems = selectedItems
        },
        getDetail: (state, action: PayloadAction<string>) => {
            state.loading = true
            state.currentOrderItems = []
        },
        setCurrentOrderItems: (state, action: PayloadAction<{ items: ListResponseModel<OrderItemModel>, isForce: boolean }>) => {
            state.loading = false
            if (action.payload.isForce) {
                state.currentOrderItems = action.payload.items.data
            } else {
                state.currentOrderItems = state.currentOrderItems.concat(action.payload.items.data)
                state.currentOrderItemFilter = action.payload.items.meta
            }

        },
        updateCurrentOrderItems: (state, action: PayloadAction<OrderItemModel[]>) => {
            const updatedOrderItems = action.payload
            let currentOrderItems = cloneDeep(state.currentOrderItems)
            let isNeedUpdate = false

            currentOrderItems = currentOrderItems.map((item) => {
                if (item.status !== ItemStatus.FAIL) {
                    const matchedItem = updatedOrderItems?.find(
                        (cloneItem) => cloneItem && cloneItem.id === item.id
                    )

                    if (
                        matchedItem &&
                        (item.status !== matchedItem.status ||
                            item.inputImageUrl?.tiny !== matchedItem.inputImageUrl?.tiny ||
                            item.outputImageUrl?.tiny !== matchedItem.outputImageUrl?.tiny)
                    ) {
                        isNeedUpdate = true;
                        return {
                            ...item,
                            status: matchedItem.status,
                            inputImageUrl: matchedItem.inputImageUrl,
                            outputImageUrl: matchedItem.outputImageUrl,
                        }
                    }
                }
                return item
            })
            if (isNeedUpdate) {
                state.currentOrderItems = currentOrderItems
            }

        },
        getAll: (state) => {
            state.list = []
            state.filter = {
                text: undefined,
                serviceId: undefined,
                status: undefined,
                page: 1,
                take: Number(process.env.REACT_APP_DEFAULT_PAGE_SIZE),
                itemCount: 0,
                pageCount: 1,
            }
            state.loading = true
        },
        filterDataChange: (state, action: PayloadAction<OrderListFilterModel>) => {
            state.loading = true
            state.filter = action.payload
            if (action.payload.page === 1) {
                state.list = []
            }
        },
        filterOrderItemsChange: (state, action: PayloadAction<PaginateModel>) => {
            state.loading = true
            state.currentOrderItemFilter = action.payload
        },
        setList: (state, action: PayloadAction<ListResponseModel<OrderModel>>) => {
            state.loading = false
            state.list = state.list.concat(action.payload.data)
            state.filter = { ...state.filter, ...action.payload.meta }
        },
        updateOrderList: (state, action: PayloadAction<OrderModel[]>) => {
            const updatedOrder = action.payload
            let currentOrderList = cloneDeep(state.list)
            let currentOrder = cloneDeep(state.current)
            let isNeedUpdate = false;
            currentOrderList = currentOrderList.map((order) => {
                if (order.status !== OrderStatus.COMPLETED && order.status !== OrderStatus.AI_FAILED) {
                    const matchedOrder = updatedOrder.find(
                        (cloneOrder) => cloneOrder && cloneOrder.id === order.id
                    )
                    if (
                        matchedOrder && matchedOrder.status &&
                        (matchedOrder.orderCover !== order.orderCover ||
                            matchedOrder.completedItems !== order.completedItems ||
                            matchedOrder.status !== order.status)
                    ) {
                        isNeedUpdate = true;
                        if (currentOrder && currentOrder.id === matchedOrder.id) {
                            state.current = {
                                ...currentOrder, orderCover: matchedOrder.orderCover,
                                completedItems: matchedOrder.completedItems,
                                status: matchedOrder.status,
                            }
                        }
                        return {
                            ...order,
                            orderCover: matchedOrder.orderCover,
                            completedItems: matchedOrder.completedItems,
                            status: matchedOrder.status,
                        }
                    }
                }
                return order
            })
            if (isNeedUpdate) {
                state.list = currentOrderList
            }

        },
        getOrderStatusList: (state) => {
            state.loading = true
        },
        setOrderStatusList: (state, action: PayloadAction<SimpleListModel[]>) => {
            state.loading = false
            state.orderStatus = action.payload
        },
        getPendingOrder: (state) => {
            state.loading = true
        },
        setPendingOrder: (state, action: PayloadAction<ListResponseModel<OrderModel>>) => {
            state.loading = false
            state.pendingOrders = action.payload.data
        },
        changeOrderDisplayMode: (state) => {
            state.displayMode =
                state.displayMode === OrderDisplayMode.Grid ? OrderDisplayMode.List : OrderDisplayMode.Grid
        },
        downloadOrder: (state, action: PayloadAction<OrderDownloadRequestModel>) => {
            state.selectMode = false;
            state.selectedItems = [];
            state.currentDownloadCredit = undefined;
        },
        retryOrder: (state, action: PayloadAction<number>) => {

        },
        setAsyncWorkingOrders: (state, action: PayloadAction<OrderActionModel>) => {
            let workingOrder = cloneDeep(state.asyncWorkingOrders)
            const targetOrder = workingOrder.find(order => order.orderId === action.payload.orderId)
            if (!targetOrder) {
                workingOrder.push(action.payload)
                state.asyncWorkingOrders = workingOrder
            }
        },
        removeAsyncWorkingOrders: (state, action: PayloadAction<OrderActionModel>) => {
            let workingOrder = cloneDeep(state.asyncWorkingOrders)
            const targetIndex = workingOrder.findIndex(order => order.orderId === action.payload.orderId)
            if (targetIndex >= 0 && targetIndex < workingOrder.length) {
                workingOrder.splice(targetIndex, 1);
                state.asyncWorkingOrders = workingOrder
            }
        },
        createOrder: (state, action: PayloadAction<OrderRequestModel>) => {
            state.loading = true
        },
        setShowSuccessMessage: (state, action: PayloadAction<boolean>) => {
            state.loading = false
            state.isShowOrderSuccess = action.payload
        },
        updateOrderRequestStatus: (
            state,
            action: PayloadAction<{ isSuccess: boolean; message: string }>
        ) => {
            state.loading = false
            if (action.payload.isSuccess) {
                toast.success(intl.formatMessage({ id: "SERVER." + action.payload.message }))
            } else {
                toast.error(intl.formatMessage({ id: "SERVER." + action.payload.message }))

            }
        },
        setCurrent: (state, action: PayloadAction<OrderModel>) => {
            state.loading = false
            state.current = action.payload
        },
        updateCurrent: (state, action: PayloadAction<OrderModel>) => {
            //ignore case when order completed and download is fired
            // not update order data in this case
            if (action.payload.download && state.current?.status === OrderStatus.COMPLETED) {
                return
            }
            if (state.current && (
                state.current?.status !== action.payload.status ||
                state.current?.completedItems !== action.payload.completedItems
            )) {
                state.current = {
                    ...state.current,
                    status: action.payload.status,
                    completedItems: action.payload.completedItems
                }
            }
        },
    },
})
export const {
    getAiServiceStatus,
    setAiServiceStatus,
    setSelectMode,
    setSelectedItem,
    setCurrent,
    updateCurrent,
    setCurrentOrderItems,
    setShowSuccessMessage,
    createOrder,
    updateOrderRequestStatus,
    updateCurrentOrderItems,
    downloadOrder,
    retryOrder,
    updateOrderList,
    setAsyncWorkingOrders,
    removeAsyncWorkingOrders,
    getPendingOrder,
    changeOrderDisplayMode,
    filterOrderItemsChange,
    setPendingOrder,
    getAll,
    filterDataChange,
    setList,
    getDetail,
    getOrderStatusList,
    setOrderStatusList,
    getCurrentDownloadOrderCredit,
    setCurrentDownloadOrderCredit
} = orderSlice.actions

export const selectOrderLoading = (state: RootState) => state.order.loading
export const selectOrderList = (state: RootState) => state.order.list
export const selectOrderStatus = (state: RootState) => state.order.orderStatus
export const selectOrderFilter = (state: RootState) => state.order.filter
export const selectPendingOrders = (state: RootState) => state.order.pendingOrders
export const selectOrderDisplayMode = (state: RootState) => state.order.displayMode
export const selectShowOrderSuccess = (state: RootState) => state.order.isShowOrderSuccess
export const selectCurrentOrder = (state: RootState) => state.order.current
export const selectCurrentOrderItems = (state: RootState) => state.order.currentOrderItems
export const selectCurrentOrderItemsFilter = (state: RootState) => state.order.currentOrderItemFilter
export const selectWorkingOrders = (state: RootState) => state.order.asyncWorkingOrders
export const selectSelectedMode = (state: RootState) => state.order.selectMode
export const selectSelectedItems = (state: RootState) => state.order.selectedItems
export const selectCurrentDownloadCredit = (state: RootState) => state.order.currentDownloadCredit
export const selectAiServiceStatus = (state: RootState) => state.order.isAiOverLoad
export default orderSlice.reducer
