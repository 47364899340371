export enum PlanType {
    PAY_AS_U_GO = "PAY_AS_U_GO",
    SUBSCRIPTION = "SUBSCRIPTION",
    ENTERPRISE = "ENTERPRISE",
}
export enum PriceType {
    ONE_TIME = "ONE_TIME",
    SUBSCRIPTION = "SUBSCRIPTION",
}
export enum CurrencyCode {
    JPY = 'JPY',
    USD = 'USD'
}
export enum CurrencySymbol{
    JPY = '¥',
    USD = '$'
}
export const PAY_AS_U_GO_PLAN = 1
export const CUSTOM_PLAN = 17
export const NONE_CHECKOUT_PLAN = [CUSTOM_PLAN, PAY_AS_U_GO_PLAN]//free, custom
