import { call, put, select, takeLatest } from "redux-saga/effects";
import { filterDataChange, getAll, getDetail, getDetailOutput, rating, retryAi, setCurrent, setCurrentDetailOutput, setList, setTransactionFail, updateItemOutput } from "./itemRedux";
import { ResponseGenerator } from "../../../shared/models/ResponseGenerator";
import { getDetailItem, getDetailItemOutput, getItems, retryAiWithSetting, updateDetailItem, updateItemImage } from "./itemApi";
import { HTTP_CODE } from "../../../shared/constants";
import { toast } from "react-toastify";
import { ItemFilterModel } from "../models/ItemFilter";
import { OrderItemModel } from "../../orders/models/OrderItemModel";
import { cloneDeep } from "lodash";
import { ItemStatus } from "../../orders/constants";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { ItemOutputModel } from "../models/ItemOutputModel";
import { intl } from "../../../../_metronic/i18n/i18nProvider";

export default function* saga() {

    yield takeLatest(updateItemOutput.type, function* updateItemOutputImage(action: ReturnType<typeof updateItemOutput>) {
        try {
            const newItem: ItemOutputModel = action.payload
            const response: ResponseGenerator = yield call(updateItemImage, newItem)
            if (response && response.status !== HTTP_CODE.SUCCESS) {
                throw new Error(response.data.message);
            }
            yield put(setCurrentDetailOutput(undefined))
            toast.success(intl.formatMessage({ id: 'MESSAGE.ITEM.UPDATE_DONE' }));

        } catch (error: any) {
            toast.error(intl.formatMessage({ id: 'SERVER.'+error.message }));            
        
        }
    })
    yield takeLatest([filterDataChange.type, getAll.type], function* getAllItems() {
        try {
            const filterData: ItemFilterModel = yield select(state => state.item.filter);
            const response: ResponseGenerator = yield call(getItems, filterData)
            if (response && response.status !== HTTP_CODE.SUCCESS) {
                throw new Error(response.data.message);//@TODO:check format
            }
            yield put(setList(response.data))
        } catch (error: any) {
            toast.error(intl.formatMessage({ id: 'SERVER.'+error.message }));   
        }

    })
    yield takeLatest(getDetailOutput.type, function* getItemDetailOutput(action: ReturnType<typeof getDetail>) {
        try {
            const itemId: string = action.payload
            const response: ResponseGenerator = yield call(getDetailItemOutput, itemId)
            if (response && response.status !== HTTP_CODE.SUCCESS) {
                throw new Error(response.data.message);//@TODO:check format
            }
            yield put(setCurrentDetailOutput(response.data))

        } catch (error: any) {
            toast.error(intl.formatMessage({ id: 'SERVER.'+error.message }));   
        }

    })
    yield takeLatest(getDetail.type, function* getItemDetail(action: ReturnType<typeof getDetail>) {
        try {
            const itemId: string = action.payload
            const response: ResponseGenerator = yield call(getDetailItem, itemId)
            if (response && response.status !== HTTP_CODE.SUCCESS) {
                throw new Error(response.data.message);//@TODO:check format
            }
            yield put(setCurrent(response.data))

        } catch (error: any) {
            toast.error(intl.formatMessage({ id: 'SERVER.'+error.message }));   
        }

    })
    yield takeLatest(rating.type, function* updateItemStar(action: ReturnType<typeof rating>) {
        try {
            const itemId: number = action.payload.id
            const star: number = action.payload.star
            const response: ResponseGenerator = yield call(updateDetailItem, itemId, star)
            if (response && response.status !== HTTP_CODE.SUCCESS) {
                throw new Error(response.data.message);//@TODO:check format
            }
        } catch (error: any) {
            toast.error(intl.formatMessage({ id: 'SERVER.'+error.message }));   
        }

    })
    yield takeLatest(retryAi.type, function* updateItemStar(action: ReturnType<typeof retryAi>) {
        try {
            const newSetting = action.payload
            const currentItem: OrderItemModel | undefined = yield select(state => state.item.current);
            if (!currentItem) {
                throw new Error("No current item found")
            }
            const response: ResponseGenerator = yield call(retryAiWithSetting, currentItem.id, newSetting)
            if (!response || response.status !== HTTP_CODE.SUCCESS) {
                throw new Error(response.data.message);//@TODO:check format
            }
            if (response.data.isSuccess) {
                let updatedItem = cloneDeep(currentItem);
                updatedItem.status = ItemStatus.AI_PROCESSING;
                updatedItem.outputImageUrl = {
                    tiny: toAbsoluteUrl('/media/loading-bg.gif'),
                    small: toAbsoluteUrl('/media/loading-bg.gif'),
                    medium: toAbsoluteUrl('/media/loading-bg.gif'),
                    original: toAbsoluteUrl('/media/loading-bg.gif')
                }
                yield put(setCurrent(updatedItem));
            }
        } catch (error: any) {
            yield put(setTransactionFail(error.message));
        }

    })

}