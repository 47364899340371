import {FC, useEffect, useState} from 'react'
import {OrderItemModel} from '../../orders/models/OrderItemModel'
import StarRatings from 'react-star-ratings'
import {useIntl} from 'react-intl'
import {ItemStatus} from '../../orders/constants'
import './itemRating.scss'
import {Modal} from 'react-bootstrap-v5'
import {useDispatch} from 'react-redux'
import {rating} from '../redux/itemRedux'
import {toast} from 'react-toastify'
interface Props {
  item: OrderItemModel
}
const ItemRating: FC<Props> = (props) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const isItemCompleted = props.item.status === ItemStatus.DONE
  const [isShow, setIsShow] = useState(false)
  const [start, setStart] = useState(props.item.star)
  useEffect(() => {
    setStart(props.item.star)
  },[props.item])
  return isItemCompleted ? (
    <div className='d-flex rating-section'>
      <span>{intl.formatMessage({id: 'Item.rating.help'})}</span>
      <StarRatings
        rating={start}
        starRatedColor='red'
        starHoverColor='red'
        changeRating={async (newRating) => {
          await dispatch(rating({id: props.item.id, star: newRating}))
          toast.success(intl.formatMessage({id: 'Rating.thank'}))
          setStart(newRating)
          // setIsShow(true)
        }}
        numberOfStars={5}
        name='rating'
      />
      <Modal size='lg' aria-labelledby='contained-modal-title-vcenter' centered show={isShow}>
        <Modal.Body className='text-center'>
          <i className='bi bi-check2-circle text-success' style={{fontSize: '100px'}}></i>

          <p className='mt-5 fs-4'>{intl.formatMessage({id: 'Rating.thank'})}</p>
          <div className='btn  btn-primary' onClick={() => setIsShow(false)}>
            {intl.formatMessage({id: 'EditOrder.btn.CLOSE'})}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  ) : (
    <></>
  )
}
export default ItemRating
