
import {FC} from 'react'
import { toAbsoluteUrl} from '../../../../_metronic/helpers'
const Error401: FC = () => {
  return (
    <>
      <h1 className='fw-bolder fs-4x text-gray-700 mb-10'>Unauthorize</h1>

      <div className='fw-bold fs-3 text-gray-400 mb-15'>
        Your session is timeout! <br /> Please try to login again.
      </div>
      <div className="mb-11">
          <img src={toAbsoluteUrl('/media/auth/Unauthorize.png')} className="mw-100 mh-300px theme-light-show" alt="Unauthorize"/>
          
      </div>
    </>
  )
}

export {Error401}
