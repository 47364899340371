import axios from 'axios'
import { IProfileDetails, UpdateProfilePayload, ChangePasswordPayload } from '../models/ProfileModels'

const API_URL = process.env.REACT_APP_API_URL
export const ACCOUNT_URL = `${API_URL}/account`
export const PLAN_URL = `${API_URL}/plans`
export const REFERRAL_CODE_URL = `${ACCOUNT_URL}/referral-code`
export const PASSWORD_CHANGE_URL = `${API_URL}/account/password-change`

/**
 * Get user profile
 * @returns profile
 */
export function getUserProfileRequest(): Promise<IProfileDetails> {
  return axios.get(ACCOUNT_URL)
}

/**
 * Get user profile
 * @returns profile
 */
export function verifyProfileRequest(): Promise<IProfileDetails> {
  return axios.post(ACCOUNT_URL + '/verify')
}

/**
 * Update user profile
 * @param payload payload for updating user profile
 * @returns updated profile
 */
export function updateUserProfileRequest(payload: UpdateProfilePayload): Promise<IProfileDetails> {
  return axios.put(ACCOUNT_URL, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export function redeemReferralCodeRequest(referralCode: string) {
  return axios.post(REFERRAL_CODE_URL, { referralCode })
}

export function changePasswordRequest(payload: ChangePasswordPayload) {
  return axios.put(PASSWORD_CHANGE_URL, payload)
}

export function getSubscriptionPlansRequest(type: string) {
  return axios.get(PLAN_URL + '?type=' + type)
}

export function updateUserSubscriptionRequest(id: number) {
  return axios.put(ACCOUNT_URL + '/plans', { id })
}
