/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useRef, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {useHistory, useLocation} from 'react-router-dom'

import {useDispatch} from 'react-redux'
import {getServiceDetail, selectCurrentService} from '../../modules/services/redux/serviceRedux'
import {useAppSelector} from '../../../setup/redux/hooks'
import {ServiceModel} from '../../modules/services/models/ServiceModel'
import {NewOrderComponent} from './components/newOrder'
import {SettingReferenceStatus} from '../setting-reference/constants'
import {getAll, selectSettingReferenceList} from '../setting-reference/redux/SettingReferenceRedux'
import {SettingReferenceModel} from '../setting-reference/models/SettingReferenceModel'
import {Modal} from 'react-bootstrap-v5'
import {
  getAiServiceStatus,
  selectAiServiceStatus,
  selectCurrentOrder,
  selectShowOrderSuccess,
  setShowSuccessMessage,
} from './redux/OrderRedux'
function useQuery() {
  const {search} = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}

const CreateOrderPage: FC = () => {
  const intl = useIntl()
  const query = useQuery()
  const didRequest = useRef(false)
  const dispatch = useDispatch()
  const history = useHistory()
  const service: ServiceModel | undefined = useAppSelector(selectCurrentService)
  const availableSettings: SettingReferenceModel[] = useAppSelector(selectSettingReferenceList)
  const isShow = useAppSelector(selectShowOrderSuccess)
  const current = useAppSelector(selectCurrentOrder)
  const isAiServerOverload = useAppSelector(selectAiServiceStatus)
  useEffect(() => {
    const getService = async () => {
      if (!didRequest.current) {
        const serviceId = query.get('serviceId') as string
        dispatch(getServiceDetail(serviceId))
        dispatch(getAiServiceStatus())
        dispatch(
          getAll({
            serviceId: serviceId,
            status: SettingReferenceStatus.ACTIVE,
          })
        )
      }

      return () => (didRequest.current = true)
    }

    getService()
  }, [])
  const handleClose = () => {
    dispatch(setShowSuccessMessage(false))
    if (current) {
      history.push('/orders/' + current?.id)
    }
  }
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'ORDER.CREATE'})}</PageTitle>
      {isAiServerOverload && (
        <div className='mx-1 mb-5'>
          <div className='d-flex bg-light-warning rounded border-warning border border-dashed p-6'>
            <i className='bi bi-exclamation-circle-fill fs-2tx text-warning me-4'></i>
            <div className='d-flex flex-stack flex-grow-1'>
              <div className='fw-bold'>
                <h4 className='text-gray-800 fw-bolder'>
                  {intl.formatMessage({id: 'Order.ai.overloaded.title'})}
                </h4>
                <div className='fs-6 text-gray-600'>
                  {intl.formatMessage({id: 'Order.ai.overloaded'})}
                </div>
              </div>
            </div>
          </div> 
        </div>
      )}
      {service && availableSettings.length > 0 && (
        <NewOrderComponent service={service} settings={availableSettings} />
      )}
      <Modal size='lg' aria-labelledby='contained-modal-title-vcenter' centered show={isShow}>
        <Modal.Body className='text-center'>
          <i className='bi bi-check2-circle text-success' style={{fontSize: '100px'}}></i>

          <p className='mt-5 fs-4'>{intl.formatMessage({id: 'Order.modal.SUCCESS'})}</p>
          <div className='btn  btn-primary' onClick={handleClose}>
            {intl.formatMessage({id: 'EditOrder.btn.CLOSE'})}
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export {CreateOrderPage}
