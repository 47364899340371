import {FC} from 'react'
import {OrderModel} from '../models/OrderModel'
import {useIntl} from 'react-intl'
import moment from 'moment'
import {orderStatusClassMapping} from '../utils'
import OrderActionBox from './OrderActionBox'
import {ORDER_ACTION_VIEW_MODE, OrderStatus} from '../constants'
import Tooltip from 'react-tooltip-lite'
import {useDispatch} from 'react-redux'
import {useAppSelector} from '../../../../setup/redux/hooks'
import {selectSelectedMode, setSelectMode} from '../redux/OrderRedux'
import {KTSVG} from '../../../../_metronic/helpers'

interface Props {
  order: OrderModel
}
const OrderInfoBox: FC<Props> = (props) => {
  const order = props.order
  const intl = useIntl()
  const dispatch = useDispatch()
  const selectedMode = useAppSelector(selectSelectedMode)
  return (
    <div className='card'>
      <div className='card-header cursor-pointer'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>
            {intl.formatMessage({id: 'OrderDetail.Title.ORDER_DETAIL'})}
          </h3>
        </div>
      </div>

      <div className='card-body p-5'>
        <div className='row mb-7'>
          <label className='col-lg-4 fw-bold text-muted'>
            {intl.formatMessage({id: 'OrderDetail.Form.ORDER_NAME'})}
          </label>

          <div className='col-lg-8'>
            <span className='fw-bolder fs-6 text-dark'>{order?.name}</span>
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 fw-bold text-muted'>
            {intl.formatMessage({id: 'OrderDetail.Form.ORDER_SERVICE'})}
            <i
              className='fas fa-exclamation-circle ms-1 fs-7'
              data-bs-toggle='tooltip'
              title={intl.formatMessage({id: 'SERVICE.SERVICE.' + order.service?.description})}
            ></i>
          </label>

          <div className='col-lg-8 fv-row'>
            <span className='fw-bold fs-6'>
              {intl.formatMessage({id: 'SERVICE.SERVICE.' + order.service?.name})}
            </span>
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 fw-bold text-muted'>
            {intl.formatMessage({id: 'Order.table.QUALITY'})}
          </label>

          <div className='col-lg-8 fv-row'>
            <span className='fw-bold fs-6'>
              {intl.formatMessage({id: 'Order.table.' + order.type})}
            </span>
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 fw-bold text-muted'>
            {intl.formatMessage({id: 'OrderDetail.Form.ORDER_SETTING'})}
          </label>

          <div className='col-lg-8 fv-row'>
            <span className='fw-bold fs-6'>{intl.formatMessage({id: order.setting?.name ?? 'unknown'})}</span>
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 fw-bold text-muted'>
            {intl.formatMessage({id: 'OrderDetail.Form.ORDER_ITEMS'})}
          </label>

          <div className='col-lg-8 fv-row'>
            <span className='fw-bold fs-6'>
              {order.completedItems}/{order.totalItems}
            </span>
          </div>
        </div>
        <div className='row mb-7'>
          <label className='col-lg-4 fw-bold text-muted'>
            {intl.formatMessage({id: 'OrderDetail.Form.ORDER_STATUS'})}
          </label>

          <div className='col-lg-8 fv-row'>
            <span className='fw-bold fs-6'>
              <span
                className={`badge ${orderStatusClassMapping(
                  order.status
                )} fw-bolder me-auto px-4 py-3`}
              >
                {intl.formatMessage({id: 'ORDER.STATUS.' + order.status})}
              </span>
            </span>
          </div>
        </div>
        <div className='row mb-7'>
          <label className='col-lg-4 fw-bold text-muted'>
            {intl.formatMessage({id: 'OrderDetail.Form.ORDER_ORDER_DATE'})}
          </label>

          <div className='col-lg-8 fv-row'>
            <span className='fw-bold fs-6'>
              {moment(order?.createdAt).format(process.env.REACT_APP_DATE_FORMAT)}
            </span>
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 fw-bold text-muted'>
            {intl.formatMessage({id: 'OrderDetail.Form.ORDER_PAYMENT_STATUS'})}
          </label>

          <div className='col-lg-8 fv-row'>
            <span className='fw-bold fs-6'>
              {intl.formatMessage({id: 'ORDER.PAYMENT.' + order.paymentStatus})}
            </span>
          </div>
        </div>
      </div>
      <div className='card-footer p-3'>
        <OrderActionBox order={order} viewMode={ORDER_ACTION_VIEW_MODE.FULL} />
        {(order.status === OrderStatus.COMPLETED || order.status === OrderStatus.PENDING) && (
          <Tooltip content={intl.formatMessage({id: 'ORDER.ACTIONS.SELECT'})} className='tooltips'>
            <button
              type='button'
              className='btn mt-3 btn-icon w-100 bg-light-info btn-active-color-primary btn-sm me-1 p-1'
              onClick={() => {
                dispatch(setSelectMode(!selectedMode))
              }}
            >
              <div className='fw-bold text-gray-800 text-hover-primary fs-6'>
                <KTSVG
                  path={'/media/icons/duotune/coding/cod006.svg'}
                  className='svg-icon svg-icon-2x svg-icon-info'
                />
                {intl.formatMessage({id: 'ORDER.ACTIONS.SELECT'})}
              </div>
            </button>
          </Tooltip>
        )}
      </div>
    </div>
  )
}

export default OrderInfoBox
