import {useDispatch} from 'react-redux'
import {DownloadStatus, ORDER_ACTION_VIEW_MODE, OrderActionType, OrderStatus} from '../constants'
import {OrderModel} from '../models/OrderModel'
import {
  getCurrentDownloadOrderCredit,
  removeAsyncWorkingOrders,
  retryOrder,
  selectSelectedItems,
  selectSelectedMode,
  selectWorkingOrders,
} from '../redux/OrderRedux'
import {KTSVG} from '../../../../_metronic/helpers'
import {FC, useEffect} from 'react'
import {Spinner} from 'react-bootstrap-v5'
import {DatabaseReference, off, onValue, ref} from 'firebase/database'
import {useAppSelector} from '../../../../setup/redux/hooks'
import {database} from '../../../../setup/firebase'
import {toast} from 'react-toastify'
import {OrderActionModel} from '../models/OrderActionModel'
import Tooltip from 'react-tooltip-lite'
import {useIntl} from 'react-intl'
import {getIntl} from '../../../shared/intl'

type Props = {
  order: OrderModel
  viewMode: ORDER_ACTION_VIEW_MODE
}

const OrderActionBox: FC<Props> = (props) => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const order = props.order
  const status = props.order.status
  const isFullMode = props.viewMode === ORDER_ACTION_VIEW_MODE.FULL
  const workingOrders = useAppSelector(selectWorkingOrders)
  const selectedItems = useAppSelector(selectSelectedItems)

  const selectedMode = useAppSelector(selectSelectedMode)
  let orderListener: DatabaseReference | undefined = undefined
  let actions = <></>
  /**
   * handleDownloadAction
   *
   * @param snapshot
   * @param workingJob
   * @returns
   */
  const handleDownloadAction = (snapshot: any, workingJob: OrderActionModel) => {
    const data = snapshot.val()
    if (!data) {
      return
    }
    if (data.status === DownloadStatus.DOWNLOADING) {
      toast.update(workingJob.toast, {
        render: intl.formatMessage({id: data.message}) + '...' + Math.round(data.percentage) + '%',
      })
    } else {
      toast.update(workingJob.toast, {
        render: intl.formatMessage({id: data.message}),
        autoClose: 3000,
      })
      if (data.status === DownloadStatus.DONE) {
        const a = document.createElement('a')
        a.href = data.downloadUrl
        a.download = data.filename
        a.style.display = 'none'
        document.body.appendChild(a)
        a.click()
        orderListener && off(orderListener, 'value')
        orderListener = undefined
        document.body.removeChild(a)
        dispatch(removeAsyncWorkingOrders({orderId: data.orderId}))
      }
    }
  }
  /**
   * handleRetryAction
   *
   * @param snapshot
   */
  const handleRetryAction = (snapshot: any) => {
    const order = snapshot.val()
    const intl = getIntl()
    if (order && order.status === OrderStatus.COMPLETED) {
      toast.success(intl.formatMessage({id: 'MESSAGE.ORDER.RETRY_DONE'}))
      orderListener && off(orderListener, 'value')
      orderListener = undefined
      dispatch(removeAsyncWorkingOrders({orderId: order.id}))
    }
    if (order && order.status === OrderStatus.AI_FAILED) {
      toast.error(intl.formatMessage({id: 'MESSAGE.ORDER.RETRY_FAIL'}))
      orderListener && off(orderListener, 'value')
      orderListener = undefined
      dispatch(removeAsyncWorkingOrders({orderId: order.id}))
    }
  }

  useEffect(() => {
    return () => {
      if (orderListener) {
        off(orderListener)
        orderListener = undefined
      }
    }
  }, [])
  const workingJob = workingOrders.find((item) => order.id === item.orderId)
  if (workingJob && !orderListener) {
    orderListener = ref(database, workingJob.asyncListenerPath)
    switch (workingJob.type) {
      case OrderActionType.Download:
        onValue(orderListener, (snapshot) => handleDownloadAction(snapshot, workingJob))
        break
      case OrderActionType.Retry:
        onValue(orderListener, handleRetryAction)
        break
    }
  }
  switch (status) {
    case OrderStatus.PENDING:
    case OrderStatus.COMPLETED:
      actions = (
        <Tooltip
          content={
            selectedItems.length > 0
              ? intl.formatMessage({id: 'ORDER.ACTIONS.DOWNLOAD_SELECTED'})
              : intl.formatMessage({id: 'ORDER.ACTIONS.DOWNLOAD'})
          }
          className='tooltips'
        >
          <button
            type='button'
            disabled={workingJob !== undefined || (selectedMode && selectedItems.length === 0)}
            className={
              'btn btn-icon bg-light-success btn-active-color-primary btn-sm me-1 p-1 ' +
              (isFullMode ? 'w-100' : '')
            }
            onClick={() => {
              //re-calculate the order credit
              dispatch(
                getCurrentDownloadOrderCredit({
                  orderId: order.id,
                })
              )
            }}
          >
            {workingJob ? (
              <Spinner size='sm' animation='border' />
            ) : (
              <div className='fw-bold text-gray-800 text-hover-primary fs-6'>
                <KTSVG
                  path={'/media/icons/duotune/files/fil021.svg'}
                  className='svg-icon svg-icon-2x svg-icon-success'
                ></KTSVG>
                {isFullMode &&
                  (selectedItems.length > 0
                    ? intl.formatMessage({id: 'ORDER.ACTIONS.DOWNLOAD_SELECTED'})
                    : intl.formatMessage({id: 'ORDER.ACTIONS.DOWNLOAD'}))}
              </div>
            )}
          </button>
        </Tooltip>
      )
      break
    case OrderStatus.AI_FAILED:
      actions = (
        <Tooltip content={intl.formatMessage({id: 'ORDER.ACTIONS.RETRY'})} className='tooltips'>
          <button
            type='button'
            disabled={workingJob ? true : false}
            className={
              'btn btn-icon bg-light-warning btn-active-color-primary btn-sm me-1 p-1 ' +
              (isFullMode ? 'w-100' : '')
            }
            onClick={() => {
              dispatch(retryOrder(order.id))
            }}
          >
            {workingJob ? (
              <Spinner size='sm' animation='border' />
            ) : (
              <div className='fw-bold text-gray-800 text-hover-primary fs-6'>
                <KTSVG
                  path={'/media/icons/duotune/arrows/arr029.svg'}
                  className='svg-icon svg-icon-2x svg-icon-success'
                />
                {isFullMode && intl.formatMessage({id: 'ORDER.ACTIONS.RETRY'})}
              </div>
            )}
          </button>
        </Tooltip>
      )
      break
  }

  return <>{actions}</>
}
export default OrderActionBox
