/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState, useRef, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {RouteComponentProps} from 'react-router-dom'
import {PageTitle} from '../../../_metronic/layout/core'
import ItemSetting from './components/itemSetting'
import ItemViewBox from './components/itemViewBox'
import {useDispatch} from 'react-redux'
import {useAppSelector} from '../../../setup/redux/hooks'
import {getDetail, selectCurrentItem, setCurrent} from './redux/itemRedux'
import {selectCurrentOrder} from '../orders/redux/OrderRedux'
import {DatabaseReference, off} from 'firebase/database'
import {OrderItemModel} from '../orders/models/OrderItemModel'
import {listenItemChange} from '../orders/firebase/order'
import {ItemStatus} from '../orders/constants'
import {toast} from 'react-toastify'
interface RouteParams {
  id: string
  orderId: string
}
interface Props extends RouteComponentProps<RouteParams> {}

const ItemDetail: FC<Props> = (props) => {
  const intl = useIntl()
  const didRequest = useRef(false)
  const itemId = props.match.params.id
  const dispatch = useDispatch()
  const item = useAppSelector(selectCurrentItem)
  const order = useAppSelector(selectCurrentOrder)
  const [settingView, toggleSettingView] = useState(false)
  let itemListener: DatabaseReference | undefined = undefined
  const getItemDetail = async () => {
    if (!didRequest.current) {
      dispatch(getDetail(itemId))
    }
    return () => {
      didRequest.current = true
      if (itemListener) {
        off(itemListener)
        itemListener = undefined
      }
    }
  }
  useEffect(() => {
    getItemDetail()
  }, [])

  const onChangeItem = (id: number) => {
    dispatch(getDetail(id.toString()))
  }
  if (item && item.status === ItemStatus.AI_PROCESSING) {
    if (itemListener === undefined) {
      itemListener = listenItemChange(
        item.orderId.toString(),
        item.id.toString(),
        (updatedItem: OrderItemModel) => {
          if (updatedItem) {
            let isCompleted = false
            if (updatedItem.status === ItemStatus.DONE) {
              toast.success(intl.formatMessage({id: 'MESSAGE.ITEM.RE_TRY_DONE'}))
              isCompleted = true
            }
            if (updatedItem.status === ItemStatus.FAIL) {
              toast.error(intl.formatMessage({id: 'MESSAGE.ITEM.RE_TRY_FAIL'}))
              isCompleted = true
            }
            if (isCompleted && itemListener) {
              setTimeout(() => {
                dispatch(setCurrent(updatedItem));
              }, 3000);
              off(itemListener)
              itemListener = undefined
            }
          }
        }
      )
    } else {
      off(itemListener)
      itemListener = undefined
    }
  }
  return (
    <>
      <PageTitle
        breadcrumbs={[
          {
            title: intl.formatMessage({id: 'ORDER.LIST'}),
            path: '/orders',
            isSeparator: false,
            isActive: true,
          },
          {
            title: 'dash',
            path: 'dash',
            isSeparator: true,
            isActive: true,
          },
          {
            title: order?.name ?? 'order',
            path: '/orders/' + props.match.params.orderId,
            isSeparator: false,
            isActive: false,
          },

          {
            title: 'dash',
            path: 'dash',
            isSeparator: true,
            isActive: true,
          },
        ]}
      >
        {item?.name}
      </PageTitle>

      <div className='row'>
        <div className={settingView ? 'col-sm-9' : 'col-sm-12'}>
          {item && (
            <ItemViewBox
              item={item}
              onNext={onChangeItem}
              onPrev={onChangeItem}
              onSelect={(id: number) => dispatch(getDetail(id.toString()))}
              toggleSettings={() => toggleSettingView(!settingView)}
            />
          )}
        </div>

        {settingView && <div className=' col-sm-3'>{item && <ItemSetting item={item} />}</div>}
      </div>
    </>
  )
}

export {ItemDetail}
