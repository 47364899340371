export enum UserTransactionType {
  REFERRAL = 'REFERRAL',
  EARN = 'EARN',
  PAID = 'PAID',
  ORDER = 'ORDER',
  CREDIT = 'CREDIT'
}
export enum UserTransactionDataType {
  invoice = 'INVOICE',
  recipe = 'RECIPE',
  orderDetail = 'ORDER_DETAIL',
  nothing = 'NOTHING'
}

export enum UserTransactionAction {
  IN = 'IN',
  OUT = 'OUT',
}