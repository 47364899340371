import {FC} from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import {useIntl} from 'react-intl'

type Props = {
  className?: string
  onClose: any
  onConfirm: any
  isShow: boolean
  message: string
}

const ConfirmModal: FC<Props> = (props) => {
  const intl = useIntl()
  const onClose = () => {
    props.onClose()
  }
  return (
    <Modal
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      show={props.isShow}
      onHide={onClose}
      backdrop='static'
    >
      <Modal.Header closeButton>
        <p className='card-label fw-bolder fs-1 mb-1 '>
          {intl.formatMessage({id: 'SYSTEM.MODAL.CONFIRM.TITLE'})}
        </p>
      </Modal.Header>
      <Modal.Body>{props.message}</Modal.Body>
      <Modal.Footer>
        <div className='d-flex justify-content-end flex-row-fluid'>
          <Button
            type='button'
            variant='secondary'
            onClick={props.onClose}
            className='btn btn-light me-3'
          >
            {intl.formatMessage({id: 'SYSTEM.MODAL.CONFIRM.CANCEL'})}
          </Button>

          <Button type='submit' variant='primary' onClick={props.onConfirm}>
            {intl.formatMessage({id: 'SYSTEM.MODAL.CONFIRM.OK'})}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}
export default ConfirmModal
