import { call, put, select, takeLatest, takeLeading } from 'redux-saga/effects'
import {
  createSetting,
  filterDataChange,
  getAll,
  getAllFeatureForService,
  getAllServices,
  getCurrent,
  getServiceCategories,
  setCategories,
  setCurrent,
  setError,
  setFeatures,
  setList,
  setServices,
  setSuccess,
  updateServiceStatus,
  updateSetting,
} from './SettingReferenceRedux'
import { SettingReferenceParamsModel } from '../models/SettingReferenceParamsModel'
import { ResponseGenerator } from '../../../shared/models/ResponseGenerator'
import {
  createNewSettingReferrence,
  getAllCategoriesBelongToServices,
  getAllFeaturesBelongToServices,
  getAllService,
  getAllSettingReference,
  getDetailSettingReference,
  updateSettingReferrence,
  updateSettingStatus,
} from './SettingReferenceAPIs'
import { HTTP_CODE } from '../../../shared/constants'
import { push } from 'connected-react-router'
import { SettingReferenceStatus } from '../constants'
import { toast } from 'react-toastify'
import { getIntl } from '../../../shared/intl'
import { intl } from '../../../../_metronic/i18n/i18nProvider'
export default function* saga() {
  /**
   * getAllSystemReference
   */
  yield takeLatest(
    [getAll.type, filterDataChange.type],
    function* getAllSystemReference(action: ReturnType<typeof getAll>) {
      try {
        const customFilter = action.payload
        const filterData: SettingReferenceParamsModel = yield select(
          (state) => state.settingReference.filter
        )
        const response: ResponseGenerator = yield call(getAllSettingReference, {
          ...filterData,
          ...customFilter,
        })
        if (response.status !== HTTP_CODE.SUCCESS) {
          throw new Error(response.data.message) //@TODO:check format
        }
        yield put(setList(response.data))
      } catch (error: any) {
        yield put(setError(error.message))
      }
    }
  )
  /**
   * getDetailSetting
   */
  yield takeLatest(
    getCurrent.type,
    function* getDetailSetting(action: ReturnType<typeof getCurrent>) {
      try {
        const settingId = action.payload
        const response: ResponseGenerator = yield call(getDetailSettingReference, settingId)
        if (response.status !== HTTP_CODE.SUCCESS) {
          throw new Error(response.data.message) //@TODO:check format
        }
        yield put(setCurrent(response.data))
      } catch (error: any) {
        yield put(setError(error.message))
      }
    }
  )
  /**
   * getServices
   */
  yield takeLatest(
    getAllServices.type,
    function* getServices(action: ReturnType<typeof getAllServices>) {
      try {
        const isLoadDepends = action.payload
        const response: ResponseGenerator = yield call(getAllService)
        if (response.status !== HTTP_CODE.SUCCESS) {
          throw new Error(response.data.message) //@TODO:check format
        }
        yield put(setServices(response.data.data))
        if (response.data.data.length > 0 && isLoadDepends) {
          const targetDependencies = response.data.data[0]
          if (targetDependencies) {
            yield put(getServiceCategories(targetDependencies.id))
            yield put(getAllFeatureForService(targetDependencies.id))
          }
        }
      } catch (error: any) {
        yield put(setError(error.message))
      }
    }
  )
  /**
   * getCategories
   */
  yield takeLatest(
    getServiceCategories.type,
    function* getCategories(action: ReturnType<typeof getServiceCategories>) {
      try {
        const serviceId = action.payload
        const response: ResponseGenerator = yield call(getAllCategoriesBelongToServices, serviceId)
        if (response.status !== HTTP_CODE.SUCCESS) {
          throw new Error(response.data.message) //@TODO:check format
        }
        yield put(setCategories(response.data.data))
      } catch (error: any) {
        yield put(setError(error.message))
      }
    }
  )
  /**
   * getFeatures
   */
  yield takeLatest(
    getAllFeatureForService.type,
    function* getFeatures(action: ReturnType<typeof getAllFeatureForService>) {
      try {
        const serviceId = action.payload
        const response: ResponseGenerator = yield call(getAllFeaturesBelongToServices, serviceId)
        if (response.status !== HTTP_CODE.SUCCESS) {
          throw new Error(response.data.message) //@TODO:check format
        }
        yield put(setFeatures(response.data.data))
      } catch (error: any) {
        yield put(setError(error.message))
      }
    }
  )
  /**
   * updateSettingServiceStatus
   */
  yield takeLatest(
    updateServiceStatus.type,
    function* updateService(action: ReturnType<typeof updateServiceStatus>) {
      try {
        const requestData: { serviceId: number; newStatus: SettingReferenceStatus } = action.payload
        const response: ResponseGenerator = yield call(
          updateSettingStatus,
          requestData.serviceId,
          requestData.newStatus
        )
        if (response.status !== HTTP_CODE.SUCCESS) {
          throw new Error(response.data.message) //@TODO:check format
        }
        const intl = getIntl();
        toast.success(intl.formatMessage({id: `MESSAGE.SETTING.UPDATE_DONE`}))
        yield put(getAll(undefined))
      } catch (error: any) {
        yield put(setError(error.message))
      }
    }
  )
  /**
   * createSettingReference
   */
  yield takeLatest(
    createSetting.type,
    function* createSettingReference(action: ReturnType<typeof createSetting>) {

      try {
        const newSetting = action.payload
        const response: ResponseGenerator = yield call(createNewSettingReferrence, newSetting)

        if (response.status !== HTTP_CODE.SUCCESS) {
          throw new Error(response.data.message) //@TODO:check format
        }
        yield put(setSuccess(intl.formatMessage({id: 'SETTING_REFERENCE.CREATE_SUCCESS'})))
        yield put(setCurrent(undefined))
      } catch (error: any) {
        yield put(setError(error.message))
      }
    }
  )
  /**
   * createSettingReference
   */
  yield takeLeading(
    updateSetting.type,
    function* updateSettingReference(action: ReturnType<typeof updateSetting>) {
      try {
        const updateSetting = action.payload
        const response: ResponseGenerator = yield call(updateSettingReferrence, updateSetting)

        if (response.status !== HTTP_CODE.SUCCESS) {
          throw new Error(response.data.message) //@TODO:check format
        }
        yield put(setSuccess('Update setting successfully!'))
        yield put(setCurrent(undefined))
      } catch (error: any) {
        yield put(setError(error.message))
      }
    }
  )
}
