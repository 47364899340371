import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import SettingReferenceTable from './components/SettingReferenceTable'
import SettingReferenceFilter from './components/SettingReferenceFilter'

type Props = {
  className: string
}
const SettingReferencePage: FC<Props> = (props) => {
  const intl = useIntl()

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({id: 'SETTING_REFERENCE.PAGE_TITLE'})}
      </PageTitle>
      <div className='card mb-10'>
        <div className='card-header border-0 pt-5'>
          <SettingReferenceFilter />
        </div>
        <div className='card-body p-10'>
          <SettingReferenceTable />
        </div>
      </div>
    </>
  )
}

export default SettingReferencePage
