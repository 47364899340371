import { COUNTRY_CODES } from "../../modules/profile/constants";
import { CurrencyCode, CurrencySymbol } from "./pricing.constant";

export const mappingCountryCodeToCurrency = (countryCode: COUNTRY_CODES): CurrencyCode => {
    switch (countryCode) {
        case COUNTRY_CODES.JP:
            return CurrencyCode.JPY;
        case COUNTRY_CODES.US:
            return CurrencyCode.USD;
        default:
            return CurrencyCode.USD;
    }

}
export const mappingCurrencyCodeToCurrencySymbol = (countryCode: CurrencyCode): string => {
    switch (countryCode) {
        case CurrencyCode.JPY:
            return CurrencySymbol.JPY;
        case CurrencyCode.USD:
            return CurrencySymbol.USD;
        default:
            return CurrencySymbol.USD;
    }

}
export const displayAmountBaseOnCurrency = (amount: number, currency: CurrencyCode): string => {
    if (currency === CurrencyCode.USD) {
        amount = amount / 100;
    }
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency,
    }).format(amount);
}