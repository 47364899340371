import {Col, Row} from 'react-bootstrap-v5'
import Modal from 'react-bootstrap/Modal'
import {useIntl} from 'react-intl'
import {FC, useState} from 'react'
import {Link} from 'react-router-dom'
import settingImg from '../../img/image-example.png'
import {useDispatch} from 'react-redux'
import {setShowWelcomeModal} from '../../../../modules/auth/redux/AuthRedux'
import {KTSVG} from '../../../../../_metronic/helpers'
import {Dropdown1} from '../../../../../_metronic/partials'

type Props = {
  className?: string
  isShow: boolean
}

const WelcomeModal: FC<Props> = (props) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const onClose = () => {
    dispatch(setShowWelcomeModal(false))
  }
  return (
    <Modal
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      show={props.isShow}
      onHide={onClose}
      backdrop='static'
    >
      <Modal.Header closeButton>
        <p className='card-label fw-bolder fs-1 mb-1 '>
          {intl.formatMessage({id: 'SERVICE.ADS.RETOUCH.TITLE'})}
        </p>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <p className='card-label fw-bold fs-4 mb-1 mt-3'>
            {intl.formatMessage({id: 'SERVICE.ADS.RETOUCH.DES'})}
          </p>
        </Row>
        <Row>
          <Col xs={6}>
            <div className='card-body pt-5'>
              <div className='d-flex align-items-center mb-7'>
                <div className='symbol symbol-50px me-5'>
                  <span className='symbol-label bg-light-warning'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen003.svg'
                      className='svg-icon-2x svg-icon-warning'
                    />
                  </span>
                </div>
                <div className='d-flex flex-column'>
                  <a href='#' className='text-dark text-hover-primary fs-6 fw-bolder'>
                    {intl.formatMessage({id: 'SERVICE.ADS.FREE'})}
                  </a>
                  <span className='text-muted fw-bold'>
                    {' '}
                    {intl.formatMessage({id: 'SERVICE.ADS.FREE.DES'})}
                  </span>
                </div>
              </div>
              {/* end::Item */}
              {/* begin::Item */}
              <div className='d-flex align-items-center mb-7'>
                {/* begin::Symbol */}
                <div className='symbol symbol-50px me-5'>
                  <span className='symbol-label bg-light-success'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen006.svg'
                      className='svg-icon-2x svg-icon-success'
                    />
                  </span>
                </div>
                {/* end::Symbol */}
                {/* begin::Text */}
                <div className='d-flex flex-column'>
                  <a href='#' className='text-dark text-hover-primary fs-6 fw-bolder'>
                    {intl.formatMessage({id: 'SERVICE.ADS.WORK'})}
                  </a>
                  <span className='text-muted fw-bold'>
                    {' '}
                    {intl.formatMessage({id: 'SERVICE.ADS.WORK.DES'})}
                  </span>
                </div>
                {/* end::Text */}
              </div>
              {/* end::Item */}
              {/* begin::Item */}
              <div className='d-flex align-items-center mb-7'>
                {/* begin::Symbol */}
                <div className='symbol symbol-50px me-5'>
                  <span className='symbol-label bg-light-primary'>
                    <KTSVG
                      path='/media/icons/duotune/finance/fin010.svg'
                      className='svg-icon-2x svg-icon-primary'
                    />
                  </span>
                </div>
                {/* end::Symbol */}
                {/* begin::Text */}
                <div className='d-flex flex-column'>
                  <a href='#' className='text-dark text-hover-primary fs-6 fw-bolder'>
                    {intl.formatMessage({id: 'SERVICE.ADS.PAYMENT'})}
                  </a>
                  <span className='text-muted fw-bold'>
                    {intl.formatMessage({id: 'SERVICE.ADS.PAYMENT.DES'})}
                  </span>
                </div>
                {/* end::Text */}
              </div>
            </div>
            {/* <div className='d-flex mt-5 fs-6'>
              <input
                className='form-check-input widget-9-check'
                type='checkbox'
                size={10}
                checked={disableShowAds}
                onChange={() => {
                  setDisableShowAds(!disableShowAds)
                }}
              />
              <p className='card-label ms-3 '>{intl.formatMessage({id: 'SERVICE.ADS.NOT_SHOW'})}</p>
            </div> */}
          </Col>
          <Col xs={6}>
            <img alt='img' style={{width:'100%'}}src={settingImg} />
          </Col>
        </Row>
        <Row>
          <Col xs={3}></Col>
          <Col xs={6} className='text-center'>
            <Link
              to={'./orders/create?serviceId=1'}
              onClick={() => onClose()}
              className='mt-2 btn btn-primary btn-outline-dashed btn-outline-dark btn-active-light-dark'
            >
              {intl.formatMessage({id: 'SERVICE.ADS.RETOUCH.ACTION'})}
            </Link>
          </Col>
          <Col xs={3}></Col>
        </Row>
      </Modal.Body>
    </Modal>
  )
}
export default WelcomeModal
