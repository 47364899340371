import {FC, useState} from 'react'
import {OrderItemModel} from '../models/OrderItemModel'
import {useIntl} from 'react-intl'
import {LazyLoadImage} from 'react-lazy-load-image-component'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import ItemActionBox from './ItemActionBox'
import './itemGrid.scss'
import {itemStatusClassMapping} from '../utils'
import {selectSelectedItems, selectSelectedMode, setSelectedItem} from '../redux/OrderRedux'
import {useAppSelector} from '../../../../setup/redux/hooks'
import {useDispatch} from 'react-redux'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
interface Props {
  items: OrderItemModel[]
}
const ItemTable: FC<Props> = (props) => {
  const intl = useIntl()
  const selectMode = useAppSelector(selectSelectedMode)
  const selectedItems = useAppSelector(selectSelectedItems)
  const [targetItem, setTargetItem] = useState<OrderItemModel | undefined>(undefined)
  const [isTargetOuputItem, setIsTargetOuputItem] = useState(true)
  const dispatch = useDispatch()
  const items = props.items
  return (
    <div className='table-responsive'>
      <table className='table text-center'>
        <thead
          style={{
            borderBottom: '1px solid #EFF2F5',
          }}
        >
          <tr>
            {selectMode && <th className='p-0 w-50px'></th>}

            <th className='pl-0 fw-bold text-muted text-uppercase d-flex justify-content-around'>
              <p style={{marginBottom:'0px'}}>{intl.formatMessage({id: 'OrderDetail.Form.ITEMS'})}</p>
              <p style={{marginBottom:'0px'}}>{intl.formatMessage({id: 'OrderDetail.Form.ITEMS_OUTPUT'})}</p>
            </th>
            <th className='pl-0 fw-bold text-muted text-uppercase'>
              {intl.formatMessage({id: 'OrderDetail.Form.ITEM_INFO'})}
            </th>
            {/* 
            <th className='text-right pr-0 fw-bold text-muted text-uppercase'>
              {intl.formatMessage({id: 'OrderDetail.Form.ITEM_PRICE'})}
            </th>
            <th className='text-right pr-0 fw-bold text-muted text-uppercase'>
              {intl.formatMessage({id: 'OrderDetail.Form.ITEM_STATUS'})}
            </th> */}
            <th className='text-right pr-0 fw-bold text-muted text-uppercase'>
              {intl.formatMessage({id: 'OrderDetail.Form.ITEM_ACTION'})}
            </th>
          </tr>
        </thead>
        <tbody>
          {items.map((item: OrderItemModel) => (
            <tr key={item.id} className='fw-bold fs-6 border-bottom-0'>
              {selectMode && (
                <td className='align-middle'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input widget-9-check'
                      type='checkbox'
                      checked={selectedItems.includes(item.id)}
                      value={item.id}
                      onChange={() => {
                        dispatch(
                          setSelectedItem({id: item.id, isAdd: !selectedItems.includes(item.id)})
                        )
                      }}
                    />
                  </div>
                </td>
              )}
              <td style={{margin:'auto'}}className='align-middle order-item-row justify-content-between d-flex rotate'>
                <div
                  onClick={() => {
                    if (item.inputImageUrl) {
                      setIsTargetOuputItem(false)
                      setTargetItem(item)
                    }
                  }}
                >
                  <LazyLoadImage
                    effect='blur'
                    placeholderSrc={toAbsoluteUrl('/media/loading-bg.gif')}
                    src={item.inputImageUrl?.tiny}
                    onError={(event: any) => {
                      if (event?.target?.src)
                        event.target.src = toAbsoluteUrl('/media/loading-bg.gif') // Replace with your default image URL
                    }}
                  />
                </div>
                <KTSVG
                  path='/media/icons/duotune/arrows/arr024.svg'
                  className='rotate-180 svg-icon-success svg-icon-2hx mx-auto'
                />
                <div
                  onClick={() => {
                    if (item.outputImageUrl) {
                      setIsTargetOuputItem(true)
                      setTargetItem(item)
                    }
                  }}
                >
                  <LazyLoadImage
                    effect='blur'
                    placeholderSrc={toAbsoluteUrl('/media/loading-bg.gif')}
                    src={item.outputImageUrl?.tiny}
                    onError={(event: any) => {
                      if (event?.target?.src) {
                        setTimeout(() => {
                          event.target.src = item.outputImageUrl?.tiny // Replace with your default image URL
                        }, 1000)
                        event.target.src = toAbsoluteUrl('/media/loading-bg.gif')
                      }
                    }}
                  />
                </div>
              </td>
              <td className='border-top-0 py-4 align-middle text-wrap '>
                <div className='d-flex flex-column align-middle'>
                  <Link
                    to={'/orders/' + item.orderId + '/items/' + item.id}
                    className='text-dark fw-bolder text-hover-primary fs-6 mb-1'
                  >
                    {item.name}
                  </Link>
                 <span> {item.price}  {intl.formatMessage({id: 'Item.Credit'})}</span> 
                  <span
                    className={`badge ${itemStatusClassMapping(
                      item.status
                    )} fw-bolder mt-1 mx-auto px-4 py-3`}
                  >
                    {intl.formatMessage({id: 'Item.status.' + item.status})}
                  </span>
                </div>
              </td>

              {/* <td className='text-primary border-top-0 pr-0 py-4 align-middle'>{item.price}</td>
              <td className='border-top-0 py-4 align-middle'>
                <span
                  className={`badge ${itemStatusClassMapping(
                    item.status
                  )} fw-bolder me-auto px-4 py-3`}
                >
                  {intl.formatMessage({id: 'Item.status.' + item.status})}
                </span>
              </td> */}
              <td className='align-middle'>
                <ItemActionBox item={item} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {targetItem && (targetItem.outputImageUrl || targetItem.inputImageUrl) && (
        <Lightbox
          mainSrc={
            isTargetOuputItem
              ? (targetItem.outputImageUrl?.medium as string)
              : (targetItem.inputImageUrl?.medium as string)
          }
          nextSrc={isTargetOuputItem ? undefined : targetItem.inputImageUrl?.medium}
          prevSrc={isTargetOuputItem ? targetItem.outputImageUrl?.medium : undefined}
          onMovePrevRequest={() => setIsTargetOuputItem(false)}
          onMoveNextRequest={() => setIsTargetOuputItem(true)}
          onCloseRequest={() => setTargetItem(undefined)}
        />
      )}
    </div>
  )
}

export default ItemTable
