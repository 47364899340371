
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import { ThumbnailImageModel } from "../../shared/models/ThumbnailImageModel";
import { PlanType } from "../setting-reference/constants";
import { OrderStatus, ItemStatus, ImageThumbnailSize } from "./constants";
import { OrderItemModel } from "./models/OrderItemModel";

export const orderStatusClassMapping = (orderStatus: OrderStatus) => {
    let statusClass = ''
    switch (orderStatus) {
        case OrderStatus.UPLOADED:
            statusClass = 'badge-light-primary';
            break;
        case OrderStatus.PENDING:
            statusClass = 'badge-light-info';
            break;
        case OrderStatus.AI_PROCESSING:
            statusClass = 'badge-light-warning';
            break;
        case OrderStatus.REJECTED:
            statusClass = 'badge-light-danger';
            break;
        case OrderStatus.PROCESSING:
            statusClass = 'badge-light-warning';
            break;
        case OrderStatus.COMPLETED:
            statusClass = 'badge-light-success';
            break;
        case OrderStatus.AI_FAILED:
            statusClass = 'badge-light-danger';
            break;
        default:
            statusClass = 'badge-light-warning';
    }
    return statusClass;
}

export const itemStatusClassMapping = (itemStatus: ItemStatus) => {
    let statusClass = ''
    switch (itemStatus) {
        case ItemStatus.UPLOADED:
            statusClass = 'badge-light-primary';
            break;
        case ItemStatus.PENDING:
            statusClass = 'badge-light-info';
            break;
        case ItemStatus.AI_PROCESSING:
            statusClass = 'badge-light-warning';
            break;
        case ItemStatus.DONE:
            statusClass = 'badge-light-success';
            break;
        case ItemStatus.FAIL:
            statusClass = 'badge-light-danger';
            break;
        default:
            statusClass = 'badge-light-warning';
    }
    return statusClass;
}

export const getMaxItemRequestWithUserPlan = (userPlan: PlanType) => {
    switch (userPlan) {
        case PlanType.ENTERPRISE:
            return 100;
        case PlanType.SUBSCRIPTION:
            return 50;
        default:
            return 10;
    }
}