import {FC, useState} from 'react'
import {OrderItemModel} from '../models/OrderItemModel'
import {useIntl} from 'react-intl'
import {LazyLoadImage} from 'react-lazy-load-image-component'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {ItemStatus} from '../constants'
import {itemStatusClassMapping} from '../utils'

import {OrderModel} from '../models/OrderModel'
import './itemGrid.scss'
import ItemActionBox from './ItemActionBox'
import {useAppSelector} from '../../../../setup/redux/hooks'
import {selectSelectedItems, selectSelectedMode, setSelectedItem} from '../redux/OrderRedux'
import {useDispatch} from 'react-redux'
interface Props {
  items: OrderItemModel[]
  order: OrderModel
}

const ItemGrid: FC<Props> = (props) => {
  const items = props.items
  const intl = useIntl()
  const dispatch = useDispatch()
  const selectMode = useAppSelector(selectSelectedMode)
  const selectedItems = useAppSelector(selectSelectedItems)
  return (
    <div className='row g-6 g-xl-9 '>
      {items.map((item) => {
        const isDisabled = [ItemStatus.AI_PROCESSING, ItemStatus.PENDING].includes(item.status)
        const targetImage = item.outputImageUrl?.tiny
          ? item.outputImageUrl.tiny
          : item.inputImageUrl?.tiny      
        return (
          <div key={item.id} className='col-md-6 order-card col-xl-4'>
            <div className='card shadow border border-2 border-gray-300 border-hover'>
              <div className='border-0 order-item-card-title order-detail-item-card-title'>
                <Link to={'/orders/' + item.orderId + '/items/' + item.id}>
                  <div className='card-title m-0'>
                    <LazyLoadImage
                      src={targetImage ?? toAbsoluteUrl('/media/loading-bg.gif')}
                      alt={item.name}
                      placeholderSrc={toAbsoluteUrl('/media/loading-bg.gif')}
                      className='rounded-top'
                      effect='blur'
                      onError={(event: any) => {
                        if (event?.target?.src)
                          event.target.src = toAbsoluteUrl('/media/loading-bg.gif') // Replace with your default image URL
                      }}
                    />
                  </div>
                </Link>
                {selectMode && (
                  <div className='card-toolbar card-selection-box'>
                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                      <input
                        className='form-check-input widget-9-check'
                        type='checkbox'
                        checked={selectedItems.includes(item.id)}
                        value={item.id}
                        onChange={() => {
                          dispatch(
                            setSelectedItem({id: item.id, isAdd: !selectedItems.includes(item.id)})
                          )
                        }}
                      />
                    </div>
                  </div>
                )}
                <div className='card-toolbar card-order-status'>
                  <span
                    className={`badge ${itemStatusClassMapping(
                      item.status
                    )} fw-bolder me-auto px-4 py-3`}
                  >
                    {intl.formatMessage({id: 'Item.status.' + item.status})}
                  </span>
                </div>
              </div>
              <div className='card-body p-3 '>
                <div className='d-flex justify-content-between mb-3'>
                  <div className='fs-3 fw-bolder text-dark order-card-title'>
                    <Link
                      className='text-dark'
                      to={'/orders/' + item.orderId + '/items/' + item.id}
                    >
                      {item.name}
                    </Link>
                  </div>
                  <div className='card-order-actions'>
                    <ItemActionBox item={item} />
                  </div>
                </div>

                <div className='d-flex flex-wrap justify-content-between'>
                  <div className='border border-gray-300 border-dashed rounded py-3 px-4 '>
                    <div className='fs-6 text-gray-800 fw-bolder'>{item.price}</div>
                    <div className='fw-bold text-gray-400'>
                      {intl.formatMessage({id: 'Item.Credit'})}
                    </div>
                  </div>
                  <div className='border border-gray-300 border-dashed rounded  py-3 px-4 '>
                    <div className='fs-6 text-gray-800 fw-bolder'>{props.order.setting?.name}</div>
                    <div className='fw-bold text-gray-400'>
                      {intl.formatMessage({id: 'Item.setting'})}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {isDisabled && (
              <div className='order-card-disable d-flex justify-content-center'>
                <div className='spinner-grow m-auto text-warning' role='status'>
                  <span className='sr-only'>Loading...</span>
                </div>
              </div>
            )}
          </div>
        )
      })}
    </div>
  )
}

export default ItemGrid
