import {FC, useEffect, useRef, useState} from 'react'
import {OrderItemModel} from '../../orders/models/OrderItemModel'
import {Link} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import ReactBeforeSliderComponent from 'react-before-after-slider-component'
import Tooltip from 'react-tooltip-lite'
import {LazyLoadImage} from 'react-lazy-load-image-component'
import {ItemStatus, OrderType} from '../../orders/constants'
import {useDispatch} from 'react-redux'
import {filterDataChange, selectItemList, selectItemsFilter} from '../redux/itemRedux'
import {useAppSelector} from '../../../../setup/redux/hooks'

import {ItemDisplayMode} from '../constants'
import ItemViewMode from './itemViewMode'
import './itemViewBox.scss'
import ItemRating from './itemRating'
import ItemActionBox from '../../orders/components/ItemActionBox'
interface Props {
  item: OrderItemModel
  onNext: (id: number) => void
  onPrev: (id: number) => void
  toggleSettings: () => void
  onSelect: (id: number) => void
}

const ItemViewBox: FC<Props> = (props) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const filter = useAppSelector(selectItemsFilter)
  const orderItems = useAppSelector(selectItemList)
  const didRequest = useRef(false)
  const [viewMode, setViewMode] = useState(
    props.item.status === ItemStatus.DONE ? ItemDisplayMode.Compare : ItemDisplayMode.Original
  )
  // const [currenItem, setCurrentItem] = useState(props.item)
  const currenItem = props.item
  const getAllOrderItems = async () => {
    if (!didRequest.current) {
      dispatch(filterDataChange({...filter, orderId: currenItem.orderId, take: 50}))
    }

    return () => (didRequest.current = true)
  }
  useEffect(() => {
    getAllOrderItems()
  }, [props.item])
  const setCurrentOrderItem = (itemId: number) => {
    const targetItem = orderItems.find((item) => item.id === itemId)
    if (targetItem) {
      props.onSelect(itemId)
    }
  }

  const renderActions = () => {
    return (
      <>
        {currenItem.type !== OrderType.PROFESSIONAL && (
          <Tooltip content={intl.formatMessage({id: 'ITEM.ACTIONS.SETTING'})} className='tooltips'>
            <button
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                setViewMode(ItemDisplayMode.Output)
                props.toggleSettings()
              }}
              disabled={currenItem.status !== ItemStatus.DONE}
              className='btn btn-icon btn-sm btn-info ms-3'
            >
              <i className='bi fs-2 bi-gear'></i>
            </button>
          </Tooltip>
        )}

        <Tooltip content={intl.formatMessage({id: 'ITEM.ACTIONS.BACK'})} className='tooltips'>
          <Link
            to={'/orders/' + props.item.orderId}
            className='btn btn-icon btn-sm btn-secondary ms-3'
          >
            <i className='bi fs-1 bi-arrow-left-short'></i>
          </Link>
        </Tooltip>
      </>
    )
  }
  return (
    <div className='card'>
      <div className='card-header cursor-pointer d-flex justify-content-between'>
        <div className='card-toolbar'>
          <ItemViewMode
            isOrderCompleted={currenItem.status === ItemStatus.DONE}
            mode={viewMode}
            onChangeMode={setViewMode}
          />
        </div>
        <div className='card-toolbar'>
          <ItemRating item={currenItem} />
        </div>
        <div className='card-toolbar'>
          <ItemActionBox item={currenItem} />
          {renderActions()}
        </div>
      </div>
      <div className='main-item-image card-body p-5 d-flex justify-content-between'>
        <button
          type='button'
          disabled={currenItem.id === orderItems[0]?.id}
          className='btn btn-light-outline-primary'
          onClick={() => setCurrentOrderItem(currenItem.id + 1)}
        >
          <i
            style={{
              fontSize: '50px',
            }}
            className='bi bi-arrow-left-circle text-primary font-weight-bold'
          ></i>
        </button>
        <div style={{maxHeight: '700px'}}>
          {viewMode !== ItemDisplayMode.Compare && (
            <LazyLoadImage
              src={
                viewMode === ItemDisplayMode.Original
                  ? currenItem.inputImageUrl?.medium
                  : currenItem.outputImageUrl?.medium
              }
              alt={currenItem.name}
              style={{
                transition: 'opacity 0.5s, filter 0.5s', // Add transitions for both opacity and filter
              }}
              placeholderSrc={toAbsoluteUrl('/media/loading-bg.gif')}
              effect='blur'
              onError={(event: any) => {
                if (event?.target?.src) event.target.src = toAbsoluteUrl('/media/loading-bg.gif') // Replace with your default image URL
              }}
            />
          )}

          {viewMode === ItemDisplayMode.Compare && (
            <ReactBeforeSliderComponent
              className='compare-detail-item'
              firstImage={{
                imageUrl: currenItem.outputImageUrl?.medium ?? '',
                alt: 'Before',
              }}
              secondImage={{
                imageUrl: currenItem.inputImageUrl?.medium ?? '',
                alt: 'After',
              }}
            />
          )}
        </div>
        <button
          type='button'
          disabled={currenItem.id === orderItems[orderItems.length - 1]?.id}
          className='btn btn-light-outline-primary'
          onClick={() => setCurrentOrderItem(currenItem.id - 1)}
        >
          <i
            style={{
              fontSize: '50px',
            }}
            className='bi text-primary bi-arrow-right-circle'
          ></i>
        </button>
      </div>
      <div className='card-footer py-5 text-center d-flex overflow-auto'>
        {orderItems.map((itm) => {
          const targetImg =
            itm.status === ItemStatus.DONE ? itm.outputImageUrl?.tiny : itm.inputImageUrl?.tiny
          return (
            <div key={itm.id} className='position-relative'>
              <LazyLoadImage
                height={'120px'}
                placeholderSrc={toAbsoluteUrl('/media/loading-bg.gif')}
                style={{
                  paddingLeft: '20px',
                  paddingRight: '20px',
                  cursor: 'pointer',
                }}
                src={targetImg}
                alt={itm.name}
                onClick={() => props.onSelect(itm.id)}
                effect='opacity'
                onError={(event: any) => {
                  if (event?.target?.src) event.target.src = toAbsoluteUrl('/media/loading-bg.gif') // Replace with your default image URL
                }}
              />
              {itm.id === currenItem.id && (
                <i
                  style={{
                    top: '10px',
                    right: '30px',
                  }}
                  className='position-absolute text-success fs-2 bi bi-check-circle-fill'
                ></i>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}
export default ItemViewBox
