import axios from 'axios'
import { PaginateModel } from '../../../shared/models/PaginateModel'
import { ItemOptionModel } from '../../orders/models/ItemOptionModel'
import { ItemOutputModel } from '../models/ItemOutputModel'


const API_URL = process.env.REACT_APP_API_URL

const BASE_ITEM_URL = `${API_URL}/items`
/**
 * 
 * @param params 
 * @returns 
 */
export function getItems(params: PaginateModel) {
    return axios.get(BASE_ITEM_URL, {
        params,
    })
}
/**
 * 
 * @returns 
 */
export function getAllItemsStatus() {
    return axios.get(BASE_ITEM_URL + '/status')
}



/**
 * 
 * @param id 
 * @returns 
 */
export function getDetailItem(id: string) {
    return axios.get<any>(BASE_ITEM_URL + "/" + id)
}
/**
 * 
 * @param id 
 * @returns 
 */
export function getDetailItemOutput(id: string) {
    return axios.get<any>(BASE_ITEM_URL + "/" + id + "/output")
}

/**
 * 
 * @param id 
 * @returns 
 */
export function updateItemImage(newItem: ItemOutputModel) {
    return axios.put<any>(BASE_ITEM_URL + "/" + newItem.id + "/output",
        newItem
    )
}
/**
 * 
 * @param id 
 * @returns 
 */
export function updateDetailItem(id: number, star: number) {
    return axios.put<any>(BASE_ITEM_URL + "/" + id + "/star", {
        star: star
    })
}


export function retryAiWithSetting(id: number, settings?: ItemOptionModel[] | undefined) {
    return axios.put<any>(BASE_ITEM_URL + "/" + id + "/ai-retry", {
        settings
    })
}