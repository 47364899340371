import React, {useEffect} from 'react'
import {Footer} from './components/Footer'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {Toolbar} from './components/toolbar/Toolbar'
import {ScrollTop} from './components/ScrollTop'
import {Content} from './components/Content'
import {PageDataProvider, useLayout} from './core'
import {useLocation} from 'react-router-dom'
import {MenuComponent} from '../../_metronic/assets/ts/components'
import clsx from 'clsx'
import CheckoutModal from '../../app/pages/pricing/modal/checkoutModal'
import WelcomeModal from '../../app/pages/orders/components/modals/welcomeModal'
import {useAppSelector} from '../../setup/redux/hooks'
import {selectIsShowWelcomeModal} from '../../app/modules/auth'
import DownloadOrderModal from '../../app/pages/orders/components/modals/downloadOrderModal'

const MasterLayout: React.FC = ({children}) => {
  const {classes} = useLayout()
  const isShowWelcomeModal = useAppSelector(selectIsShowWelcomeModal)
  const location = useLocation()
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])
  return (
    <PageDataProvider>
      <div className='page d-flex flex-row flex-column-fluid'>
        <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
          <HeaderWrapper />

          <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
            <Toolbar />
            <div
              className={clsx(
                'd-flex flex-column-fluid align-items-start',
                classes.contentContainer.join(' ')
              )}
              id='kt_post'
            >
              <Content>{children}</Content>
            </div>
          </div>

          <CheckoutModal />
          <DownloadOrderModal />

          <WelcomeModal isShow={isShowWelcomeModal} />
          <Footer />
        </div>
      </div>

      <ScrollTop />
    </PageDataProvider>
  )
}

export {MasterLayout}
