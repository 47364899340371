import { call, put, takeLatest } from "redux-saga/effects";
import { getAll, getAllCategories, getServiceDetail, setAllCategories, setServiceDetail, setServiceList } from "./serviceRedux";
import { toast } from "react-toastify";
import { getServiceById, getServiceCategories, getServices } from "./serviceAPI";
import { ResponseGenerator } from "../../../shared/models/ResponseGenerator";
import { HTTP_CODE } from "../../../shared/constants";
import { CategoryModel } from "../models/CategoryModel";
import { ServiceModel } from "../models/ServiceModel";
import { ListResponseModel } from "../../../shared/models/ListResponseModel";
import { intl } from "../../../../_metronic/i18n/i18nProvider";

export default function* saga() {
    yield takeLatest(getAllCategories.type, function* getAllServiceCategories() {
        try {
            const response: ResponseGenerator = yield call(getServiceCategories)
            if (response && response.status === HTTP_CODE.SUCCESS) {
                const categories: CategoryModel[] = response.data.data;
                yield put(setAllCategories(categories))
            }
        } catch (err: any) {
            toast.error(intl.formatMessage({ id: "SERVER." + err.message }))


        }
    });
    yield takeLatest(getAll.type, function* getAllServices() {
        try {
            const response: ResponseGenerator = yield call(getServices)
            if (response && response.status === HTTP_CODE.SUCCESS) {
                const services: ListResponseModel<ServiceModel> = response.data;
                yield put(setServiceList(services))
            }
        } catch (err: any) {
            toast.error(intl.formatMessage({ id: "SERVER." + err.message }));
        }
    });
    yield takeLatest(getServiceDetail.type, function* getDetail(action: ReturnType<typeof getServiceDetail>) {
        try {
            const response: ResponseGenerator = yield call(getServiceById, action.payload)
            if (response && response.status === HTTP_CODE.SUCCESS) {
                const service: ServiceModel = response.data;
                yield put(setServiceDetail(service))
            } else {
                throw new Error("Can not get service detail.please try again")
            }
        } catch (err: any) {
            err.message && toast.error(intl.formatMessage({ id: "SERVER." + err.message }))

        }
    });
}