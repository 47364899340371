import {ref, onChildAdded, get} from 'firebase/database'
import {database} from '../../../../../setup/firebase'
import store from '../../../../../setup/redux/Store'

export const onNotifications = (cb: (values: any) => void) => {
  const {
    auth: {user},
  } = store.getState()
  if (!user) return
  const url = `server/users/${user.id}/notifications`
  return onChildAdded(ref(database, url), (snapshot) => {
    const data = snapshot.val()
    cb(data)
  })
}

export const getNotificationsSizeOnce = (cb: (value: any) => void) => {
  const {
    auth: {user},
  } = store.getState()
  if (!user) return
  const url = `server/users/${user.id}/notifications`
  get(ref(database, url))
    .then((snapshot) => {
      if (snapshot.exists()) {
        cb(snapshot.size)
      } else {
        console.log('No data available')
      }
    })
    .catch((error) => {
      console.error(error)
    })
}
