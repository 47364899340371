/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {useLang, setLanguage} from '../../../_metronic/i18n/Metronici18n'

const languages = [
  {
    lang: 'en',
    name: 'English',
    flag: toAbsoluteUrl('/media/flags/united-states.svg'),
  },
  {
    lang: 'ja',
    name: 'Japan',
    flag: toAbsoluteUrl('/media/flags/japan.svg'),
  },
]

const Languages: FC = () => {
  const lang = useLang()
  const currentLanguage = languages.find((x) => x.lang === lang)
  return (
    <ul className='menu menu-gray-600 menu-hover-primary fw-bold order-1 align-items-center mb-3 mb-md-0'>
      {languages.map((l) => (
        <li className='menu-item' key={l.lang}>
          <a
            href='#'
            className={clsx('menu-link px-3', {active: l.lang === currentLanguage?.lang})}
            onClick={() => {
              setLanguage(l.lang)
            }}
          >
            <img className='rounded-1 h-20px' src={l.flag} alt='country' />
          </a>
        </li>
      ))}
    </ul>
  )
}

export {Languages}
