import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { PaginateModel } from "../../../shared/models/PaginateModel"
import { BillingModel } from "../models/BillingModel"
import { ListResponseModel } from "../../../shared/models/ListResponseModel"
import { toast } from "react-toastify"
import { RootState } from "../../../../setup/redux/Store"

export interface IBillingState {
  list: BillingModel[]
  filter: PaginateModel
  loading: boolean
}

const initialState: IBillingState = {
  list: [],
  filter: {
    page: 1,
    take: Number(process.env.REACT_APP_DEFAULT_PAGE_SIZE),
    itemCount: 0,
    pageCount: 1,
  } as PaginateModel,
  loading: false,
}


export const billingSlice = createSlice({
  name: "billing",
  initialState,
  reducers: {
    getAll: (state, action: PayloadAction<any | undefined>) => {
      state.loading = true;
    },
    setList: (state, action: PayloadAction<ListResponseModel<BillingModel>>) => {
      state.loading = false;
      state.list = action.payload.data;
      state.filter = action.payload.meta;
    },
    filterDataChange: (state, action: PayloadAction<PaginateModel>) => {
      state.loading = true;
      state.filter = action.payload;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.loading = false;
      toast.error(action.payload);
      
    },
    setSuccess: (state, action: PayloadAction<string>) => {
      state.loading = false;
      toast.success(action.payload);
    },
  }
})
export const {
  setSuccess,
  getAll,
  setList,
  filterDataChange,
  setError,
} = billingSlice.actions;
export const selectBillingLoading = (state: RootState) => state.billing.loading;
export const selectBillingList = (state: RootState) => state.billing.list;
export const selectBillingFilter = (state: RootState) => state.billing.filter;
export default billingSlice.reducer;