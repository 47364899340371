/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTSVG} from '../../../helpers'
import {Link} from 'react-router-dom'

type Props = {
  className: string
  number?: number
  color: string
  svgIcon: string
  iconColor: string
  title: string
  subtitle?: string
  description: string
  link?: string
  extraInfo?: string
}

const StatisticsWidget5: React.FC<Props> = ({
  className,
  color,
  svgIcon,
  iconColor,
  title,
  description,
  number,
  link,
  subtitle,
  extraInfo,
}) => {
  return (
    <Link to={link} className={`card bg-${color} hoverable ${className}`}>
      {/* begin::Body */}
      <div className='card-body'>
        <div className={`text-inverse-${color} fw-bolder d-flex`}>
          <span style={{fontSize: '30px', lineHeight: '39px'}}>
            {Intl.NumberFormat('en-US').format(number ?? 0)}
          </span>

          <KTSVG path={svgIcon} className={`svg-icon-${iconColor} svg-icon-3x ms-1`} />
        </div>

        <span className={`text-inverse-${color} fs-7 fw-bolder`}>{extraInfo}</span>
        <div className={`text-inverse-${color} fw-bolder fs-2 mb-2 mt-5`}>
          {title} {subtitle && <span className={` fs-7 `}>{subtitle}</span>}
        </div>

        <div className={`fw-bold text-inverse-${color} fs-7`}>{description}</div>
      </div>
      {/* end::Body */}
    </Link>
  )
}

export {StatisticsWidget5}
