import {FC, useEffect, useRef, useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import Button from 'react-bootstrap/Button'
import {WatermarkSettingModel} from '../../models/WatermarkSettingModel'
import './waterMarkSettingStyle.css'
import {toast} from 'react-toastify'
import {convertFileToString} from '../../utils'
import {KTSVG} from '../../../../../_metronic/helpers'
import RangeSlider from 'react-bootstrap-range-slider'
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css'
import {WatermarkPosition, MARGIN} from '../../constants'
import {uploadFile} from '../../redux/SettingReferenceAPIs'
import {HTTP_CODE} from '../../../../shared/constants'
import { ORDER_IMAGE_SUPPORTED_TYPE } from '../../../orders/constants'
type Props = {
  className?: string
  isShow: boolean
  onClose: any
  setting: WatermarkSettingModel
  onChanged: any
  baseImage: string
  settingData?: Array<any>
}

const WaterMarkSettingModal: FC<Props> = (props) => {
  const intl = useIntl()
  const elementRef = useRef<HTMLImageElement>(null)
  const imageElementRef = useRef<HTMLImageElement>(null)

  const [logoSize, setLogoSize] = useState({
    width: 0,
    height: 0,
    widthPx: 0,
    heighPx: 0,
  })
  const [setting, setSetting] = useState(props.setting)

  const handleFormSubmit = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    props.onChanged(setting)
  }

  let top = 'auto'
  let left = 'auto'
  let right = 'auto'
  let bottom = 'auto'
  switch (setting.position) {
    case WatermarkPosition.CENTER:
      left = logoSize.widthPx + 'px'
      top = logoSize.heighPx + 'px'
      break
    case WatermarkPosition.TOP_LEFT:
      left = `${MARGIN}px`
      top = `${MARGIN}px`
      break
    case WatermarkPosition.TOP_RIGHT:
      right = `${MARGIN}px`
      top = `${MARGIN}px`
      break
    case WatermarkPosition.BOTTOM_LEFT:
      left = `${MARGIN}px`
      bottom = `${MARGIN}px`
      break
    case WatermarkPosition.BOTTOM_RIGHT:
      right = `${MARGIN}px`
      bottom = `${MARGIN}px`
      break
  }

  const adjustLogoSizeToFitImage = (img_size: number[], logo_size: number[]) => {
    let [logo_width, logo_height] = logo_size
    const ratio = logo_width / logo_height
    // Image size after subtracting the margin
    let [img_width, img_height] = img_size
    // As Meng's requirement: margin should be 25px for both sides
    img_width = img_width - 2 * MARGIN
    img_height = img_height - 2 * MARGIN

    // Adjust logo size if logo width is larger than image with
    if (logo_width > img_width) {
      logo_width = img_width
      logo_height = Math.round(logo_width / ratio)
    }

    // Adjust logo size if logo height is larger than image height
    if (logo_height > img_height) {
      logo_height = img_height
      logo_width = Math.round(logo_height * ratio)
    }

    return [logo_width, logo_height]
  }

  const calculateLogoSize = () => {
    const img_width = imageElementRef.current?.offsetWidth as number
    const img_height = imageElementRef.current?.offsetHeight as number
    const logo_width = elementRef.current?.offsetWidth as number
    const logo_height = elementRef.current?.offsetHeight as number
    // Calculate the size of watermark
    const img_area = img_width * img_height
    const logo_area = logo_width * logo_height
    const resized_logo_area = img_area * (setting.size / 100)
    const factor = Math.sqrt(logo_area / resized_logo_area)
    let width = logo_width / factor
    let height = logo_height / factor

    return adjustLogoSizeToFitImage([img_width, img_height], [width, height])
  }

  const recalculateLogoPosition = () => {
    if (imageElementRef.current && elementRef.current) {
      const img_width = imageElementRef.current?.offsetWidth as number
      const img_height = imageElementRef.current?.offsetHeight as number
      const [width, height] = calculateLogoSize()

      setLogoSize({
        width,
        height,
        widthPx: (img_width - width) / 2,
        heighPx: (img_height - height) / 2,
      })
    }
  }

  return (
    <Modal
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      show={props.isShow}
      onHide={props.onClose}
      backdrop='static'
    >
      <Modal.Header closeButton>
        <h2 className='fw-bolder'>{intl.formatMessage({id: 'Watermark.setting.header'})}</h2>
      </Modal.Header>
      <form
        className='form w-100'
        onSubmit={handleFormSubmit}
        noValidate
        id='kt_watermark_setting_form'
      >
        <Modal.Body>
          <div className='row image-preview'>
            <img ref={imageElementRef} alt='base' src={props.baseImage} className='img-base' />
            <img
              alt='watermark'
              className='logo-preview'
              src={setting.watermarkUrl}
              ref={elementRef}
              onLoad={recalculateLogoPosition}
              style={{
                opacity: setting.opacity / 100,
                width: logoSize.width === 0 ? 'auto' : logoSize.width + 'px',
                height: logoSize.height === 0 ? 'auto' : logoSize.height + 'px',
                left: left,
                top: top,
                right: right,
                bottom: bottom,
              }}
            />
          </div>
          <div className='row py-10 '>
            <div className='col col-md-6 d-flex text-center'>
              <div className='row w-100'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  {intl.formatMessage({id: 'Watermark.setting.position'})}
                </label>
                <div className='col-lg-8 fv-row'>
                  <select
                    className='form-select form-select-solid form-select-lg me-3'
                    value={setting.position}
                    onChange={(e) => {
                      const value = Number(e.target.value)
                      setSetting({...setting, position: value})
                    }}
                  >
                    {props.settingData?.map((item: any) => (
                      <option key={item.key + '_option'} value={item.key}>
                        {intl.formatMessage({id: item.value})}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className='col col-md-6 d-flex justify-content-between'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                {intl.formatMessage({id: 'Watermark.setting.watermark'})}
              </label>
              <div className='col-lg-8 fv-row d-flex justify-content-between'>
                <img alt='watermark' className='watermark-logo' src={setting.watermarkUrl} />
                <button className='btn btn-sm btn-primary watermark-logo-input'>
                  <input
                    onChange={async (e: any) => {
                      const file = e.target.files[0]
                      if (file) {
                        const validImageTypes = ORDER_IMAGE_SUPPORTED_TYPE
                        const size = file.size
                        if (size > 1000000 || !validImageTypes.includes(file.type)) {
                          toast.error(
                            intl.formatMessage({id: 'SETTING_REFERENCE.UPLOAD.WRONG_FORMAT'})
                          )
                          return
                        }
                        const valueUrl: string = await convertFileToString(file)
                        const value: any = await uploadFile(file)
                        if (value && value.status === HTTP_CODE.SUCCESS) {
                          setLogoSize({...logoSize, width: 0, height: 0})
                          setSetting({
                            ...setting,
                            watermark: value.data.key,
                            watermarkUrl: valueUrl,
                          })
                        } else {
                          toast.error(value?.message ?? intl.formatMessage({id: 'SETTING_REFERENCE.UPLOAD.FAIL'}))
                        }
                      } else {
                        toast.error(intl.formatMessage({id: 'SETTING_REFERENCE.UPLOAD.REQUIRED'}))
                      }
                    }}
                    type='file'
                    id='avatar'
                    name='avatar'
                    accept='image/*'
                  ></input>
                  <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                </button>
              </div>
            </div>
          </div>

          <div className='row py-10'>
            <div className='col col-md-6 d-flex text-center'>
              <div className='row w-100'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  {intl.formatMessage({id: 'Watermark.setting.opacity'})}
                </label>
                <div className='col-lg-8 fv-row'>
                  <RangeSlider
                    value={setting.opacity}
                    min={0}
                    max={100}
                    tooltip='on'
                    tooltipPlacement='top'
                    onChange={(changeEvent) =>
                      setSetting({...setting, opacity: Number(changeEvent.target.value)})
                    }
                  />
                </div>
              </div>
            </div>
            <div className='col col-md-6 d-flex '>
              <div className='row w-100'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                  {intl.formatMessage({id: 'Watermark.setting.size'})}
                </label>
                <div className='col-lg-8 fv-row'>
                  <RangeSlider
                    value={setting.size}
                    min={1}
                    max={100}
                    tooltip='on'
                    tooltipPlacement='top'
                    onChange={(changeEvent) => {
                      setSetting({...setting, size: Number(changeEvent.target.value)})
                      recalculateLogoPosition()
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='d-flex justify-content-end flex-row-fluid'>
            <Button type='submit' className='btn btn-lg btn-primary' id='kt_setting_change_submit'>
              {intl.formatMessage({id: 'Watermark.button.ok'})}
            </Button>
            <Button
              type='button'
              variant='secondary'
              onClick={props.onClose}
              className='btn btn-light ms-3'
            >
              {intl.formatMessage({id: 'MODAL.PLAN.cancel_btn'})}
            </Button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  )
}
export default WaterMarkSettingModal
