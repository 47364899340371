import {FC} from 'react'
import Modal from 'react-bootstrap/Modal'
import {useIntl} from 'react-intl'
import {useAppSelector} from '../../../../setup/redux/hooks'
import {selectPaymentModalLink, setPaymentModalLink} from '../redux/pricingRedux'
import {useDispatch} from 'react-redux'
import {loadStripe} from '@stripe/stripe-js'
import {EmbeddedCheckoutProvider, EmbeddedCheckout} from '@stripe/react-stripe-js'
import {CurrencyCode} from '../pricing.constant'
type Props = {
  className?: string
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_APP_KEY as string, {locale: 'en'})
const stripeJpPromise = loadStripe(process.env.REACT_APP_STRIPE_JP_APP_KEY as string, {
  locale: 'ja',
})

const CheckoutModal: FC<Props> = (props) => {
  const intl = useIntl()
  const paymentModalLink = useAppSelector(selectPaymentModalLink)
  const dispatch = useDispatch()
  return (
    <Modal
      size='xl'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      show={paymentModalLink !== undefined}
      onHide={() => dispatch(setPaymentModalLink(undefined))}
      backdrop='static'
    >
      <Modal.Header closeButton>
        <h2 className='fw-bolder'>{intl.formatMessage({id: 'MODAL.PLAN.payment'})}</h2>
      </Modal.Header>

      <Modal.Body>
        {paymentModalLink && (
          <EmbeddedCheckoutProvider
            stripe={
              paymentModalLink.currency === CurrencyCode.USD ? stripePromise : stripeJpPromise
            }
            options={{clientSecret: paymentModalLink.link}}
          >
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        )}
      </Modal.Body>
    </Modal>
  )
}
export default CheckoutModal
