import axios from 'axios'
import { CurrencyCode } from '../pricing.constant'
import { PAYMENT_METHOD } from '../../../shared/constants'

const API_URL = process.env.REACT_APP_API_URL
export const PLAN_URL = `${API_URL}/plans`
export const ACCOUNT_URL = `${API_URL}/account`
export const PUBLIC_URL = `${API_URL}/public`
/**
 *
 * @param params
 * @returns
 */
export function getUserSubscriptionPlan(type: String | undefined) {
  if (type) return axios.get(PLAN_URL + '?type=' + type)
  return axios.get(PLAN_URL)
}

/**
 *
 * @param params
 * @returns
 */
export function sendContactRequest(requestData: {
  name: string
  email: string
  message: string
  language: string
}) {
  return axios.post(PUBLIC_URL + '/contact', requestData)
}


/**
 *
 * @returns
 */
export function updateUserSubscription(id: number, currency: CurrencyCode, paymentMethod: PAYMENT_METHOD) {
  return axios.put(ACCOUNT_URL + '/plans', { id, currency, paymentMethod })
}
