/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {UserModel} from '../../../../app/modules/auth/models/UserModel'
import {Languages} from './Languages'
import * as auth from '../../../../app/modules/auth/redux/AuthRedux'
import {useDispatch} from 'react-redux'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {useIntl} from 'react-intl'
import {useAppSelector} from '../../../../setup/redux/hooks'
import {logout} from '../../../../app/modules/auth/redux/AuthRedux'
import {USER_VERIFY_STATUS} from '../../../../app/modules/profile/constants'
import {getUserFullName} from '../../../../app/shared/utils'

const HeaderUserMenu: FC = () => {
  const user: UserModel | undefined = useAppSelector(auth.selectUser)
  const dispatch = useDispatch()
  const signOut = () => {
    dispatch(logout())
  }
  const intl = useIntl()
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      {user && (
        <div className='menu-item px-3'>
          <div className='menu-content d-flex align-items-center px-3'>
            <div className='symbol symbol-50px me-5'>
              <img alt='Logo' src={user.avatar || toAbsoluteUrl('/media/avatars/blank.png')} />
            </div>

            <div className='d-flex flex-column'>
              <div className='fw-bolder d-flex align-items-center fs-5'>
                {getUserFullName(user, intl.locale)}
                {/* generate checked icon for verified user */}
                {user.verifyStatus === USER_VERIFY_STATUS.VERIFIED ? (
                  <KTSVG
                    path='/media/icons/duotune/general/gen026.svg'
                    className='svg-icon-4 me-1 ms-1 svg-icon-success'
                  />
                ) : (
                  <button className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2 border-0'>
                    {user.plan}
                  </button>
                )}
              </div>
              <a href='#' className='fw-bold text-muted text-hover-primary text-break fs-7'>
                {user.email}
              </a>
            </div>
          </div>
        </div>
      )}
      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <Link to={'/crafted/pages/profile'} className='menu-link px-5'>
          {intl.formatMessage({id: 'HEADER.User.profile'})}
        </Link>
      </div>
      <div className='menu-item px-5'>
        <Link to={'/setting-references'} className='menu-link px-5'>
          {intl.formatMessage({id: 'HEADER.User.settingReference'})}
        </Link>
      </div>
      <div className='separator my-2'></div>

      <Languages />

      <div className='menu-item px-5'>
        <a onClick={signOut} className='menu-link px-5'>
          {intl.formatMessage({id: 'HEADER.User.sight_out'})}
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
