/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import OrderTable from './components/OrderTable'
import OrderTableFilter from './components/OrderTableFilter'

type Props = {
  className: string
}
const OrderPage: FC<Props> = (props) => {
  const intl = useIntl()

  return (
    <>
      <PageTitle
        breadcrumbs={[
          {
            title: '',
            path: '',
            isSeparator: false,
            isActive: true,
          },
        ]}
      >
        {intl.formatMessage({id: 'ORDER.LIST'})}
      </PageTitle>
      <div className='card mb-10' >
        <div className='card-body p-5'>
          <OrderTableFilter />
        </div>
        <div className='card-body p-5'>
          <OrderTable />
        </div>
      </div>
    </>
  )
}

export default OrderPage
