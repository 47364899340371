import {FC, useEffect, useRef} from 'react'
import {useIntl} from 'react-intl'
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {toast} from 'react-toastify'
import {useAppSelector} from '../../../../setup/redux/hooks'
import {SettingReferenceParamsModel} from '../models/SettingReferenceParamsModel'
import {SimpleListModel} from '../../../shared/models/SimpleListModel'
import {
  selectServicesList,
  selectSettingReferenceFilter,
  selectCategoriesList,
  getAllServices,
  filterDataChange,
  getServiceCategories,
} from '../redux/SettingReferenceRedux'
type Props = {
  className?: string
}
const SettingReferenceFilter: FC<Props> = (props) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const didRequest = useRef(false)
  const history = useHistory()
  const filter: SettingReferenceParamsModel = useAppSelector(selectSettingReferenceFilter)
  const serviceList: SimpleListModel[] = useAppSelector(selectServicesList)
  const categoryList: SimpleListModel[] = useAppSelector(selectCategoriesList)

  const handleFilterChange = (filterData: any) => {
    //auto reset paging to first page when filtering
    dispatch(filterDataChange({...filter, ...filterData, ...{page: 1}}))
  }
  useEffect(() => {
    try {
      if (!didRequest.current) {
        dispatch(getAllServices(false))
      }
    } catch (error: any) {
      toast.error(intl.formatMessage({id: 'SERVER.' + error.message}))
    }

    didRequest.current = true
  }, [])
  return (
    <>
      <div className='card-title'>
        <div className='d-flex align-items-center position-relative my-1'>
          <i className='bi bi-search fs-1 position-absolute ms-6'></i>
          <input
            type='text'
            data-kt-user-table-filter='search'
            className='form-control form-control-solid w-250px ps-14'
            placeholder={intl.formatMessage({id: 'SETTING_REFERENCE.BTN.SEARCH'})}
            value={filter.name}
            onChange={(e) => {
              if (e.target.value.length === 0) {
                handleFilterChange({name: undefined})
              } else if (e.target.value.length >= 2) {
                handleFilterChange({name: e.target.value})
              }
            }}
          />
        </div>
      </div>
      <div className='card-toolbar'>
        <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
          <button
            type='button'
            className='btn btn-light-primary me-3'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
          >
            <i className='fs-2 bi bi-funnel-fill'></i>
            {intl.formatMessage({id: 'SETTING_REFERENCE.BTN.FILTER'})}
          </button>
          <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
            <div className='px-7 py-5'>
              <div className='fs-5 text-dark fw-bolder'>
                {intl.formatMessage({id: 'SETTING_REFERENCE.FILTER_OPTIONS'})}
              </div>
            </div>

            <div className='px-7 py-5' data-kt-user-table-filter='form'>
              <div className='mb-10'>
                <label className='form-label fs-6 fw-bold'>
                  {intl.formatMessage({id: 'SETTING_REFERENCE.FILTER.SERVICE'})}:
                </label>
                <select
                  className='form-select form-select-solid fw-bolder'
                  data-kt-select2='true'
                  data-placeholder='Select option'
                  data-allow-clear='true'
                  data-kt-user-table-filter='role'
                  data-hide-search='true'
                  defaultValue={filter.serviceId}
                  onChange={(e) => {
                    const value = e.target.value.length === 0 ? undefined : e.target.value
                    if (value != filter.serviceId) {
                      handleFilterChange({serviceId: value, categoryId: undefined})
                      if (value) {
                        dispatch(getServiceCategories(value))
                      }
                    }
                  }}
                >
                  <option value=''>{intl.formatMessage({id: 'ECOMMERCE.COMMON.ALL'})}</option>
                  {serviceList?.map((service: any) => (
                    <option key={service.id} value={service.id}>
                      {intl.formatMessage({id: 'SERVICE.SERVICE.' + service.label})}
                    </option>
                  ))}
                </select>
              </div>
              {filter.serviceId != undefined && (
                <div className='mb-10'>
                  <label className='form-label fs-6 fw-bold'>
                    {intl.formatMessage({id: 'SETTING_REFERENCE.FILTER.CATEGORIES'})}:
                  </label>

                  <select
                    className='form-select form-select-solid fw-bolder'
                    data-kt-select2='true'
                    data-placeholder='Select option'
                    data-allow-clear='true'
                    data-kt-user-table-filter='two-step'
                    data-hide-search='true'
                    defaultValue={filter.categoryId}
                    onChange={(e) => {
                      const value = e.target.value.length === 0 ? undefined : e.target.value
                      if (value != filter.categoryId) {
                        handleFilterChange({categoryId: value})
                      }
                    }}
                  >
                    <option value=''>{intl.formatMessage({id: 'ECOMMERCE.COMMON.ALL'})}</option>
                    {categoryList?.map((service: any) => (
                      <option key={service.id} value={service.id}>
                        {intl.formatMessage({id: 'SERVICE.SERVICE.' + service.label})}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>
          </div>
          <button
            type='button'
            className='btn btn-primary'
            onClick={() => {
              history.push('/setting-references/create')
            }}
          >
            <i className='bi bi-plus-circle-fill fs-2'></i>
            {intl.formatMessage({id: 'SETTING_REFERENCE.BTN.ADD_NEW'})}
          </button>
        </div>
      </div>
    </>
  )
}

export default SettingReferenceFilter
