import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import BillingTable from './components/BillingTable'

type Props = {
  className: string
}
const BillingsPage: FC<Props> = (props) => {
  const intl = useIntl()

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'BILLING.PAGE_TITLE'})}</PageTitle>
      <div className='card mb-10'>
        {/* <div className='card-header border-0 pt-5'>
          <SettingReferenceFilter />
        </div> */}
        <div className='card-body p-10'>
          <BillingTable />
        </div>
      </div>
    </>
  )
}

export default BillingsPage
