import { call, put, select, takeLatest } from "redux-saga/effects"
import {
  login,
  socialLogin,
  logout,
  register,
  socialRegister,
  requestFail,
  requestUser,
  setToken,
  setUser,
  setShowWelcomeModal,
} from './AuthRedux'
import {
  verifyToken,
  loginRequest,
  socialLoginRequest,
  registerNewUser,
  socialRegisterRequest,
} from './AuthAPI'
import { ResponseGenerator } from "../../../shared/models/ResponseGenerator"
import { HTTP_CODE } from "../../../shared/constants"
import { AuthResponseModel } from "../models/AuthResponseModel"
import { UserModel } from "../models/UserModel"
import { toast } from "react-toastify"
import { intl } from "../../../../_metronic/i18n/i18nProvider"
export default function* saga() {
  yield takeLatest(requestUser.type, function* userRequested() {
    const accessToken: string = yield select(state => state.auth.accessToken)
    try {
      const response: ResponseGenerator = yield call(verifyToken, accessToken)
      if (response && response.status === HTTP_CODE.SUCCESS) {
        const user: UserModel = response.data;
        yield put(setUser({ user }))
      } else {
        yield put(logout())
      }
    } catch (err: any) {
      let errors = [err?.message];
      const status = err?.response?.status
      if (status === HTTP_CODE.BAD_REQUEST) {
        errors = err.response?.data?.messages;
      }
      yield put(requestFail({ errors }));
    }

  })
  yield takeLatest(login.type, function* userLogin(action: any) {
    try {
      const response: ResponseGenerator = yield call(loginRequest, action.payload.loginRequest)
      if (response && response.status === HTTP_CODE.SUCCESS) {
        const authResponse: AuthResponseModel = response.data;
        yield put(setToken({ token: authResponse.token.token }))
        yield put(setUser({ user: authResponse.userInfo }))
      }

    } catch (err: any) {
      let errors = [err?.message];
      const status = err?.response?.status
      if (status === HTTP_CODE.BAD_REQUEST) {
        errors = err.response?.data?.messages;
      }
      yield put(requestFail({ errors }));
    }
  })
  yield takeLatest(socialLogin.type, function* userLogin(action: ReturnType<typeof socialLogin>) {
    try {
      const response: ResponseGenerator = yield call(
        socialLoginRequest,
        action.payload.loginRequest
      )
      if (response && response.status === HTTP_CODE.SUCCESS) {
        const authResponse: AuthResponseModel = response.data
        yield put(setToken({ token: authResponse.token.token }))
        yield put(setUser({ user: authResponse.userInfo }))
      }
    } catch (err: any) {
      let errors = [err?.message]
      const status = err?.response?.status
      if (status === HTTP_CODE.BAD_REQUEST) {
        errors = err.response?.data?.messages
      }
      toast.error (intl.formatMessage({ id: "SERVER."+errors[0] }))
     
      yield put(requestFail({ errors }))
    }
  })
  yield takeLatest(register.type, function* userLogin(action: ReturnType<typeof register>) {
    try {
      const response: ResponseGenerator = yield call(registerNewUser, action.payload.user)
      if (response && response.status === HTTP_CODE.CREATE_NEW) {
        const authResponse: AuthResponseModel = response.data;
        yield put(setToken({ token: authResponse.token.token }))
        yield put(setUser({ user: authResponse.userInfo }))
      }
    } catch (err: any) {
      let errors = [err?.message];
      const status = err?.response?.status
      if (status === HTTP_CODE.BAD_REQUEST) {
        errors = err.response?.data?.messages;
      }
      yield put(requestFail({ errors }));
    }
  })
  yield takeLatest(
    socialRegister.type,
    function* userLogin(action: ReturnType<typeof socialRegister>) {
      try {
        const response: ResponseGenerator = yield call(
          socialRegisterRequest,
          action.payload.registerRequest
        )
        if (response && response.status === HTTP_CODE.CREATE_NEW) {
          const authResponse: AuthResponseModel = response.data
          yield put(setToken({ token: authResponse.token.token }))
          yield put(setUser({ user: authResponse.userInfo }))
        }
      } catch (err: any) {
        let errors = [err?.message]
        const status = err?.response?.status
        if (status === HTTP_CODE.BAD_REQUEST) {
          errors = err.response?.data?.messages
        }
        yield put(requestFail({ errors }))
      }
    }
  )
  yield takeLatest(setShowWelcomeModal.type, function* reloadUser(action: ReturnType<typeof setShowWelcomeModal>) {
    const accessToken: string = yield select(state => state.auth.accessToken)
    const isShow = action.payload;
    if (isShow) {
      return;
    }
    try {
      const response: ResponseGenerator = yield call(verifyToken, accessToken)
      if (response && response.status === HTTP_CODE.SUCCESS) {
        const user: UserModel = response.data;
        yield put(setUser({ user }))
      }
    } catch (err: any) {
      let errors = [err?.message];
      const status = err?.response?.status
      if (status === HTTP_CODE.BAD_REQUEST) {
        errors = err.response?.data?.messages;
      }
      yield put(requestFail({ errors }));
    }

  })
}
