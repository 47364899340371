/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {resetPassword} from '../redux/AuthAPI'
import {useIntl} from 'react-intl'
import {useLocation, useHistory} from 'react-router-dom'
import {toast} from 'react-toastify'

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/
function useQuery() {
  const {search} = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}

export function ResetPassword() {
  const [loading, setLoading] = useState(false)
  const query = useQuery()
  const token = query.get('token') as string
  const intl = useIntl()
  const history = useHistory()
  const resetPasswordSchema = Yup.object().shape({
    token: Yup.string().required(),
    newPassword: Yup.string()
      .min(8, intl.formatMessage({id: 'ResetPassword.form.PASS.MIN'}))
      .max(50, intl.formatMessage({id: 'ResetPassword.form.PASS.MAX'}))
      .required(intl.formatMessage({id: 'ResetPassword.form.PASS.REQUIRED'})),
    confirmedPassword: Yup.string()
      .min(8, intl.formatMessage({id: 'ResetPassword.form.PASS.MIN'}))
      .max(50, intl.formatMessage({id: 'ResetPassword.form.PASS.MAX'}))
      .required(intl.formatMessage({id: 'ResetPassword.form.CONFIRM_PASS.REQUIRED'}))
      .oneOf(
        [Yup.ref('newPassword'), null],
        intl.formatMessage({id: 'ResetPassword.form.PASS.NOT_MATCH'})
      ),
  })

  const formik = useFormik({
    initialValues: {token: token, newPassword: '', confirmedPassword: ''},
    validationSchema: resetPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)

      resetPassword(values.token, values.newPassword)
        .then((response) => {
          setLoading(false)
          toast.success(intl.formatMessage({id: 'ResetPassword.form.SUCCESS'}))
          history.push('/login')
        })
        .catch(
          ({
            response: {
              data: {messages},
            },
          }) => {
            setLoading(false)
            setSubmitting(false)
            setStatus(messages)
          }
        )
    },
  })

  return (
    <form
      className='form w-100 w-lg-500px '
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>{intl.formatMessage({id: 'ResetPassword.header.TITLE'})}</h1>
        <div className='text-gray-400 fw-bold fs-4'>
          {intl.formatMessage({id: 'ResetPassword.header.SUB_TITLE'})}
        </div>
      </div>
      {/* begin::Heading */}

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>
              {intl.formatMessage({id: 'ResetPassword.form.PASS'})}
            </label>
            {/* end::Label */}
          </div>
        </div>
        <input
          type='password'
          autoComplete='off'
          placeholder={intl.formatMessage({id: 'ResetPassword.form.PASS.HOLDER'})}
          {...formik.getFieldProps('newPassword')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.newPassword && formik.errors.newPassword,
            },
            {
              'is-valid': formik.touched.newPassword && !formik.errors.newPassword,
            }
          )}
        />
        {formik.touched.newPassword && formik.errors.newPassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.newPassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>
              {intl.formatMessage({id: 'ResetPassword.form.CONFIRM_PASS'})}
            </label>
            {/* end::Label */}
          </div>
        </div>
        <input
          type='password'
          autoComplete='off'
          placeholder={intl.formatMessage({id: 'ResetPassword.form.CONFIRM_PASS.HOLDER'})}
          {...formik.getFieldProps('confirmedPassword')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.confirmedPassword && formik.errors.confirmedPassword,
            },
            {
              'is-valid': formik.touched.confirmedPassword && !formik.errors.confirmedPassword,
            }
          )}
        />
        {formik.touched.confirmedPassword && formik.errors.confirmedPassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.confirmedPassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}
      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_reset_password_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && (
            <span className='indicator-label'>
              {intl.formatMessage({id: 'ResetPassword.form.RESET_BTN'})}
            </span>
          )}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              {intl.formatMessage({id: 'ResetPassword.form.WAITING'})}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </form>
  )
}
