import axios from 'axios'
import { SettingReferenceParamsModel } from '../models/SettingReferenceParamsModel'
import { SettingReferenceModel } from '../models/SettingReferenceModel'
import { SettingReferenceStatus } from '../constants'

const API_URL = process.env.REACT_APP_API_URL
export const SETTING_REFERENCE_BASE_URL = `${API_URL}/setting-reference`
export const SERVICE_BASE_URL = `${API_URL}/services`

export function getAllSettingReference(params?: SettingReferenceParamsModel) {
  return axios.get(SETTING_REFERENCE_BASE_URL, {
    params,
  })
}
export function getDetailSettingReference(settingId: string) {
  return axios.get(SETTING_REFERENCE_BASE_URL + "/" + settingId)
}
export function getAllService() {
  return axios.get(SERVICE_BASE_URL + "/all", {})
}

export function createNewSettingReferrence(settingReference: SettingReferenceModel) {

  return axios.post(SETTING_REFERENCE_BASE_URL, settingReference)
}
export function updateSettingReferrence(settingReference: SettingReferenceModel) {

  return axios.put(SETTING_REFERENCE_BASE_URL + "/" + settingReference.id, settingReference)
}
export function getAllCategoriesBelongToServices(serviceId: string) {
  return axios.get(SERVICE_BASE_URL + "/" + serviceId + "/categories", {})
}

export function getAllFeaturesBelongToServices(serviceId: string) {
  return axios.get(SERVICE_BASE_URL + "/" + serviceId + "/features", {})
}

export function uploadFile(fileContent: File) {
  let bodyFormData = new FormData()
  bodyFormData.append('file', fileContent)
  return axios.post<any>(SETTING_REFERENCE_BASE_URL + "/files", bodyFormData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

export function updateSettingStatus(serviceId: number, newStatus: SettingReferenceStatus) {
  return axios.patch(SETTING_REFERENCE_BASE_URL + "/" + serviceId + "/status", { status: newStatus })
}