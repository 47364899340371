import {FC} from 'react'
import {useLang} from './Metronici18n'
import {IntlProvider} from 'react-intl'
import {createIntl, createIntlCache} from 'react-intl'
import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/locale-data/en'
import '@formatjs/intl-relativetimeformat/locale-data/ja'

import enMessages from './messages/en.json'
import jaMessages from './messages/ja.json'

const allMessages: {[key: string]: {[key: string]: string}} = {
  en: enMessages,
  ja: jaMessages,
}

const cache = createIntlCache()
const detectLanguage = navigator.language.split(/[-_]/)[0] // Use detected language

const cachedLanguage = localStorage.getItem('i18nConfig')
let selectedLang = detectLanguage
if (cachedLanguage && cachedLanguage.length > 0) {
  try {
    selectedLang = JSON.parse(cachedLanguage).selectedLang
  } catch (error) {
    console.error('Failed to parse selectedLang from localStorage', error)
  }
}
const supportedMessages = Object.keys(allMessages)
selectedLang = supportedMessages.includes(selectedLang) ? selectedLang : 'ja'

const intl = createIntl({locale: selectedLang, messages: allMessages[selectedLang]}, cache)

const I18nProvider: FC = ({children}) => {
  const locale = useLang()
  const messages = allMessages[locale]

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  )
}

export {I18nProvider, intl}
