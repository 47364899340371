import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../../setup/redux/Store";
import { ListResponseModel } from "../../../shared/models/ListResponseModel";
import { PaginateModel } from "../../../shared/models/PaginateModel";
import { CategoryModel } from "../models/CategoryModel";
import { ServiceModel } from "../models/ServiceModel";

export interface IServiceState {
    loading: boolean,
    selectedService: ServiceModel | undefined;
    serviceList: ServiceModel[];
    paginate: PaginateModel;
    serviceCategories: CategoryModel[];
}

const initialState: IServiceState = {
    selectedService: undefined,
    serviceList: [],
    loading: false,
    paginate: {
        page: 1,
        take: Number(process.env.REACT_APP_DEFAULT_PAGE_SIZE),
        itemCount: 0,
        pageCount: 0
    },
    serviceCategories: []

}
export const serviceSlice = createSlice({
    name: "service",
    initialState,
    reducers: {
        getServiceDetail: (state, action: PayloadAction<string>) => {
            state.loading = true;
        },
        setServiceDetail: (state, action: PayloadAction<ServiceModel>) => {
            state.loading = false;
            state.selectedService = action.payload
        },
        getAll: (state) => {
            state.loading = true;
        },
        setServiceList: (state, action: PayloadAction<ListResponseModel<ServiceModel>>) => {
            state.loading = true;
            state.serviceList = action.payload.data
        },
        getAllCategories: (state) => {
            state.loading = true;
        },
        setAllCategories: (state, action: PayloadAction<CategoryModel[]>) => {
            state.loading = false;
            state.serviceCategories = action.payload
        }
    }
})
export const { getAllCategories, setAllCategories, getServiceDetail, setServiceDetail, getAll, setServiceList } = serviceSlice.actions;
export const selectCurrentService = (state: RootState) => state.service.selectedService;
export const selectServiceCategories = (state: RootState) => state.service.serviceCategories;
export const selectAllService = (state: RootState) => state.service.serviceList;
export default serviceSlice.reducer;