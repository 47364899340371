import axios from "axios"

import { ListResponseModel } from "../../../shared/models/ListResponseModel"
import { ServiceModel } from "../models/ServiceModel"
import { CategoryModel } from "../models/CategoryModel"

const API_URL = process.env.REACT_APP_API_URL
const BASE_ORDER_URL = `${API_URL}/services`

export function getServices() {
    return axios.get<ListResponseModel<ServiceModel>>(BASE_ORDER_URL)
}
export function getServicesByCategory(categoryId: number) {
    return axios.get<ListResponseModel<ServiceModel>>(BASE_ORDER_URL, { params: { categoryId } })
}
export function getServiceById(serviceId: string) {
    return axios.get<ServiceModel>(BASE_ORDER_URL + "/" + serviceId)
}
export function getServiceCategories() {
    return axios.get<CategoryModel[]>(BASE_ORDER_URL + "/categories")
}