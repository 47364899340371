import { call, put, select, takeLatest } from "redux-saga/effects";
import { filterDataChange, getAll, setError, setList } from "./BillingRedux";
import { PaginateModel } from "../../../shared/models/PaginateModel";
import { ResponseGenerator } from "../../../shared/models/ResponseGenerator";
import { getAllUserTransaction } from "./BillingApi";
import { HTTP_CODE } from "../../../shared/constants";

export default function* saga() {
    yield takeLatest([getAll.type, filterDataChange.type], function* getAllBilling(action: ReturnType<typeof getAll>) {
        try {
            const filterData: PaginateModel = yield select(state => state.billing.filter);
            const response: ResponseGenerator = yield call(getAllUserTransaction, filterData)
            if (response && response.status !== HTTP_CODE.SUCCESS) {
                throw new Error(response.data.message);//@TODO:check format
            }
            yield put(setList(response.data))
        } catch (error: any) {
            yield put(setError(error.message))
        }
    })
}