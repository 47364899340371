import {OrderModel} from '../models/OrderModel'
import {FC} from 'react'
import {Link} from 'react-router-dom'
import moment from 'moment'
import {useIntl} from 'react-intl'
import {orderStatusClassMapping} from '../utils'
import OrderActionBox from './OrderActionBox'
import {ORDER_ACTION_VIEW_MODE} from '../constants'
interface Props {
  orders: OrderModel[]
}

const OrderListView: FC<Props> = (props) => {
  const intl = useIntl()

  return (
    <div className='table-responsive'>
      {/* begin::Table */}
      <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3 text-center'>
        {/* begin::Table head */}
        <thead>
          <tr className='fw-bolder text-muted'>
            <th className='min-w-80px'>{intl.formatMessage({id: 'Order.table.ID'})}</th>
            <th className='min-w-150px'>{intl.formatMessage({id: 'Order.table.NAME'})}</th>
            <th className='min-w-100px'>{intl.formatMessage({id: 'Order.table.STATUS'})}</th>
            <th className='min-w-150px'>{intl.formatMessage({id: 'Order.table.SERVICES'})}</th>
            <th className='min-w-80px'>{intl.formatMessage({id: 'Order.table.Item'})}</th>
            <th className='min-w-100px'>{intl.formatMessage({id: 'Order.table.SETTING'})}</th>
            <th className='min-w-80px'>{intl.formatMessage({id: 'Order.table.QUALITY'})}</th>
            <th className='min-w-100px'>{intl.formatMessage({id: 'Order.table.CREATE_DATE'})}</th>
            <th className='min-w-80px'>{intl.formatMessage({id: 'Order.table.TOTAL'})}</th>
            <th className='min-w-100px'>{intl.formatMessage({id: 'Order.table.ACTIONS'})}</th>
          </tr>
        </thead>
        {/* end::Table head */}
        {/* begin::Table body */}
        <tbody>
          {props.orders &&
            props.orders?.map((order) => (
              <tr key={order.id}>
                <td>
                  <Link
                    to={'orders/' + order.id}
                    className='text-dark fw-bolder text-hover-primary fs-6'
                  >
                    {order.id}
                  </Link>
                </td>
                <td>
                  <Link
                    to={'orders/' + order.id}
                    className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                  >
                    {order.name}
                  </Link>
                </td>
                <td>
                  <span className={`badge ${orderStatusClassMapping(order.status)}`}>
                    {intl.formatMessage({id: 'ORDER.STATUS.' + order.status})}
                  </span>
                </td>
                <td>
                  <span className='text-dark fw-bold d-block mb-1 fs-6'>
                    {intl.formatMessage({id: 'SERVICE.SERVICE.' + order?.service?.name})}
                  </span>
                </td>
                <td className='text-dark fw-bold fs-6'>
                  {order.completedItems}/{order.totalItems}
                </td>
                <td className='text-dark fw-bold fs-6'>
                  {order.setting?.name}
                </td>
                <td className='text-dark fw-bold fs-6'>
                  {intl.formatMessage({id: 'Order.table.' + order.type})}
                </td>
                <td className='text-dark fw-bold fs-6'>
                  {order.createdAt && moment(order.createdAt).format(process.env.REACT_APP_DATE_TIME_FORMAT)}
                </td>
                <td className='text-dark fw-bold fs-6'>{order?.price}</td>
                <td>
                  <OrderActionBox order={order} viewMode={ORDER_ACTION_VIEW_MODE.MINIFY} />
                </td>
              </tr>
            ))}
        </tbody>
        {/* end::Table body */}
      </table>
      {/* end::Table */}
    </div>
  )
}

export default OrderListView
