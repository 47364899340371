
export const getUserFullName = (user: {
    firstName: string,
    lastName: string
}, language: string): string => {
    if (language === 'ja') {
        return `${user.lastName} ${user.firstName}`;
    }
    return `${user.firstName} ${user.lastName}`;

}