export enum CategoryStatus {
    DISABLE = 0,
    ENABLE = 1
}

export enum FeatureDataType {
    HIDDEN = 0,
    YES_NO = 1,
    RADIO_BTN = 2,
    YES_NO_RADIO_BTN = 3,
    CHECKBOX_BTN = 4,
    WATERMARK = 5
}
export enum FeaturePriceType {
    FREE = 0,
    PAID = 1,
}
export enum FeatureDataValueType {
    IMAGE = 'img',
    TEXT = 'text',
    CHECK_GROUP = 'checkbox',
}
export enum BasicFeatureID {
    WB = 1,
    BRIGHTNESS = 3,
    PERSPECTIVE = 6,
    SKY = 5,
}

export enum ServiceStatus {
    DISABLE = 0,
    ENABLE = 1
}