import {FC, useEffect, useState} from 'react'
import {OrderItemModel} from '../../orders/models/OrderItemModel'
import {useIntl} from 'react-intl'
import FeatureItem from '../../setting-reference/components/FeatureItem'
import {ReferenceFeatureModel} from '../../setting-reference/models/ReferenceFeatureModel'
import {FeatureViewMode} from '../../setting-reference/constants'
import {ItemOptionStatus, ItemStatus} from '../../orders/constants'
import {cloneDeep} from 'lodash'
import Tooltip from 'react-tooltip-lite'
import {KTSVG} from '../../../../_metronic/helpers'
import {useDispatch} from 'react-redux'
import {retryAi} from '../redux/itemRedux'
import {Spinner} from 'react-bootstrap-v5'
import {ItemOptionModel} from '../../orders/models/ItemOptionModel'
import {MAX_AI_TRY_TIME} from '../constants'
interface Props {
  item: OrderItemModel
}
const ItemSetting: FC<Props> = (props) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const loading = props.item.status !== ItemStatus.DONE
  const dataItems = props.item.itemOptions?.map((item) => item.feature)
  const [aiCountTime, setAiCountTime] = useState(MAX_AI_TRY_TIME - 1)

  const [itemSettings, setItemSettings] = useState<ReferenceFeatureModel[]>([])
  const onHandleItemSettingsChange = (featureId: number, value: string | undefined) => {
    let currentSelectedData: ReferenceFeatureModel[] = cloneDeep(itemSettings)

    let targetItem: ReferenceFeatureModel | undefined = currentSelectedData.find(
      (item: ReferenceFeatureModel) => item.featureId === featureId
    )

    if (targetItem) {
      if (!value) {
        setItemSettings(
          currentSelectedData.filter((item) => item.featureId !== targetItem?.featureId)
        )
      } else {
        targetItem.value = value
        setItemSettings(currentSelectedData)
      }
    } else {
      const selectedItem: ItemOptionModel | undefined = props.item.itemOptions?.find(
        (item) => item.featureId === featureId
      )
      if (selectedItem && value) {
        currentSelectedData.push({
          featureId: selectedItem.featureId,
          value: selectedItem.value,
          type: selectedItem.feature?.dataType,
          featureIcon: selectedItem.feature?.icon,
        } as ReferenceFeatureModel)
        setItemSettings(currentSelectedData)
      }
    }
  }
  useEffect(() => {
    const values = props.item.itemOptions?.map((item) => {
      return item.status === ItemOptionStatus.ACTIVE
        ? ({
            featureId: item.featureId,
            value: item.value,
            type: item.feature?.dataType,
            featureIcon: item.feature?.icon,
          } as ReferenceFeatureModel)
        : undefined
    }) as ReferenceFeatureModel[]
    const defaultSetting: ReferenceFeatureModel[] = values ? values.filter((item) => item) : []
    setItemSettings(defaultSetting)
    setAiCountTime(MAX_AI_TRY_TIME - props.item.aiCountTime)
  }, [props.item])
  return (
    <div className='card'>
      <div className='card-header cursor-pointer'>
        <div className='card-title w-100 d-flex justify-content-between'>
          <h3 className='fw-bolder'>{intl.formatMessage({id: 'ItemDetail.Title.ITEM_SETTING'})}</h3>
          <div className='card-toolbar'>
            <Tooltip
              content={intl.formatMessage({id: 'ORDER.ACTIONS.RETRY'}) + ' (' + aiCountTime + ')'}
              className='tooltips'
            >
              <button
                disabled={aiCountTime === 0 || itemSettings.length === 0}
                type='button'
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 p-1'
                onClick={() => {
                  if (props.item.itemOptions) {
                    const newSettings = props.item.itemOptions?.map((item) => {
                      const setting = cloneDeep(item)
                      const newSetting = itemSettings.find((i) => i.featureId === setting.featureId)
                      if (newSetting) {
                        setting.value = newSetting.value
                        setting.status = ItemOptionStatus.ACTIVE
                      } else {
                        setting.status = ItemOptionStatus.INACTIVE
                      }
                      return setting
                    })
                    dispatch(retryAi(newSettings))
                    setAiCountTime(aiCountTime - 1)
                  }
                }}
              >
                {loading ? (
                  <Spinner size='sm' animation='border' />
                ) : (
                  <KTSVG
                    path={'/media/icons/duotune/arrows/arr029.svg'}
                    className='svg-icon svg-icon-2x svg-icon-warning'
                  />
                )}
              </button>
            </Tooltip>
          </div>
        </div>
      </div>

      <div className='card-body p-3 form-check form-check-solid form-switch'>
        {dataItems && itemSettings.length > 0 && dataItems.length > 0 && (
          <FeatureItem
            readonly={loading}
            onChange={(id: number, value: string | undefined, type: any) =>
              onHandleItemSettingsChange(id, value)
            }
            values={itemSettings}
            dataItems={dataItems}
            viewMode={FeatureViewMode.MINIMIZE}
          />
        )}
      </div>
    </div>
  )
}
export default ItemSetting
