/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Link} from 'react-router-dom'
import ReactBeforeSliderComponent from 'react-before-after-slider-component'
import {useIntl} from 'react-intl'
import {ServiceModel} from '../../../modules/services/models/ServiceModel'
import {ServiceStatus} from '../../../modules/services/constants'
import 'react-before-after-slider-component/dist/build.css'
import './serviceBox.scss'
import {Col, Row} from 'react-bootstrap-v5'
import Tooltip from 'react-tooltip-lite'

interface Props {
  service: ServiceModel
  categoryName: string
  cateColor: string
}
const SERVICE_STATUS_COMING = 2
const ServiceBox: React.FC<Props> = (prop) => {
  const intl = useIntl()
  const service = prop.service
  const FIRST_IMAGE = {
    imageUrl: service.imgAfter,
  }
  const SECOND_IMAGE = {
    imageUrl: service.imgBefore,
  }

  return (
    <div
      className='card card-flush shadow'
      style={{
        position: 'relative',
      }}
    >
      {service && service.status >= SERVICE_STATUS_COMING && (
        <span className='badge badge-warning service-badge'>
          {intl.formatMessage({id: 'SERVICE.STATUS.' + service.status})}
        </span>
      )}
      <div className='card-body p-2'>
        <Row className='w-100'>
          <Col xs={5} className='p-1'>
            <ReactBeforeSliderComponent
              className='ms-1 service-img-compare'
              firstImage={FIRST_IMAGE}
              secondImage={SECOND_IMAGE}
            />
          </Col>
          <Col xs={7} className='p-3'>
            <h3 className='card-title d-flex fs-2 mb-2'>
              <Link
                to={'./services/' + service.id}
                className='text-dark'
              >
                {intl.formatMessage({id: 'SERVICE.SERVICE.' + service.name})}
              </Link>
              <Tooltip
                content={
                  <div style={{maxWidth: '400px'}}>
                    {intl.formatMessage({id: 'SERVICE.SERVICE.' + service.description})}
                  </div>
                }
                className='tooltips'
              >
                <i
                  className={'bi text-primary  ms-2 fs-3 font-weight-bold  bi bi-info-circle-fill'}
                ></i>
              </Tooltip>
            </h3>
            <span className={'mb-4 p-2 fs-6 badge badge-' + prop.cateColor}>
              {prop.categoryName}
            </span>
            <div className='mt-4'>
              <span className='fs-5 fw-bold text-gray-400 me-1 align-self-start'>
                {intl.formatMessage({id: 'SERVICE.From.Text'})}
              </span>
              <span className='fs-1 fw-bolder text-dark ms-2 lh-1 ls-n2'>
                {service.price}
                <span className='ms-2 fs-5 text-gray-700'>
                  {intl.formatMessage({id: 'SERVICE.SERVICE.CREDIT'})}
                </span>
              </span>
            </div>
            <Link
              to={'./orders/create?serviceId=' + service.id}
              id={'order-btn-'+service.id}
              className={
                'btn btn-primary p-2 mt-6 me-2  float-end ' +
                (service.status === ServiceStatus.ENABLE ? '' : 'disabled')
              }
              style={{
                position: 'absolute',
                bottom: 10,
                right: 5,
              }}
            >
              {intl.formatMessage({id: 'SERVICE.Order.Button'})}
            </Link>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export {ServiceBox}
