import { toast } from 'react-toastify'
import { logout } from '../../app/modules/auth'
import { intl } from '../../_metronic/i18n/i18nProvider'
const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || 'i18nConfig'
export default function setupAxios(axios: any, store: any) {

  axios.defaults.headers.Accept = 'application/json'
  axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
  axios.interceptors.request.use((config: any) => {
    const {
      auth: { accessToken },
    } = store.getState()
    const ls = JSON.parse(localStorage.getItem(I18N_CONFIG_KEY) ?? '{"selectedLang":"en_US"}')
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`
    }
    config.headers.common['Accept-Language'] = ls?.selectedLang === 'ja' ? 'ja_JP' : 'en_US'

    return config
  })
  axios.interceptors.response.use(
    (response: any) => response,
    (error: any) => {
      // whatever you want to do with the error
      if (!error.response) {
        toast.error(intl.formatMessage({ id: 'SERVER.CONNECT_ERROR' }));
      } else {
        const status = error.response.status
        switch (status) {
          // case 404:
          //   window.location.href = process.env.REACT_APP_LINK + 'error/404'
          //   break
          case 401:
            store.dispatch(logout())

            // window.location.href = process.env.REACT_APP_LINK + 'error/401'
            break
          // case 403:
          //   window.location.href = process.env.REACT_APP_LINK + 'error/403'
          //   break
          // case 500:
          //   window.location.href = process.env.REACT_APP_LINK + 'error/500'
          //   break
          default:
            throw error
        }
      }

    }
  )
}
