/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState, useRef, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {RouteComponentProps, useParams} from 'react-router-dom'
import ReactBeforeSliderComponent from 'react-before-after-slider-component'
import 'react-before-after-slider-component/dist/build.css'

import './ServiceDetail.scss'
import {ServiceModel} from '../../modules/services/models/ServiceModel'
import {getServiceById} from '../../modules/services/redux/serviceAPI'
import {FeaturePriceType} from '../../modules/services/constants'
interface RouteParams {
  id: string
}
interface Props extends RouteComponentProps<RouteParams> {}

const ServiceDetailWrapper: FC<Props> = (props) => {
  const intl = useIntl()
  const didRequest = useRef(false)
  const id = props.match.params.id
  const [service, setService] = useState<ServiceModel>()

  useEffect(() => {
    const getService = async () => {
      try {
        if (!didRequest.current) {
          const {data: service} = await getServiceById(id)

          setService(service)
        }
      } catch (error) {
        console.error(error)
      }

      return () => (didRequest.current = true)
    }

    getService()
  }, [])
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'SERVICE.DETAIL'})}</PageTitle>
      <div className='card mb-10'>
        <div className='card-body p-10'>
          <div className='d-flex align-items-center'>
            <div className='col-md-3'>
              <ReactBeforeSliderComponent
                firstImage={{
                  imageUrl: service?.imgBefore as string,
                }}
                secondImage={{
                  imageUrl: service?.imgAfter as string,
                }}
              />
            </div>
            <div className='col-md-9 mx-5'>
              <h3 className='fs-2 text-dark mb-5'>
                {intl.formatMessage({id: 'SERVICE.SERVICE.' + service?.name})}
              </h3>
              <div className='fs-6 text-muted fw-bold'>
                {intl.formatMessage({id: 'SERVICE.SERVICE.' + service?.description})}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='card'>
        <div className='card-body p-10'>
          <div className='text-center mb-12'>
            <h3 className='fs-1 text-dark mb-5'>
              {intl.formatMessage({id: 'SERVICE.SERVICE.' + service?.subTitle})}
            </h3>
            <div className='fs-5 text-muted fw-bold'>
              {intl.formatMessage({id: 'SERVICE.SERVICE.' + service?.subDescription})}
            </div>
          </div>
          <div className='separator my-10'></div>
          <div className='row mx-5'>
            {service?.features
              .filter((feature) => feature.priceType !== FeaturePriceType.PAID)
              .map((feature, index) => {
                if (index % 2 === 0) {
                  return (
                    <div className='d-flex align-items-center my-5'>
                      <div className='col-md-5'>
                        <ReactBeforeSliderComponent
                          className='feature-img-compare'
                          firstImage={{
                            imageUrl: feature?.imgBefore as string,
                          }}
                          secondImage={{
                            imageUrl: feature?.imgAfter as string,
                          }}
                        />
                      </div>
                      <div className='col-md-7 mx-5'>
                        <h3 className='fs-2 text-dark mb-5'>
                          {intl.formatMessage({id: 'SERVICE.FEATURE.' + feature.name})}
                        </h3>
                        <div className='fs-6 text-muted fw-bold'>
                          {intl.formatMessage({id: 'SERVICE.FEATURE.' + feature.description})}
                        </div>
                      </div>
                    </div>
                  )
                }
                return (
                  <div className='d-flex align-items-center my-5'>
                    <div className='col-md-7 mx-5'>
                      <h3 className='fs-2 text-dark mb-5'>
                        {intl.formatMessage({id: 'SERVICE.FEATURE.' + feature.name})}
                      </h3>
                      <div className='fs-6 text-muted fw-bold'>
                        {intl.formatMessage({id: 'SERVICE.FEATURE.' + feature.description})}
                      </div>
                    </div>
                    <div className='col-md-5'>
                      <ReactBeforeSliderComponent
                        className='feature-img-compare'
                        firstImage={{
                          imageUrl: feature?.imgBefore as string,
                        }}
                        secondImage={{
                          imageUrl: feature?.imgAfter as string,
                        }}
                      />
                    </div>
                  </div>
                )
              })}
          </div>
          <div className='separator my-10'></div>
          <div className='row mx-5'>
            <h2 className='text-center fs-1 text-dark mb-10'>
              {intl.formatMessage({id: 'SERVICE.FEATURE.PAID'})}
            </h2>
            {service?.features
              .filter((feature) => feature.priceType === FeaturePriceType.PAID)
              .map((feature, index) => {
                if (index % 2 === 0) {
                  return (
                    <div className='d-flex align-items-center'>
                      <div className='col-md-5'>
                        <ReactBeforeSliderComponent
                          className='feature-img-compare'
                          firstImage={{
                            imageUrl: feature?.imgBefore as string,
                          }}
                          secondImage={{
                            imageUrl: feature?.imgAfter as string,
                          }}
                        />
                      </div>
                      <div className='col-md-7 mx-5'>
                        <h3 className='fs-2 text-dark mb-5'>
                          {intl.formatMessage({id: 'SERVICE.FEATURE.' + feature?.name})}
                        </h3>
                        <div className='fs-6 text-muted fw-bold'>
                          {intl.formatMessage({id: 'SERVICE.FEATURE.' + feature?.description})}
                        </div>
                        <button type='button' className='btn btn-primary float-start '>
                          {intl.formatMessage({id: 'SERVICE.FEATURE.' + feature?.priceDescription})}
                        </button>
                      </div>
                    </div>
                  )
                }
                return (
                  <div className='d-flex align-items-center'>
                    <div className='col-md-7 mx-5'>
                      <h3 className='fs-2 text-dark mb-5'>
          
                        {intl.formatMessage({id: 'SERVICE.FEATURE.' + feature?.name})}
                      </h3>
                      <div className='fs-6 text-muted fw-bold'>
                        {intl.formatMessage({id: 'SERVICE.FEATURE.' + feature?.description})}
                      </div>
                      <button type='button' className='btn btn-primary float-start '>
                        {intl.formatMessage({id: 'SERVICE.FEATURE.' + feature?.priceDescription})}
                      </button>
                    </div>
                    <div className='col-md-5'>
                      <ReactBeforeSliderComponent
                        className='feature-img-compare'
                        firstImage={{
                          imageUrl: feature?.imgBefore as string,
                        }}
                        secondImage={{
                          imageUrl: feature?.imgAfter as string,
                        }}
                      />
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    </>
  )
}

export {ServiceDetailWrapper}
