import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { SubscriptionPlanModel } from "../models/subscriptionPlanModel";
import { toast } from "react-toastify";
import { RootState } from "../../../../setup/redux/Store";
import { CurrencyCode } from "../pricing.constant";
import { PAYMENT_METHOD } from "../../../shared/constants";
import { intl } from "../../../../_metronic/i18n/i18nProvider";

export interface IPricingState {
    loading: boolean
    subscriptionPlans: SubscriptionPlanModel[]
    selectedSubscription: SubscriptionPlanModel | undefined
    paymentModalLink: { link: string, currency: CurrencyCode } | undefined
}
const initialState: IPricingState = {
    loading: false,
    subscriptionPlans: [],
    selectedSubscription: undefined,
    paymentModalLink: undefined
}

export const pricingSlice = createSlice({
    name: "pricing",
    initialState,
    reducers: {
        setPaymentModalLink: (state, action: PayloadAction<{ link: string, currency: CurrencyCode } | undefined>) => {
            state.paymentModalLink = action.payload;
        },
        getAllSubscription: (state, action: PayloadAction<string | undefined>) => {
            state.loading = true;
        },
        setSubscriptions: (state, action: PayloadAction<SubscriptionPlanModel[]>) => {
            state.loading = false;
            state.subscriptionPlans = action.payload
        },
        setSelectedSubscription: (state, action: PayloadAction<SubscriptionPlanModel>) => {
            state.selectedSubscription = action.payload
        },
        buySubscription: (state, action: PayloadAction<{ currency: CurrencyCode, paymentMethod: PAYMENT_METHOD }>) => {
            state.loading = true;
        },
        customPlanRequest: (state, action: PayloadAction<{ message: string }>) => {
            state.loading = true;
        },
        setActionResult: (state, action: PayloadAction<{ isSuccess: boolean, message: string | undefined }>) => {
            state.loading = false;
            if (action.payload.message) {
                if (action.payload.isSuccess) {
                    toast.success(intl.formatMessage({ id: "SERVER." + action.payload.message }))
                } else {
                    toast.error(intl.formatMessage({ id: "SERVER." + action.payload.message }))
                }
            }

        }
    }
})
export const {
    getAllSubscription,
    setSubscriptions,
    setSelectedSubscription,
    setActionResult,
    buySubscription,
    setPaymentModalLink,
    customPlanRequest
} = pricingSlice.actions
export const selectPricingLoading = (state: RootState) => state.pricing.loading;
export const selectSubscriptionPlan = (state: RootState) => state.pricing.subscriptionPlans;
export const selectSelecteesSubscription = (state: RootState) => state.pricing.selectedSubscription;
export const selectPaymentModalLink = (state: RootState) => state.pricing.paymentModalLink;
export default pricingSlice.reducer;