export enum Language {
    ENGLISH = 'en',
    JAPANESES = 'ja'
}
export enum NOTIFICATION_TYPES {
    NOTHING = 1,
    OPEN_LINK = 2,
    WELCOME = 3,
}
export const YEN_RATE = 125
export enum HTTP_CODE {
    BAD_REQUEST = 400,
    SUCCESS = 200,
    CREATE_NEW = 201,
    INTERNAL_SERVER_ERROR = 500
}

export enum USER_ROLE {
    NORMAL_USER = "NORMAL_USER"
}

export enum USER_STATUS {
    ACTIVE = "ACTIVE"
}

export const ALL_KEY = 0

export const MAX_INDIVIDUAL_UPLOAD_ITEMS = 10
export const MAX_SUBSCRIPTION_UPLOAD_ITEMS = 50
export const MAX_ENTERPRISE_UPLOAD_ITEMS = 100
export const MAX_INDIVIDUAL_FILE_SIZE = 10485760//10Mb
export const MAX_ENTERPRISE_FILE_SIZE = 10485760 * 2//20Mb
export enum PAYMENT_METHOD {
    VISA = "VISA",
    BANK = "BANK",
    CREDIT = "CREDIT"
}
export const APP_TERM_AND_CONDITION_LINK = process.env.REACT_APP_WEBSITE+"/term-of-service"