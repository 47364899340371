/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useLayout} from '../core'
import {useIntl} from 'react-intl'

const Footer: FC = () => {
  const {classes} = useLayout()
  const intl = useIntl()
  return (
    <div className='footer py-4 d-flex flex-lg-column' id='kt_footer'>
      {/* begin::Container */}
      <div
        className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
      >
        {/* begin::Copyright */}
        <div className='text-dark order-2 order-md-1'>
          <span className='fw-bold me-2'>Copyright {new Date().getFullYear()} &copy;</span>
          <a
            target='_blank'
            href={process.env.REACT_APP_WEBSITE}
            className='text-gray-800 text-hover-primary'
            rel='noreferrer'
          >
            A-LEADS HOLDINGS PTE.LTD.
          </a>
          All Rights Reserved.
        </div>
        <div className='text-dark order-2 order-md-1 text-center'>
          <span className='fw-bold me-1 fs-7'>
            {intl.formatMessage({id: 'AuthPage.VERSION'}, {version: process.env.REACT_APP_VERSION})}
          </span>
        </div>
        {/* end::Copyright */}

        {/* begin::Nav */}
        {/* <ul className='menu menu-gray-600 menu-hover-primary fw-bold order-1'>
          <li className='menu-item'>
            <a href='#' className='menu-link ps-0 pe-2'>
              About
            </a>
          </li>
          <li className='menu-item'>
            <a href='#' className='menu-link pe-0 pe-2'>
              Contact
            </a>
          </li>
          <li className='menu-item'>
            <a href='#' className='menu-link pe-0'>
              Purchase
            </a>
          </li>
        </ul> */}
        {/* end::Nav */}
      </div>
      {/* end::Container */}
    </div>
  )
}

export {Footer}
