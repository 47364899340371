import axios from 'axios'
import qs from 'query-string'
import { OrderItemModel } from '../models/OrderItemModel'
import { OrderModel } from '../models/OrderModel'
import { OrderListFilterModel } from '../models/OrderListFilterModel'
import { OrderStatus } from '../constants'
import { OrderRequestModel } from '../models/OrderRequestModel'
import { PaginateModel } from '../../../shared/models/PaginateModel'
import { OrderDownloadRequestModel } from '../models/orderDownloadRequestModel'
const API_URL = process.env.REACT_APP_API_URL

const BASE_ORDER_URL = `${API_URL}/orders`
/**
 * 
 * @param params 
 * @returns 
 */
export function getOrders(params: OrderListFilterModel) {
  return axios.get(BASE_ORDER_URL, {
    params,
  })
}
/**
 * 
 * @returns 
 */
export function getAllOrderStatus() {
  return axios.get(BASE_ORDER_URL + '/status')
}
/**
 * 
 * @returns 
 */
export function getOrderPrice(orderId: number, selectedItems: number[]) {
  return axios.get(BASE_ORDER_URL + '/' + orderId + '/price?items=' + selectedItems.join(','))
}
/**
 * 
 * @param status 
 * @returns 
 */
export function getOrdersByStatus(status: OrderStatus) {
  return axios.get(BASE_ORDER_URL + '?status=' + status.toString())
}
/**
 * 
 * @param id 
 * @returns 
 */
export function downloadCompletedOrder(params: OrderDownloadRequestModel) {
  return axios.post<any>(BASE_ORDER_URL + "/" + params.orderId + '/download', params)
}
/**
 * 
 * @param id 
 * @returns 
 */
export function retryOrderAIProcessing(id: number) {
  return axios.put<any>(BASE_ORDER_URL + "/" + id + '/ai-retry', {})
}
/**
 * 
 * @param newOrder 
 * @returns 
 */
export function createNewOrder(newOrder: OrderRequestModel) {
  return axios.post<any>(BASE_ORDER_URL, newOrder)
}
/**
 * 
 * @param id 
 * @returns 
 */
export function getDetailOrder(id: string) {
  return axios.get<any>(BASE_ORDER_URL + "/" + id)
}
/**
 * 
 * @param id 
 * @returns 
 */
export function getDetailOrderItems(id: string, params: PaginateModel) {
  return axios.get<any>(BASE_ORDER_URL + "/" + id + "/items", {
    params
  })
}
/**
 * 
 * @param params 
 * @returns 
 */
export function getAllProcessingPhoto() {
  return axios.get(API_URL + '/ai-processor/processing');
}
export const UPLOAD_FILE_URL = `${API_URL}/orders/files`
export const CREATE_ORDER_URL = `${API_URL}/orders`
export const ORDER_DETAIL_URL = `${API_URL}/orders/`
export const UPDATE_ORDER_ITEMS_URL = `${API_URL}/orders/`
export const GET_ORDER_STATUS_URL = `${API_URL}/orders/status`
export const INIT_ORDER_PAYMENT_URL = `${API_URL}/orders/`
export const CONFIRM_PAYMENT_URL = `${API_URL}/orders/`
export const GET_ORDER_URL = `${API_URL}/orders`

export function uploadFile(fileContent: File, serviceId: number) {
  let bodyFormData = new FormData()
  bodyFormData.append('file', fileContent)
  bodyFormData.append('serviceId', serviceId.toString())
  return axios.post<any>(UPLOAD_FILE_URL, bodyFormData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}
export function payOrder(orderId: number) {
  return axios.post<any>(INIT_ORDER_PAYMENT_URL + orderId + '/payment')
}

export function confirmOrder(orderId: number) {
  return axios.put<any>(CONFIRM_PAYMENT_URL + orderId + '/confirm')
}

export function updateOrderItems(id: string, item: OrderItemModel, applyAll: boolean) {
  return axios.put<any>(UPDATE_ORDER_ITEMS_URL + id + '/items', {
    id: item.id,
    note: item.note,
    options: item.itemOptions,
    applyAll: applyAll,
  })
}
