import {useDispatch} from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useHistory} from 'react-router-dom'
import {useFormik} from 'formik'
import {LoginSocialGoogle, IResolveParams} from 'reactjs-social-login'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {LoginRequestModel} from '../models/LoginRequestModel'
import {useAppSelector} from '../../../../setup/redux/hooks'
import {login, socialLogin, selectAccessToken} from '../redux/AuthRedux'
const initialValues: LoginRequestModel = {
  email: '',
  password: '',
}

export function Login() {
  const accessToken: string | undefined = useAppSelector(selectAccessToken)
  const history = useHistory()
  const dispatch = useDispatch()
  const intl = useIntl()
  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({id: 'Login.email.FORMAT'}))
      .min(1, intl.formatMessage({id: 'Login.email.MIN'}))
      .max(50, intl.formatMessage({id: 'Login.email.MAX'}))
      .required(intl.formatMessage({id: 'Login.email.REQUIRED'})),
    password: Yup.string()
      .min(8, intl.formatMessage({id: 'Login.password.MIN'}))
      .max(50, intl.formatMessage({id: 'Login.password.MAX'}))
      .required(intl.formatMessage({id: 'Login.password.REQUIRED'})),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      await dispatch(login({loginRequest: values}))
      setSubmitting(false)
      if (accessToken) {
        history.push('/')
      }
    },
  })

  const handleSocialLogin = ({provider, data}: IResolveParams) => {
    const accessToken = data?.access_token || data?.accessToken
    dispatch(socialLogin({loginRequest: {accessToken: accessToken, provider: provider}}))
    if (accessToken) {
      history.push('/')
    }
  }

  const handleError = (error: any) => {
    console.log(error)
  }

  return (
    <form
      className='form w-100 w-lg-500px '
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>
          {intl.formatMessage({id: 'Login.header.SIGNING'})} AI-prop
        </h1>
        <div className='text-gray-400 fw-bold fs-4'>
          {intl.formatMessage({id: 'Login.header.NEW_HERE'})}
          <Link to='/auth/registration' className='ms-1 link-primary fw-bolder'>
            {intl.formatMessage({id: 'Login.header.CREATE_ACC'})}
          </Link>
        </div>
      </div>
      {/* begin::Heading */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>
          {intl.formatMessage({id: 'Login.form.EMAIL'})}
        </label>
        <input
          placeholder={intl.formatMessage({id: 'Login.form.EMAIL_HOLDER'})}
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>
              {intl.formatMessage({id: 'Login.form.PASS'})}
            </label>
            {/* end::Label */}
            {/* begin::Link */}
            <Link
              to='/auth/forgot-password'
              className='link-primary fs-6 fw-bolder'
              style={{marginLeft: '5px'}}
            >
              {intl.formatMessage({id: 'Login.header.FORGOT_PASS'})}
            </Link>
            {/* end::Link */}
          </div>
        </div>
        <input
          type='password'
          autoComplete='off'
          placeholder={intl.formatMessage({id: 'Login.form.PASS_HOLDER'})}
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!formik.isSubmitting && (
            <span className='indicator-label'>
              {intl.formatMessage({id: 'Login.form.CONTINUE_BTN'})}
            </span>
          )}
          {formik.isSubmitting && (
            <span className='indicator-progress' style={{display: 'block'}}>
              {intl.formatMessage({id: 'Login.form.WAITING'})}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>

        {/* begin::Separator */}
        <div className='text-center text-muted text-uppercase fw-bolder mb-5'>
          {intl.formatMessage({id: 'Login.body.OR'})}
        </div>
        {/* end::Separator */}

        {/* begin::Google link */}
        <LoginSocialGoogle
          client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID ?? ''}
          scope={process.env.REACT_APP_GOOGLE_OAUTH2_SCOPES ?? ''}
          onResolve={handleSocialLogin}
          onReject={handleError}
          isOnlyGetToken
        >
          <button type='button' className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
              className='h-20px me-3'
            />
            {intl.formatMessage({id: 'Login.form.CONTINUE_GG'})}
          </button>
        </LoginSocialGoogle>
        {/* end::Google link */}
      </div>
      {/* end::Action */}
    </form>
  )
}
