import {MenuItem} from './MenuItem'
import {useIntl} from 'react-intl'

export function MenuInner() {
  const intl = useIntl()
  return (
    <>
      <MenuItem title={intl.formatMessage({id: 'MENU.HOME'})} to='/services' />
      <div
        className='menu-item menu-down-accordion menu-dropdown'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-start'
      >
        <span className='menu-link py-3'>
          <span className='menu-title'>{intl.formatMessage({id: 'MENU.ORDERS'})}</span>
        </span>
        <div className='menu-sub menu-sub-accordion menu-sub-lg-dropdown menu-active-bg py-4 w-100 w-lg-225px'>
          <MenuItem to='/orders' title={intl.formatMessage({id: 'MENU.ORDERS'})} />
          <MenuItem to='/orders/create?serviceId=1' title={intl.formatMessage({id: 'MENU.ORDERS.NEW'})} />
        </div>
      </div>

      <MenuItem title={intl.formatMessage({id: 'MENU.PRICING'})} to='/pricing' />
      {/* <MenuItem title={intl.formatMessage({id: 'MENU.INQUIRY'})} to='/inquiries' />*/}
      <MenuItem title={intl.formatMessage({id: 'MENU.BILLING'})} to='/billings' />
    </>
  )
}
