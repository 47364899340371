import {FC, useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import Tooltip from 'react-tooltip-lite'
import {useDispatch} from 'react-redux'
import {KTSVG} from '../../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import {SettingReferenceModel} from '../models/SettingReferenceModel'
import {SettingReferenceStatus} from '../constants'
import Paginator from '../../../components/Paginator'
import {ReferenceFeatureModel} from '../models/ReferenceFeatureModel'
import {
  filterDataChange,
  getAll,
  selectSettingReferenceFilter,
  selectSettingReferenceList,
  updateServiceStatus,
} from '../redux/SettingReferenceRedux'
import {useAppSelector} from '../../../../setup/redux/hooks'
import {SettingReferenceParamsModel} from '../models/SettingReferenceParamsModel'
import ConfirmModal from '../../../components/ConfirmModal'
type Props = {
  className?: string
}
const SettingReferenceTable: FC<Props> = (props) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const didRequest = useRef(false)
  const data: SettingReferenceModel[] = useAppSelector(selectSettingReferenceList)
  const filter: SettingReferenceParamsModel = useAppSelector(selectSettingReferenceFilter)
  const [currentSelection, setCurrentSelection] = useState<SettingReferenceModel | undefined>(
    undefined
  )
  const renderFeaturesIcons = (features: ReferenceFeatureModel[]) => {
    return (
      <div className='d-flex mt-3'>
        {features.map((feature, index) => {
          return (
            <div key={index} className='d-flex'>
              {index !== 0 && <i className='bi bi-arrow-right me-1'></i>}
              <Tooltip
                content={intl.formatMessage({id: 'SERVICE.FEATURE.' + feature.featureName})}
                className='tooltips'
              >
                <i
                  className={'bi text-dark  me-1 fs-3 font-weight-bold  bi-' + feature.featureIcon}
                ></i>
              </Tooltip>
            </div>
          )
        })}
      </div>
    )
  }
  const renderStatus = (status: SettingReferenceStatus) => {
    switch (status) {
      case SettingReferenceStatus.ACTIVE:
        return (
          <span className='badge badge-light-success fs-8 fw-bolder'>
            {intl.formatMessage({id: 'SETTING_REFERENCE.STATUS.ACTIVE'})}
          </span>
        )
      default:
        return (
          <span className='badge badge-light-danger fs-8 fw-bolder'>
            {intl.formatMessage({id: 'SETTING_REFERENCE.STATUS.DEACTIVATE'})}
          </span>
        )
    }
  }
  const renderItemActions = (item: SettingReferenceModel) => {
    let actions: any = []
    if (item.userId) {
      actions = [
        <Tooltip
          key={'item_action_1'}
          content={intl.formatMessage({id: 'SETTING_REFERENCE.ACTION.Edit'})}
          className='tooltips'
        >
          <Link
            to={'/setting-references/' + item.id}
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          >
            <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
          </Link>
        </Tooltip>,
      ]
      if (!item.isDefault) {
        actions.push(
          <Tooltip
            key={'item_action_2'}
            content={
              item.status === SettingReferenceStatus.ACTIVE
                ? intl.formatMessage({id: 'SETTING_REFERENCE.STATUS.DEACTIVATE'})
                : intl.formatMessage({id: 'SETTING_REFERENCE.STATUS.ACTIVE'})
            }
          >
            <button
              className='btn btn-icon btn-bg-light btn-active-danger btn-sm'
              onClick={() => setEnableDisableSetting(item)}
            >
              {item.status === SettingReferenceStatus.ACTIVE ? (
                <KTSVG path='/media/icons/duotune/general/gen034.svg' className='svg-icon-3' />
              ) : (
                <KTSVG path='/media/icons/duotune/general/gen037.svg' className='svg-icon-3' />
              )}
            </button>
          </Tooltip>
        )
      }
    }
    return (
      <div className='d-flex justify-content-end flex-shrink-0'>
        {actions.map((action: any) => action)}
      </div>
    )
  }
  const renderReferenceItem = (item: SettingReferenceModel) => {
    return (
      <tr key={item.id}>
        <td>
          <div className='d-flex align-items-center'>
            <div className='d-flex justify-content-start flex-column'>
              {item.userId ? (
                <Link
                  to={'/setting-references/' + item.id}
                  className='text-dark fw-bolder text-hover-primary fs-6'
                >
                  {item.name}
                </Link>
              ) : (
                <span className='text-dark fw-bolder text-hover-primary fs-6'>
                  {intl.formatMessage({id: item.name})}
                </span>
              )}
              <span className='text-muted fw-bold text-muted d-block fs-7'>
                {renderFeaturesIcons(item.settingFeatures)}
              </span>
            </div>
          </div>
        </td>
        <td>
          <a
            href={'/services/' + item.serviceId}
            className='text-dark fw-bolder text-hover-primary d-block fs-6'
          >
            {intl.formatMessage({id: 'SERVICE.SERVICE.' + item.serviceName})}
          </a>
          <span className='text-muted fw-bold text-muted d-block fs-7'>
            {intl.formatMessage({id: 'SETTING_REFERENCE.CATEGORY.' + item.categoryName})}
          </span>
        </td>
        <td className='text-end'>
          <div className='d-flex flex-column w-100 me-2'>
            <div className='d-flex mb-2'>
              {renderStatus(item.status)}
              {item.isDefault && (
                <span className='badge badge-light-warning ms-3 fs-8 fw-bolder'>
                  {intl.formatMessage({id: 'SETTING_REFERENCE.TABLE.DEFAULT'})}
                </span>
              )}
            </div>
          </div>
        </td>
        <td className='text-start text-gray-800 fw-bolder'>
          {item.totalPrice} {intl.formatMessage({id: 'SYSTEM.CURRENCY'})}
        </td>
        <td>{renderItemActions(item)}</td>
      </tr>
    )
  }
  const handleEnableDisableSetting = () => {
    if (currentSelection) {
      const newStatus =
        currentSelection.status === SettingReferenceStatus.ACTIVE
          ? SettingReferenceStatus.DISABLE
          : SettingReferenceStatus.ACTIVE

      dispatch(updateServiceStatus({serviceId: currentSelection.id, newStatus}))
      setCurrentSelection(undefined)
    }
  }
  const setEnableDisableSetting = (item: SettingReferenceModel) => {
    setCurrentSelection(item)
  }
  const handlePageChanged = (targetPage: number) => {
    dispatch(filterDataChange({...filter, page: targetPage}))
  }
  useEffect(() => {
    if (!didRequest.current) {
      dispatch(getAll(undefined))
      didRequest.current = true
    }
  }, [])
  return (
    <>
      <div className='table-responsive'>
        {/* begin::Table */}
        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
          {/* begin::Table head */}
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              <th className='min-w-150px'>
                {intl.formatMessage({id: 'SETTING_REFERENCE.TABLE.NAME'})}
              </th>
              <th className='min-w-140px'>
                {intl.formatMessage({id: 'SETTING_REFERENCE.TABLE.SERVICE'})}
              </th>
              <th className='min-w-120px'>
                {intl.formatMessage({id: 'SETTING_REFERENCE.TABLE.STATUS'})}
              </th>
              <th className='min-w-120px'>
                {intl.formatMessage({id: 'SETTING_REFERENCE.TABLE.PRICE'})}
              </th>
              <th className='min-w-100px text-end'>
                {intl.formatMessage({id: 'SETTING_REFERENCE.TABLE.ACTIONS'})}
              </th>
            </tr>
          </thead>
          {/* end::Table head */}
          {/* begin::Table body */}
          <tbody>{data?.map((item: any) => renderReferenceItem(item))}</tbody>
          {/* end::Table body */}
        </table>
        {/* end::Table */}
      </div>
      <div className='d-flex justify-content-end pt-10'>
        <Paginator
          currentPage={filter.page}
          totalPages={filter.pageCount || 1}
          onPageChange={handlePageChanged}
        />
      </div>
      <ConfirmModal
        onClose={() => setCurrentSelection(undefined)}
        isShow={currentSelection !== undefined}
        onConfirm={() => handleEnableDisableSetting()}
        message={
          currentSelection?.status === SettingReferenceStatus.ACTIVE
            ? intl.formatMessage({id: 'SETTING_REFERENCE.STATUS.DEACTIVATE.CONFIRM'})
            : intl.formatMessage({id: 'SETTING_REFERENCE.STATUS.ACTIVE.CONFIRM'})
        }
      />
    </>
  )
}

export default SettingReferenceTable
