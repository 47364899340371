import { call, put, select, takeLatest } from "redux-saga/effects";
import { buySubscription, customPlanRequest, getAllSubscription, setActionResult, setPaymentModalLink, setSelectedSubscription, setSubscriptions } from "./pricingRedux";
import { getUserSubscriptionPlan, sendContactRequest, updateUserSubscription } from "./pricingApi";
import { ResponseGenerator } from "../../../shared/models/ResponseGenerator";
import { HTTP_CODE, PAYMENT_METHOD } from "../../../shared/constants";
import { SubscriptionPlanModel } from "../models/subscriptionPlanModel";
import { UserModel } from "../../../modules/auth/models/UserModel";
import { CurrencyCode, NONE_CHECKOUT_PLAN } from "../pricing.constant";
import { setUser } from "../../../modules/auth";
import { clone } from "lodash";
import { intl } from "../../../../_metronic/i18n/i18nProvider";
import { getUserFullName } from "../../../shared/utils";

export default function* saga() {
    yield takeLatest(getAllSubscription.type, function* getAll(action: ReturnType<typeof getAllSubscription>) {
        try {
            const user: UserModel = yield select(state => state.auth.user);
            const type: string | undefined = action.payload
            const response: ResponseGenerator = yield call(getUserSubscriptionPlan, type)
            if (response.status !== HTTP_CODE.SUCCESS) {
                throw new Error(response.data.message);//@TODO:check format
            }

            if (response && response.data.length > 0) {
                yield put(setSubscriptions(response.data))
                const selected = response.data.find((plan: any) => plan.id === user.planId)
                if (selected) {
                    yield put(setSelectedSubscription(selected))
                } else {
                    yield put(setSelectedSubscription(response.data[0]))
                }

            }

        } catch (error: any) {
            yield put(setActionResult({ isSuccess: false, message: error.message }))
        }
    })
    yield takeLatest(customPlanRequest.type, function* sendCustomPlanRequestEmail(action: ReturnType<typeof customPlanRequest>) {
        try {
            const user: UserModel = yield select(state => state.auth.user);
            const message: string = action.payload.message
            const response: ResponseGenerator = yield call(sendContactRequest, {
                name: getUserFullName(user, intl.locale),
                email: user.email,
                message: message,
                language: intl.locale

            })
            if (response.status !== HTTP_CODE.SUCCESS) {
                throw new Error(response.data.message);//@TODO:check format
            }
            yield put(setActionResult({ isSuccess: true, message: "CONTACT_REQUEST_SUCCESS" }))

        } catch (error: any) {
            yield put(setActionResult({ isSuccess: false, message: error.message }))
        }
    })
    yield takeLatest(buySubscription.type, function* buySubscriptionWithCurrency(action: ReturnType<typeof buySubscription>) {
        try {
            const { currency, paymentMethod } = action.payload
            const currentSubscription: SubscriptionPlanModel = yield select(state => state.pricing.selectedSubscription);
            if (!currentSubscription) {
                throw new Error("Please select a subscription first");
            }
            const response: ResponseGenerator = yield call(updateUserSubscription, currentSubscription.id, currency, paymentMethod)
            if (response.status !== HTTP_CODE.SUCCESS) {
                throw new Error(response.data.message);//@TODO:check format
            }
            let message = undefined
            if (response.data.clientSecret) {
                //@TODO: map currency code
                yield put(setPaymentModalLink({ link: response.data.clientSecret, currency: currency }))
            } else {

                //if payment method is bank or pay as u go plan, we don't need to show payment modal
                if (NONE_CHECKOUT_PLAN.includes(currentSubscription.id) || paymentMethod === PAYMENT_METHOD.BANK) {
                    //update user plan
                    const user: UserModel = yield select(state => state.auth.user);
                    let updatedUser = clone(user);
                    updatedUser.planId = currentSubscription.id;
                    updatedUser.plan = currentSubscription.name;
                    updatedUser.planType = currentSubscription.type;
                    yield put(setUser({ user: updatedUser }))
                    if (paymentMethod === PAYMENT_METHOD.BANK) {
                        message = intl.formatMessage({ id: "payment.BANK.success" })
                    } else {
                        message = intl.formatMessage({ id: "payment.SUBSCRIPTION.success" })
                    }
                }
            }

            yield put(setActionResult({ isSuccess: true, message }))
        } catch (error: any) {
            const errorBody = error.response && error.response.data;
            yield put(setActionResult({ isSuccess: false, message: errorBody.messages[0] }))
        }
    })
}