export enum COUNTRY_CODES {
  US = 'en',
  JP = 'ja',
}

export enum USER_TYPE {
  INDIVIDUAL = 'individual',
  ENTERPRISE = 'enterprise',
}

export enum USER_VERIFY_STATUS {
  UN_VERIFY = 'un-verify',
  VERIFYING = 'verifying',
  VERIFIED = 'verified',
}
