import {FC} from 'react'
import {useIntl} from 'react-intl'
import {ItemDisplayMode} from '../constants'

interface Props {
  onChangeMode: any
  isOrderCompleted: boolean
  mode: ItemDisplayMode
}
const ItemViewMode: FC<Props> = (props) => {
  const isOrderCompleted = props.isOrderCompleted
  const mode = props.mode
  const intl = useIntl()
  return (
    <div className='btn-group' role='group'>
      <button
        type='button'
        className={`btn ${
          mode === ItemDisplayMode.Original ? 'btn-active-light-primary active' : 'btn-secondary'
        }`}
        onClick={() => {
          props.onChangeMode(ItemDisplayMode.Original)
        }}
      >
        {intl.formatMessage({id: 'ITEM.VIEW.Origin'})}
      </button>
      <button
        type='button'
        disabled={!isOrderCompleted}
        className={`btn ${
          mode === ItemDisplayMode.Output ? 'btn-active-light-primary active' : 'btn-secondary'
        }`}
        onClick={() => {
          props.onChangeMode(ItemDisplayMode.Output)
        }}
      >
        {intl.formatMessage({id: 'ITEM.VIEW.Output'})}
      </button>
      <button
        type='button'
        disabled={!isOrderCompleted}
        className={`btn ${
          mode === ItemDisplayMode.Compare ? 'btn-active-light-primary active' : 'btn-secondary'
        }`}
        onClick={() => {
          props.onChangeMode(ItemDisplayMode.Compare)
        }}
      >
        {intl.formatMessage({id: 'ITEM.VIEW.Compare'})}
      </button>
    </div>
  )
}
export default ItemViewMode
