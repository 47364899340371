
import { RootState } from "../../../../setup/redux/Store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ListResponseModel } from "../../../shared/models/ListResponseModel";

import { PaginateModel } from "../../../shared/models/PaginateModel";
import { OrderItemModel } from "../../orders/models/OrderItemModel";
import { ItemFilterModel } from "../models/ItemFilter";
import { cloneDeep } from "lodash";
import { toast } from "react-toastify";
import { ItemOptionModel } from "../../orders/models/ItemOptionModel";
import { ItemOutputModel } from "../models/ItemOutputModel";
export interface IItemState {
    loading: boolean;
    list: OrderItemModel[];
    filter: ItemFilterModel;
    current: OrderItemModel | undefined;
    currentDetailOutput: ItemOutputModel | undefined;
}
const initialState: IItemState = {
    list: [],
    loading: false,
    filter: {
        page: 1,
        take: Number(process.env.REACT_APP_DEFAULT_PAGE_SIZE),
        itemCount: 0,
        pageCount: 1,
    } as PaginateModel,
    current: undefined,
    currentDetailOutput: undefined,


}

export const itemSlice = createSlice({
    name: "item",
    initialState,
    reducers: {
        getAll: (state) => {
            state.loading = true;
        },
        getDetail: (state, action: PayloadAction<string>) => {
            state.loading = true;
        },
        getDetailOutput: (state, action: PayloadAction<string>) => {
            state.loading = true;
        },
        updateItemOutput: (state, action: PayloadAction<ItemOutputModel>) => {
            state.loading = true;
        },
        filterDataChange: (state, action: PayloadAction<ItemFilterModel>) => {
            state.loading = true;
            state.filter = action.payload;
        },
        setList: (state, action: PayloadAction<ListResponseModel<OrderItemModel>>) => {
            state.loading = false;
            state.list = action.payload.data;
            state.filter = action.payload.meta;
        },
        setCurrent: (state, action: PayloadAction<OrderItemModel>) => {
            state.loading = false;
            state.current = action.payload;
        },
        setCurrentDetailOutput: (state, action: PayloadAction<ItemOutputModel | undefined>) => {
            state.loading = false;
            state.currentDetailOutput = action.payload;


        },
        rating: (state, action: PayloadAction<{ id: number, star: number }>) => {
            let updatedList = cloneDeep(state.list)
            let target = updatedList.find(item => item.id === action.payload.id)
            if (target) {
                target.star = action.payload.star
                state.list = updatedList
            }
        },
        retryAi: (state, action: PayloadAction<ItemOptionModel[]>) => {
            state.loading = false;

        },
        setTransactionFail: (state, action: PayloadAction<string>) => {
            state.loading = true;
            toast.error(action.payload)
        },
        setTransactionSuccess: (state, action: PayloadAction<string>) => {
            state.loading = true;
            toast.success(action.payload)
        },
    },

})
export const {
    rating,
    getAll,
    getDetail,
    getDetailOutput,
    filterDataChange,
    setList,
    setCurrent,
    retryAi,
    setTransactionFail,
    setTransactionSuccess,
    setCurrentDetailOutput,
    updateItemOutput
} = itemSlice.actions;

export const selectItemLoading = (state: RootState) => state.item.loading;
export const selectItemsFilter = (state: RootState) => state.item.filter;
export const selectItemList = (state: RootState) => state.item.list;
export const selectCurrentItem = (state: RootState) => state.item.current;
export const selectCurrentItemOutput = (state: RootState) => state.item.currentDetailOutput;
export default itemSlice.reducer;