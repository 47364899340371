import {FC} from 'react'
import { toAbsoluteUrl} from '../../../../_metronic/helpers'
const Error404: FC = () => {
  return (
    <>
      <h1 className='fw-bolder fs-4x text-gray-700 mb-10'>Page Not Found</h1>

      <div className='fw-bold fs-3 text-gray-400 mb-15'>
        The page you looked not found! <br /> Plan your blog post by choosing a topic
      </div>
      <div className="mb-11">
          <img src={toAbsoluteUrl('/media/auth/NotFound.png')} className="mw-100 mh-300px theme-light-show" alt="Page Not Found"/>
      </div>
    </>
  )
}

export {Error404}
