import {OrderModel} from '../models/OrderModel'
import {FC} from 'react'
import {Link} from 'react-router-dom'
import './orderGrid.scss'
import moment from 'moment'
import {useIntl} from 'react-intl'
import {ORDER_ACTION_VIEW_MODE, OrderStatus} from '../constants'
import {orderStatusClassMapping} from '../utils'
import {LazyLoadImage} from 'react-lazy-load-image-component'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import OrderActionBox from './OrderActionBox'
interface Props {
  orders: OrderModel[]
}

const OrderGridView: FC<Props> = (prop) => {
  const intl = useIntl()
  return (
    <div className='row g-6 g-xl-9 ' style={{minHeight: '100px'}}>
      {prop.orders.map((order) => {
        // const isDisabled = [OrderStatus.AI_PROCESSING, OrderStatus.Analyzing].includes(order.status)
        const isDisabled = false
        return (
          <div key={order.id} className='col-md-6 order-card col-xl-4'>
            <div className='card shadow border border-2 border-gray-300 border-hover'>
              <Link className='border-0 order-item-card-title' to={'orders/' + order.id}>
                <div className='card-title m-0'>
                  <LazyLoadImage
                    effect='blur'
                    src={order.orderCover?.tiny}
                    placeholderSrc={toAbsoluteUrl('/media/loading-bg.gif')}
                    alt={order.name}
                    height={250}
                    className='rounded-top'
                    onError={(event: any) => {
                      if (event?.target?.src)
                        event.target.src = toAbsoluteUrl('/media/loading-bg.gif') // Replace with your default image URL
                    }}
                  />
                </div>
                <div className='card-toolbar card-order-status'>
                  <span
                    className={`badge ${orderStatusClassMapping(
                      order.status
                    )} fw-bolder me-auto px-4 py-3`}
                  >
                    {intl.formatMessage({id: 'ORDER.STATUS.' + order.status})}
                  </span>
                </div>
                <div
                  style={{width: '95%'}}
                  className='card-toolbar card-order-service d-flex justify-content-between'
                >
                  <span className={`badge badge-primary fw-bolder`}>
                    {intl.formatMessage({id: 'SERVICE.SERVICE.' + order?.service?.name})}
                  </span>
                  <span className={`badge badge-secondary fw-bolder`}>
                    {intl.formatMessage({id: 'Order.table.COMPLETION'}, {
                      totalItems: order.totalItems
                    })}
                  </span>
                </div>
              </Link>
              <div className='card-body p-4 '>
                <div className='d-flex justify-content-between mb-3 '>
                  <Link
                    to={'orders/' + order.id}
                    className=' text-truncate fs-3 fw-bolder text-dark order-card-title'
                  >
                    {order.name}
                  </Link>
                  <div className='card-order-actions'>
                    <OrderActionBox order={order} viewMode={ORDER_ACTION_VIEW_MODE.MINIFY} />
                  </div>
                </div>

                <div className='d-flex flex-wrap justify-content-between'>
                  <div className='border border-gray-300 border-dashed rounded py-3 px-4 custom-mw-33percent'>
                    <div className='fs-6 text-gray-800 fw-bolder'>
                      {order.createdAt &&
                        moment(order.createdAt).format(process.env.REACT_APP_DATE_FORMAT)}
                    </div>
                    <div className='fw-bold text-gray-400'>
                      {intl.formatMessage({id: 'Order.table.CREATE_DATE'})}
                    </div>
                  </div>
                  {/* <div className='border border-gray-300 border-dashed rounded  py-3 px-4'>
                    <div className='fs-6 text-gray-800 fw-bolder'>
                      {order.completedItems}/{order.totalItems}
                    </div>
                    <div className='fw-bold text-gray-400'>
                      {intl.formatMessage({id: 'Order.table.COMPLETION'})}
                    </div>
                  </div> */}
                  <div className='border border-gray-300 border-dashed rounded  py-3 px-4 custom-mw-33percent'>
                    <div className='text-truncate fs-6 text-gray-800 fw-bolder'>
                      {intl.formatMessage({id: order.setting?.name ?? 'unknown'})}
                    </div>
                    <div className='fw-bold text-gray-400'>
                      {intl.formatMessage({id: 'Order.table.SETTING'})}
                    </div>
                  </div>
                  <div className='border border-gray-300 border-dashed rounded  py-3 px-4 custom-mw-33percent'>
                    <div className='text-truncate fs-6 text-gray-800 fw-bolder'>
                      {intl.formatMessage({id: 'Order.table.' + order.type})}
                    </div>
                    <div className='fw-bold text-gray-400'>
                      {intl.formatMessage({id: 'Order.table.QUALITY'})}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {isDisabled && (
              <div className='order-card-disable d-flex justify-content-center'>
                <div className='spinner-grow m-auto text-warning' role='status'>
                  <span className='sr-only'>Loading...</span>
                </div>
              </div>
            )}
          </div>
        )
      })}
    </div>
  )
}

export default OrderGridView
