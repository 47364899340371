import axios from "axios"
import { PaginateModel } from "../../../shared/models/PaginateModel"

const API_URL = process.env.REACT_APP_API_URL
export const BILLING_BASE_URL = `${API_URL}/account/transactions`

export function getAllUserTransaction(params: PaginateModel) {
  return axios.get(BILLING_BASE_URL, {
    params,
  })
}

export function downloadDocument(transactionId: number) {
  return axios.get(BILLING_BASE_URL + '/' + transactionId + '/get-document')
}

