import {FC, useState} from 'react'
import {useIntl} from 'react-intl'
import {useAppSelector} from '../../../../setup/redux/hooks'
import {selectUser} from '../../../modules/auth'
import {OrderPaymentModel} from '../models/OrderPaymentModel'
import {PAYMENT_METHOD} from '../../../shared/constants'
import {CurrencyCode} from '../../pricing/pricing.constant'
import {COUNTRY_CODES, USER_TYPE, USER_VERIFY_STATUS} from '../../../modules/profile/constants'
import React from 'react'

type Props = {
  className?: string
  totalOrder: number
  currency: CurrencyCode
  fiatAmount: number
  orderDetails: OrderPaymentModel[]
  onPaymentMethodChange: (method: string) => void
}
const paymentMethods = [
  {
    icon: 'bi-wallet2',
    name: PAYMENT_METHOD.CREDIT,
    currency: [CurrencyCode.USD, CurrencyCode.JPY],
  },
  {
    icon: 'bi-credit-card',
    name: PAYMENT_METHOD.VISA,
    currency: [CurrencyCode.USD, CurrencyCode.JPY],
  },
]
const PaymentInformation: FC<Props> = (props) => {
  const intl = useIntl()
  const user = useAppSelector(selectUser)
  const [paymentMethod, setPaymentMethod] = useState(PAYMENT_METHOD.CREDIT)
  return (
    <div style={{backgroundColor: '#f5f8fa'}} className={props.className + ' rounded border p-5'}>
      <div className='pb-5 '>
        <h3 className='pb-3 fw-bolder d-flex align-items-center text-dark'>
          {intl.formatMessage({id: 'Download.setting.payment'})}
        </h3>
        <table className='table mb-5 table-row-bordered table-row-gray-100 align-middle gs-0 gy-3 text-center'>
          <thead>
            <tr className='fw-bolder text-muted'>
              <th className='min-w-130px text-center'>
                {intl.formatMessage({id: 'Order.payment.service'})}
              </th>
              <th className='min-w-80px'>{intl.formatMessage({id: 'Order.payment.unit'})}</th>
              <th className='min-w-80px'>{intl.formatMessage({id: 'Order.payment.quantity'})}</th>
              <th className='min-w-100px '>{intl.formatMessage({id: 'Order.payment.total'})}</th>
            </tr>
          </thead>

          <tbody>
            {props.orderDetails &&
              props.orderDetails.map((order: OrderPaymentModel, index: number) => (
                <tr key={index}>
                  <td className='text-left'>{order.itemName}</td>
                  <td>{Number(order.unitPrice).toFixed(2)}</td>
                  <td>{order.quantity}</td>
                  <td>{(Number(order.quantity) * Number(order.unitPrice)).toFixed(2)}</td>
                </tr>
              ))}
          </tbody>
        </table>
        <div className='d-flex justify-content-between'>
          <div className='fs-4'> {intl.formatMessage({id: 'Download.payment.total'})}</div>
          <div className='fw-bolder fs-3'>
            {props.totalOrder.toFixed(2)} {intl.formatMessage({id: 'SYSTEM.CURRENCY'})}
          </div>
        </div>
        <div className='d-flex justify-content-between'>
          <div className='fs-4'> </div>
          <div className='fs-5'>
            *
            {props.currency === CurrencyCode.USD
              ? (props.fiatAmount / 100).toFixed(2)
              : props.fiatAmount}{' '}
            {intl.formatMessage({id: props.currency})}
          </div>
        </div>
        <div className='d-flex justify-content-between'>
          <div className='fs-4'> </div>
          <div className='fs-7 text-danger'>
            {props.currency === CurrencyCode.JPY &&
              intl.formatMessage({id: 'MODAL.PLAN.tax_guide'})}
          </div>
        </div>
      </div>
      {props.totalOrder > 0 && (
        <div className='fv-row'>
          <div className='pb-5 fs-4 d-flex justify-content-between'>
            <div> {intl.formatMessage({id: 'Download.payment'})}</div>
          </div>
          <div className='row'>
            {paymentMethods.map((method, index) => {
              const isDisableBank =
                method.name === PAYMENT_METHOD.BANK &&
                user &&
                (user.verifyStatus !== USER_VERIFY_STATUS.VERIFIED ||
                  user.countryCode !== COUNTRY_CODES.JP ||
                  user.userType !== USER_TYPE.ENTERPRISE)
              return (
                <div
                  className='col-lg-12'
                  key={index}
                  onClick={() => {
                    if (isDisableBank && method.name === PAYMENT_METHOD.BANK) {
                      return
                    }
                    setPaymentMethod(method.name)
                    props.onPaymentMethodChange(method.name)
                  }}
                >
                  <label
                    className={
                      (paymentMethod === method.name ? 'active' : isDisableBank ? 'disabled' : '') +
                      ' btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10'
                    }
                  >
                    <i className={'bi ' + method.icon + ' fs-3x me-5'}></i>

                    <span className='d-block fw-bold text-start'>
                      <span className='text-dark fw-bolder d-block fs-4 mb-2'>
                        {intl.formatMessage({id: 'payment.' + method.name + '.title'})}
                        {method.name === PAYMENT_METHOD.CREDIT &&
                          '(' +
                            (user?.points ?? 0).toFixed(2) +
                            ' ' +
                            intl.formatMessage({id: 'SYSTEM.CURRENCY'}) +
                            ')'}
                      </span>
                      <span className='text-gray-400 fw-bold fs-6'>
                        {intl
                          .formatMessage({id: 'payment.' + method.name + '.info'})
                          .split('\n')
                          .map((line, ind) => (
                            <React.Fragment key={ind}>
                              {line}
                              <br />
                            </React.Fragment>
                          ))}
                        {method.name === PAYMENT_METHOD.CREDIT && (
                          <a href='/pricing' className='link-primary fw-bolder'>
                            {intl.formatMessage({id: 'Download.setting.Balance.price'})}
                          </a>
                        )}
                      </span>
                    </span>
                  </label>
                </div>
              )
            })}
            {paymentMethod === PAYMENT_METHOD.BANK && (
              <div className='mx-1'>
                <div className='d-flex bg-light-warning rounded border-warning border border-dashed p-6'>
                  <i className='bi bi-exclamation-circle-fill fs-2tx text-warning me-4'></i>

                  <div className='d-flex flex-stack flex-grow-1'>
                    <div className='fw-bold'>
                      <h4 className='text-gray-800 fw-bolder'>
                        {intl.formatMessage({id: 'payment.BANK.notice'})}
                      </h4>
                      <div className='fs-6 text-gray-600'>
                        {intl.formatMessage({id: 'payment.BANK.notice.des'})}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className='text-danger mt-2'></div>
          </div>
        </div>
      )}
    </div>
  )
}

export default PaymentInformation
