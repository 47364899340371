import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import {FC, useEffect, useRef} from 'react'
import * as Yup from 'yup'
import {useDispatch} from 'react-redux'
import {SettingReferenceModel} from '../models/SettingReferenceModel'
import {useFormik} from 'formik'
import {SettingReferenceStatus} from '../constants'
import {RouteComponentProps, useHistory} from 'react-router-dom'
import FeatureItem from './FeatureItem'
import {ReferenceFeatureModel} from '../models/ReferenceFeatureModel'
import {useAppSelector} from '../../../../setup/redux/hooks'

import Tooltip from 'react-tooltip-lite'
import {
  createSetting,
  getAllServices,
  getCurrent,
  selectCategoriesList,
  selectCurrentSettingReference,
  selectFeatureList,
  selectServicesList,
  setCurrent,
  selectSettingReferenceLoading,
  updateSetting,
} from '../redux/SettingReferenceRedux'
import {SimpleListModel} from '../../../shared/models/SimpleListModel'
import {FeatureModel} from '../models/FeatureModel'
import {FeatureDataType} from '../../../modules/services/constants'
import {cloneDeep} from 'lodash'

const initialValues: SettingReferenceModel = {
  id: 0,
  name: '',
  serviceId: 1,
  categoryId: 1,
  status: SettingReferenceStatus.ACTIVE,
  isDefault: false,
  additionalFee: 0,
  settingFeatures: [],
  totalPrice: 0,
  isAiSupport: true,
}
interface RouteParams {
  settingId?: string
}
interface Props extends RouteComponentProps<RouteParams> {
  className?: string
}

const SettingReferenceDetail: FC<Props> = (props) => {
  //basic init
  const intl = useIntl()
  const dispatch = useDispatch()
  const didRequest = useRef(false)
  const history = useHistory()

  const itemId = props.match.params.settingId
  //state init
  const loading: boolean = useAppSelector(selectSettingReferenceLoading)
  const current: SettingReferenceModel | undefined = useAppSelector(selectCurrentSettingReference)
  const serviceList: SimpleListModel[] = useAppSelector(selectServicesList)
  const categoryList: SimpleListModel[] = useAppSelector(selectCategoriesList)
  const featureList: FeatureModel[] = useAppSelector(selectFeatureList)
  //local data initial
  const settingReferenceSchema = Yup.object().shape({
    name: Yup.string()
      .min(1, intl.formatMessage({id: 'SETTING_REFERENCE.NAME.MIN'}))
      .max(50, intl.formatMessage({id: 'SETTING_REFERENCE.NAME.MAX'}))
      .required(intl.formatMessage({id: 'SETTING_REFERENCE.NAME.REQUIRED'})),
    serviceId: Yup.number().required(
      intl.formatMessage({id: 'SETTING_REFERENCE.SERVICE.REQUIRED'})
    ),
    categoryId: Yup.number().required(
      intl.formatMessage({id: 'SETTING_REFERENCE.CATEGORY.REQUIRED'})
    ),
    isDefault: Yup.boolean().required(
      intl.formatMessage({id: 'SETTING_REFERENCE.DEFAULT.REQUIRED'})
    ),
    settingFeatures: Yup.array()
      .min(1, intl.formatMessage({id: 'SETTING_REFERENCE.FEATURE.MIN'}))
      .required(intl.formatMessage({id: 'SETTING_REFERENCE.FEATURE.REQUIRED'})),
  })

  const title = current
    ? intl.formatMessage({id: 'SETTING_REFERENCE.UPDATE_TITLE'})
    : intl.formatMessage({id: 'SETTING_REFERENCE.CREATE_TITLE'})
  const formik = useFormik({
    initialValues,
    validationSchema: settingReferenceSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      if (values.id === 0) {
        dispatch(createSetting(values))
      } else {
        dispatch(updateSetting(values))
      }
      setTimeout(() => {
        history.push('/setting-references')
      }, 1000)
    },
  })
  //default page initial
  useEffect(() => {
    if (!didRequest.current) {
      dispatch(getAllServices(true))
    }
    if (itemId && itemId !== 'create') {
      if (current && current.id === Number(itemId)) {
        formik.resetForm({values: current})
      } else {
        dispatch(getCurrent(itemId))
      }
    } else {
      if (current) {
        dispatch(setCurrent(undefined))
      }
      formik.resetForm({values: initialValues})
    }
    return () => {
      didRequest.current = true
    }
  }, [itemId, current])
  const onFeaturesChanged = (
    fieldName: string,
    featureId: number,
    value: any,
    type: FeatureDataType
  ) => {
    let currentData: any = cloneDeep(formik.values['settingFeatures'])
    let targetItem: ReferenceFeatureModel | undefined = currentData.find(
      (item: ReferenceFeatureModel) => item.featureId === featureId
    )
    let targetValue = value
    if (typeof value === 'object') {
      targetValue = JSON.stringify(value)
    }
    if (targetItem) {
      if (value) {
        targetItem.value = targetValue
      } else {
        currentData = currentData.filter((item: any) => item.featureId !== featureId)
      }
    } else {
      currentData.push({
        featureId: featureId,
        value: targetValue,
        type: type,
      })
    }
    formik.setFieldValue(fieldName, currentData, true)
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>{title}</PageTitle>
      <div className='card mb-10'>
        <form
          id='kt_setting_reference_form'
          onSubmit={formik.handleSubmit}
          noValidate
          className='form'
        >
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-2 col-form-label required fw-bold fs-6'>
                {intl.formatMessage({id: 'SETTING_REFERENCE.NAME'})}
              </label>

              <div className='col-lg-10 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder={intl.formatMessage({id: 'SETTING_REFERENCE.NAME.PLACEHOLDER'})}
                  {...formik.getFieldProps('name')}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert'>{formik.errors.name}</span>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6 pe-0'>
              <label className='col-lg-2 col-form-label required fw-bold fs-6'>
                {intl.formatMessage({id: 'SETTING_REFERENCE.SERVICE'})}
              </label>
              <div className='col-lg-10 row pe-0'>
                <div className='col-lg-5'>
                  <select
                    className='form-select form-select-solid form-select-lg'
                    {...formik.getFieldProps('serviceId')}
                  >
                    {serviceList?.map((service: any) => (
                      <option key={service.id} value={service.id}>
                        {intl.formatMessage({id: 'SERVICE.SERVICE.' + service.label})}
                      </option>
                    ))}
                  </select>
                  {formik.touched.serviceId && formik.errors.serviceId && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors.serviceId}</span>
                    </div>
                  )}
                </div>

                <div className='col-lg-7 row pe-0'>
                  <label className='d-flex col-lg-4 col-form-label required fw-bold fs-6'>
                    {intl.formatMessage({id: 'SETTING_REFERENCE.CATEGORY'})}
                    <Tooltip
                      content={
                        <div style={{maxWidth: '400px'}}>
                          {intl.formatMessage({id: 'SETTING_REFERENCE.CATEGORY.TOOLTIP'})}
                        </div>
                      }
                      className='tooltips'
                    >
                      <i
                        className={
                          'bi text-primary  ms-2 fs-3 font-weight-bold  bi bi-info-circle-fill'
                        }
                      ></i>
                    </Tooltip>
                  </label>

                  <div className='col-lg-8 pe-0'>
                    <select
                      className='form-select form-select-solid form-select-lg'
                      {...formik.getFieldProps('categoryId')}
                    >
                      {categoryList?.map((service: any) => (
                        <option key={service.id} value={service.id}>
                          {intl.formatMessage({id: 'SETTING_REFERENCE.CATEGORY.' + service.label})}
                        </option>
                      ))}
                    </select>
                    {formik.touched.categoryId && formik.errors.categoryId && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.categoryId}</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-2 col-form-label fw-bold fs-6'>
                {intl.formatMessage({id: 'SETTING_REFERENCE.IS_DEFAULT'})}
              </label>

              <div className='col-lg-10 d-flex align-items-center'>
                <div className='form-check form-check-solid form-switch fv-row'>
                  <input
                    className='form-check-input w-45px h-30px'
                    type='checkbox'
                    checked={formik.values.isDefault}
                    {...formik.getFieldProps('isDefault')}
                  />
                  {formik.touched.isDefault && formik.errors.isDefault && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.isDefault}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-12 col-form-label fw-bold fs-6'>
                {intl.formatMessage({id: 'SETTING_REFERENCE.FEATURE'})}
              </label>

              <div className='col-lg-12'>
                <FeatureItem
                  dataItems={featureList}
                  values={formik.values.settingFeatures}
                  onChange={(id: number, value: any, type: FeatureDataType) =>
                    onFeaturesChanged('settingFeatures', id, value, type)
                  }
                />
                {formik.touched.settingFeatures && formik.errors.settingFeatures && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert'>{formik.errors.settingFeatures}</span>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button
              className='btn btn-light me-3'
              onClick={() => {
                history.push('/setting-references')
              }}
            >
              {intl.formatMessage({id: 'INQUIRY.BUTTON.CANCEL'})}
            </button>
            <button
              type='submit'
              className='btn btn-primary'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && intl.formatMessage({id: 'SETTING_REFERENCE.BTN.SAVE'})}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {intl.formatMessage({id: 'Login.form.WAITING'})}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </>
  )
}
export default SettingReferenceDetail
