import { toAbsoluteUrl } from "../../../_metronic/helpers";


export enum SettingReferenceStatus {
  ACTIVE = '1',
  DISABLE = '2',
}

export enum FeatureStatus {
  ENABLE = '1',
  DISABLE = '2',
  COMING_SOON = '3',
  BETA = '4',
}

export enum FeaturePriceType {
  INCLUDED = '0',
  PAID = '1',
  FREE = '2'
}

export enum PlanType {
  PAY_AS_U_GO = "PAY_AS_U_GO",
  SUBSCRIPTION= "SUBSCRIPTION",
  ENTERPRISE = "ENTERPRISE"
}

export enum WatermarkPosition {
  CENTER = 1,
  TOP_LEFT = 2,
  TOP_RIGHT = 3,
  BOTTOM_RIGHT = 4,
  BOTTOM_LEFT = 5,
}

export const defaultWatermarkSetting = {
  watermark: toAbsoluteUrl('/media/logos/watermask-logo.png'),
  position: WatermarkPosition.CENTER,
  size: 50,
  opacity: 80
}
export const MARGIN = 5

export enum FeatureViewMode {
  FULL = 'FULL',
  MINIMIZE = 'MINIMIZE',
}
