import {FC, useEffect} from 'react'
import Modal from 'react-bootstrap/Modal'
import {useIntl} from 'react-intl'
import {useDispatch} from 'react-redux'
import * as Yup from 'yup'
import {
  OrderDownloadRequestModel,
  OrderDownloadSchema,
} from '../../models/orderDownloadRequestModel'
import {OrderDownloadType, OrderPaymentMethod} from '../../constants'
import {getIn, useFormik} from 'formik'
import {useAppSelector} from '../../../../../setup/redux/hooks'
import {selectUser} from '../../../../modules/auth/redux/AuthRedux'
import clsx from 'clsx'
import Button from 'react-bootstrap/Button'
import {
  downloadOrder,
  selectCurrentDownloadCredit,
  selectOrderLoading,
  selectSelectedItems,
  selectSelectedMode,
  setCurrentDownloadOrderCredit,
} from '../../redux/OrderRedux'
import {DIMENSIONS_OPTIONS, MAX_DIMENSIONS_SIZE, MIN_DIMENSIONS_SIZE} from '../../constants'
import './downloadOrderModal.scss'
import PaymentInformation from '../paymentInfomation'
import {CurrencyCode} from '../../../pricing/pricing.constant'

const DownloadOrderModal: FC = (props) => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const user = useAppSelector(selectUser)
  const loading = useAppSelector(selectOrderLoading)
  const userBalance = user?.points ?? 0
  const downloadOrderCredit = useAppSelector(selectCurrentDownloadCredit)
  const isAllowToDownload = userBalance >= (downloadOrderCredit?.paidRequestAmount ?? 0)
  const selectMode = useAppSelector(selectSelectedMode)
  const selectedItems = useAppSelector(selectSelectedItems)
  const initialValues: OrderDownloadSchema = {
    name: '',
    type: selectMode ? OrderDownloadType.SELECTED : OrderDownloadType.OUTPUT,
    paymentMethod: OrderPaymentMethod.CREDIT,
  }

  const downloadSchema = Yup.object().shape({
    name: Yup.string()
      .min(1, intl.formatMessage({id: 'Download.name.MIN'}))
      .max(50, intl.formatMessage({id: 'Download.name.MAX'}))
      // .matches(/[^a-zA-Z0-9_\-.\s]/, intl.formatMessage({id: 'Download.name.RULE'}))
      .required(intl.formatMessage({id: 'Download.name.REQUIRED'})),
    type: Yup.string().required(intl.formatMessage({id: 'Login.password.REQUIRED'})),
    dimensions: Yup.object()
      .shape({
        changeBy: Yup.mixed<DIMENSIONS_OPTIONS>()
          .oneOf(Object.values(DIMENSIONS_OPTIONS))
          .required(),
        crop: Yup.object().shape({
          longEdge: Yup.number()
            .integer()
            .min(
              MIN_DIMENSIONS_SIZE,
              intl.formatMessage({id: 'Download.setting.image_dimensions.MIN'})
            )
            .max(
              MAX_DIMENSIONS_SIZE,
              intl.formatMessage({id: 'Download.setting.image_dimensions.MAX'})
            )
            .when('$dimensions.changeBy', (changeBy, schema) => {
              return changeBy === DIMENSIONS_OPTIONS.CROP
                ? schema.required(
                    intl.formatMessage({id: 'Download.setting.image_dimensions.REQUIRED'})
                  )
                : schema.optional()
            }),
          shortEdge: Yup.number()
            .integer()
            .min(
              MIN_DIMENSIONS_SIZE,
              intl.formatMessage({id: 'Download.setting.image_dimensions.MIN'})
            )
            .max(
              MAX_DIMENSIONS_SIZE,
              intl.formatMessage({id: 'Download.setting.image_dimensions.MAX'})
            )
            .when('$dimensions.changeBy', (changeBy, schema) => {
              return changeBy === DIMENSIONS_OPTIONS.CROP
                ? schema.required(
                    intl.formatMessage({id: 'Download.setting.image_dimensions.REQUIRED'})
                  )
                : schema.optional()
            }),
        }),
        resize: Yup.object().shape({
          longEdge: Yup.number()
            .integer()
            .min(
              MIN_DIMENSIONS_SIZE,
              intl.formatMessage({id: 'Download.setting.image_dimensions.MIN'})
            )
            .max(
              MAX_DIMENSIONS_SIZE,
              intl.formatMessage({id: 'Download.setting.image_dimensions.MAX'})
            )
            .when('$dimensions.changeBy', (changeBy, schema) => {
              return changeBy === DIMENSIONS_OPTIONS.LONG_EDGE
                ? schema.required(
                    intl.formatMessage({id: 'Download.setting.image_dimensions.REQUIRED'})
                  )
                : schema.optional()
            }),
          shortEdge: Yup.number()
            .integer()
            .min(
              MIN_DIMENSIONS_SIZE,
              intl.formatMessage({id: 'Download.setting.image_dimensions.MIN'})
            )
            .max(
              MAX_DIMENSIONS_SIZE,
              intl.formatMessage({id: 'Download.setting.image_dimensions.MAX'})
            )
            .when('$dimensions.changeBy', (changeBy, schema) => {
              return changeBy === DIMENSIONS_OPTIONS.SHORT_EDGE
                ? schema.required(
                    intl.formatMessage({id: 'Download.setting.image_dimensions.REQUIRED'})
                  )
                : schema.optional()
            }),
        }),
      })
      .default(undefined),
    maxFileSize: Yup.number().integer().min(1).default(undefined),
    paymentMethod: Yup.string(),
  })
  const formik = useFormik({
    initialValues,
    validationSchema: downloadSchema,
    onSubmit: async (values, {setSubmitting}) => {
      // Update payload
      let payload: OrderDownloadRequestModel = {
        ...values,
        dimensions: undefined,
        selectedItems,
      }
      switch (values.dimensions?.changeBy) {
        case DIMENSIONS_OPTIONS.CROP: {
          let longEdge = values.dimensions.crop?.longEdge as number
          let shortEdge = values.dimensions.crop?.shortEdge as number

          if (shortEdge > longEdge) {
            ;[longEdge, shortEdge] = [shortEdge, longEdge]
          }
          payload.dimensions = {
            changeBy: DIMENSIONS_OPTIONS.CROP,
            longEdge: longEdge,
            shortEdge: shortEdge,
          }
          break
        }
        case DIMENSIONS_OPTIONS.LONG_EDGE: {
          payload.dimensions = {
            changeBy: DIMENSIONS_OPTIONS.LONG_EDGE,
            longEdge: values.dimensions.resize?.longEdge,
          }

          break
        }
        case DIMENSIONS_OPTIONS.SHORT_EDGE: {
          payload.dimensions = {
            changeBy: DIMENSIONS_OPTIONS.SHORT_EDGE,
            shortEdge: values.dimensions?.resize?.shortEdge,
          }
          break
        }
      }

      formik.resetForm()
      onClose()
      dispatch(downloadOrder({...payload, orderId: downloadOrderCredit?.orderId}))
    },
  })

  const dimensions = formik.values.dimensions?.changeBy || DIMENSIONS_OPTIONS.ORIGINAL
  const onPaymentMethodChange = (value: string) => {
    formik.setFieldValue('paymentMethod', value)
  }
  useEffect(() => {
    if (downloadOrderCredit !== undefined) {
      formik.resetForm()
      //set initial multiple values
      formik.setValues({
        name: downloadOrderCredit.orderName,
        type: selectMode ? OrderDownloadType.SELECTED : OrderDownloadType.OUTPUT,
        paymentMethod: OrderPaymentMethod.CREDIT,
        dimensions: undefined,
      })
    }
  }, [downloadOrderCredit])

  const onClose = () => {
    dispatch(setCurrentDownloadOrderCredit(undefined))
  }
  return (
    <Modal
      size='xl'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      show={downloadOrderCredit !== undefined}
      onHide={onClose}
      backdrop='static'
    >
      <Modal.Header closeButton>
        <h2 className='fw-bolder'>{intl.formatMessage({id: 'Download.setting.header'})}</h2>
      </Modal.Header>
      <form
        className='form w-100  '
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_order_download_form'
      >
        <Modal.Body>
          <div className='row'>
            <div className='col col-md-7  py-10'>
              <div className='text-center mb-10'>
                <h3 className='fw-bolder d-flex align-items-center text-dark'>
                  {intl.formatMessage({id: 'Download.setting.TITLE'})}
                </h3>
              </div>
              <div className='fv-row mb-10'>
                <label className='form-label mb-5 fs-6 fw-bolder text-dark'>
                  {intl.formatMessage({id: 'Download.setting.name'})}
                </label>
                <input
                  placeholder={intl.formatMessage({id: 'Download.setting.name_placeholder'})}
                  {...formik.getFieldProps('name')}
                  // defaultValue={props.name}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {'is-invalid': formik.touched.name && formik.errors.name},
                    {
                      'is-valid': formik.touched.name && !formik.errors.name,
                    }
                  )}
                  type='text'
                  name='name'
                  autoComplete='off'
                />
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert'>{formik.errors.name}</span>
                  </div>
                )}
              </div>

              <div className='fv-row mb-10 mt-15'>
                <div className='d-flex justify-content-between mt-n5'>
                  <div className='d-flex flex-stack mb-2'>
                    <label className='form-label mb-5 fw-bolder text-dark fs-6 mb-0'>
                      {intl.formatMessage({id: 'Download.setting.type'})}
                    </label>
                  </div>
                </div>

                {selectMode ? (
                  <div className='row ms-2'>
                    <div className='col col-6 form-check form-check-custom form-check-solid'>
                      <input
                        className='form-check-input'
                        type='radio'
                        name='type'
                        defaultChecked={true}
                      />
                      <label className='form-check-label'>
                        {intl.formatMessage(
                          {
                            id: 'Download.setting.type.selected',
                          },
                          {files: selectedItems.length}
                        )}
                      </label>
                    </div>
                  </div>
                ) : (
                  <div className='row ms-2'>
                    <div className='col col-6 form-check form-check-custom form-check-solid'>
                      <input
                        className='form-check-input'
                        type='radio'
                        name='type'
                        checked={formik.values.type === OrderDownloadType.OUTPUT}
                        value={OrderDownloadType.OUTPUT}
                        onChange={() => formik.setFieldValue('type', OrderDownloadType.OUTPUT)}
                      />
                      <label className='form-check-label'>
                        {intl.formatMessage({id: 'Download.setting.type.output'})}
                      </label>
                    </div>
                    <div className='col col-6 form-check form-check-custom form-check-solid'>
                      <input
                        className='form-check-input'
                        type='radio'
                        name='type'
                        checked={formik.values.type === OrderDownloadType.ALL}
                        value={OrderDownloadType.ALL}
                        onChange={() => formik.setFieldValue('type', OrderDownloadType.ALL)}
                      />
                      <label className='form-check-label'>
                        {intl.formatMessage({id: 'Download.setting.type.all'})}
                      </label>
                    </div>
                  </div>
                )}
              </div>

              {/* Start: dimensions section */}

              {/* Title */}
              <div className='fv-row mb-10 mt-15'>
                <div className='d-flex justify-content-between mt-n5'>
                  <div className='d-flex flex-stack mb-2'>
                    <label className='form-label mb-5 fw-bolder text-dark fs-6 mb-0'>
                      {intl.formatMessage({id: 'Download.setting.image_dimensions'})}
                    </label>
                  </div>
                </div>

                {/* Options: Original, Image Crop, Image Resize */}
                <div className='row ms-2'>
                  {/* Original dimensions */}
                  <div className='col form-check form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='dimensions'
                      checked={dimensions === DIMENSIONS_OPTIONS.ORIGINAL}
                      onChange={() => formik.setFieldValue('dimensions', undefined)}
                    />
                    <label className='form-check-label'>
                      {intl.formatMessage({id: 'Download.setting.image_dimensions.original'})}
                    </label>
                  </div>

                  {/* Image crop */}
                  <div className='col form-check form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='dimensions'
                      checked={dimensions === DIMENSIONS_OPTIONS.CROP}
                      onChange={() =>
                        formik.setFieldValue('dimensions.changeBy', DIMENSIONS_OPTIONS.CROP)
                      }
                    />
                    <label className='form-check-label'>
                      {intl.formatMessage({id: 'Download.setting.image_dimensions.crop'})}
                    </label>
                  </div>

                  {/* Image resize */}
                  <div className='col form-check form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='dimensions'
                      checked={
                        dimensions === DIMENSIONS_OPTIONS.LONG_EDGE ||
                        dimensions === DIMENSIONS_OPTIONS.SHORT_EDGE
                      }
                      onChange={() => {
                        if (
                          dimensions !== DIMENSIONS_OPTIONS.LONG_EDGE &&
                          dimensions !== DIMENSIONS_OPTIONS.SHORT_EDGE
                        ) {
                          formik.setFieldValue('dimensions.changeBy', DIMENSIONS_OPTIONS.LONG_EDGE)
                        }
                      }}
                    />
                    <label className='form-check-label'>
                      {intl.formatMessage({id: 'Download.setting.image_dimensions.resize'})}
                    </label>
                  </div>
                </div>

                {/*Inputs*/}
                <div className='row ms-5 mt-5'>
                  {/*Input for Image Crop*/}
                  {dimensions === DIMENSIONS_OPTIONS.CROP && (
                    <>
                      <div className='col'>
                        <div className='input-group input-group-sm'>
                          {/*Long edge input*/}
                          <input
                            type='number'
                            className={clsx(
                              'form-control',
                              {
                                'is-invalid':
                                  getIn(formik.touched, 'dimensions.crop.longEdge') &&
                                  getIn(formik.errors, 'dimensions.crop.longEdge'),
                              },
                              {
                                'is-valid':
                                  getIn(formik.touched, 'dimensions.crop.longEdge') &&
                                  !getIn(formik.errors, 'dimensions.crop.longEdge'),
                              }
                            )}
                            {...formik.getFieldProps('dimensions.crop.longEdge')}
                            disabled={dimensions !== DIMENSIONS_OPTIONS.CROP}
                          />
                          <span className='input-group-text'>X</span>
                          {/*Short edge input*/}
                          <input
                            type='number'
                            className={clsx(
                              'form-control',
                              {
                                'is-invalid':
                                  getIn(formik.touched, 'dimensions.crop.shortEdge') &&
                                  getIn(formik.errors, 'dimensions.crop.shortEdge'),
                              },
                              {
                                'is-valid':
                                  getIn(formik.touched, 'dimensions.crop.shortEdge') &&
                                  !getIn(formik.errors, 'dimensions.crop.shortEdge'),
                              }
                            )}
                            {...formik.getFieldProps('dimensions.crop.shortEdge')}
                            disabled={dimensions !== DIMENSIONS_OPTIONS.CROP}
                          />
                          <span className='input-group-text'>Pixels</span>
                        </div>
                        {/*Show error */}
                        {getIn(formik.touched, 'dimensions.crop.longEdge') &&
                          getIn(formik.errors, 'dimensions.crop.longEdge') && (
                            <div className='fv-plugins-message-container'>
                              <span role='alert'>
                                {getIn(formik.errors, 'dimensions.crop.longEdge')}
                              </span>
                            </div>
                          )}
                        {getIn(formik.touched, 'dimensions.crop.shortEdge') &&
                          getIn(formik.errors, 'dimensions.crop.shortEdge') && (
                            <div className='fv-plugins-message-container'>
                              <span role='alert'>
                                {getIn(formik.errors, 'dimensions.crop.shortEdge')}
                              </span>
                            </div>
                          )}
                      </div>

                      <div className='col-4'>
                        <figure className='figure w-50'>
                          <img
                            src='/media/svg/image-dimensions/crop-portrait.svg'
                            className='figure-img img-fixed-height '
                            alt='Portrait-crop'
                          />
                          <figcaption className='figure-caption text-center'>
                            {intl.formatMessage({
                              id: 'Download.setting.image_dimensions.portrait',
                            })}
                          </figcaption>
                        </figure>
                        <figure className='figure w-50'>
                          <img
                            src='/media/svg/image-dimensions/crop-landscape.svg'
                            className='figure-img img-fixed-height'
                            alt='Landscape-crop'
                          />
                          <figcaption className='figure-caption text-center'>
                            {intl.formatMessage({
                              id: 'Download.setting.image_dimensions.landscape',
                            })}
                          </figcaption>
                        </figure>
                      </div>
                    </>
                  )}

                  {/*Input for Image Resize*/}
                  {(dimensions === DIMENSIONS_OPTIONS.LONG_EDGE ||
                    dimensions === DIMENSIONS_OPTIONS.SHORT_EDGE) && (
                    <>
                      <div className='col'>
                        <div className='row'>
                          <div className='col-5'>
                            <select
                              className='form-select form-select-sm form-select-solid'
                              value={dimensions}
                              onChange={(e) =>
                                formik.setFieldValue(
                                  'dimensions.changeBy',
                                  e.target.value as DIMENSIONS_OPTIONS
                                )
                              }
                            >
                              <option value={DIMENSIONS_OPTIONS.LONG_EDGE}>
                                {intl.formatMessage({
                                  id: 'Download.setting.image_dimensions.long_edge',
                                })}
                              </option>
                              <option value={DIMENSIONS_OPTIONS.SHORT_EDGE}>
                                {intl.formatMessage({
                                  id: 'Download.setting.image_dimensions.short_edge',
                                })}
                              </option>
                            </select>
                          </div>

                          <div className='col'>
                            <div className='input-group input-group-sm mb-3'>
                              {dimensions === DIMENSIONS_OPTIONS.LONG_EDGE && (
                                <input
                                  type='number'
                                  className={clsx(
                                    'form-control',
                                    {
                                      'is-invalid':
                                        getIn(formik.touched, 'dimensions.resize.longEdge') &&
                                        getIn(formik.errors, 'dimensions.resize.longEdge'),
                                    },
                                    {
                                      'is-valid':
                                        getIn(formik.touched, 'dimensions.resize.longEdge') &&
                                        !getIn(formik.errors, 'dimensions.resize.longEdge'),
                                    }
                                  )}
                                  {...formik.getFieldProps('dimensions.resize.longEdge')}
                                />
                              )}
                              {dimensions === DIMENSIONS_OPTIONS.SHORT_EDGE && (
                                <input
                                  type='number'
                                  className={clsx(
                                    'form-control',
                                    {
                                      'is-invalid':
                                        getIn(formik.touched, 'dimensions.resize.shortEdge') &&
                                        getIn(formik.errors, 'dimensions.resize.shortEdge'),
                                    },
                                    {
                                      'is-valid':
                                        getIn(formik.touched, 'dimensions.resize.shortEdge') &&
                                        !getIn(formik.errors, 'dimensions.resize.shortEdge'),
                                    }
                                  )}
                                  {...formik.getFieldProps('dimensions.resize.shortEdge')}
                                />
                              )}
                              <span className='input-group-text'>Pixels</span>
                            </div>
                            {getIn(formik.touched, 'dimensions.resize.longEdge') &&
                              getIn(formik.errors, 'dimensions.resize.longEdge') && (
                                <div className='fv-plugins-message-container'>
                                  <span role='alert'>
                                    {getIn(formik.errors, 'dimensions.resize.longEdge')}
                                  </span>
                                </div>
                              )}
                            {getIn(formik.touched, 'dimensions.resize.shortEdge') &&
                              getIn(formik.errors, 'dimensions.resize.shortEdge') && (
                                <div className='fv-plugins-message-container'>
                                  <span role='alert'>
                                    {getIn(formik.errors, 'dimensions.resize.shortEdge')}
                                  </span>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>

                      <div className='col-4'>
                        {dimensions === DIMENSIONS_OPTIONS.LONG_EDGE && (
                          <>
                            <figure className='figure w-50'>
                              <img
                                src='/media/svg/image-dimensions/long-edge-portrait.svg'
                                className='figure-img img-fixed-height '
                                alt='Portrait-long-edge'
                              />
                              <figcaption className='figure-caption text-center'>
                                {intl.formatMessage({
                                  id: 'Download.setting.image_dimensions.portrait',
                                })}
                              </figcaption>
                            </figure>
                            <figure className='figure w-50'>
                              <img
                                src='/media/svg/image-dimensions/long-edge-landscape.svg'
                                className='figure-img img-fixed-height rounded'
                                alt='Landscape-long-edge'
                              />
                              <figcaption className='figure-caption text-center'>
                                {intl.formatMessage({
                                  id: 'Download.setting.image_dimensions.landscape',
                                })}
                              </figcaption>
                            </figure>
                          </>
                        )}
                        {dimensions === DIMENSIONS_OPTIONS.SHORT_EDGE && (
                          <>
                            <figure className='figure w-50'>
                              <img
                                src='/media/svg/image-dimensions/short-edge-portrait.svg'
                                className='figure-img img-fixed-height '
                                alt='Portrait-long-edge'
                              />
                              <figcaption className='figure-caption text-center'>
                                {intl.formatMessage({
                                  id: 'Download.setting.image_dimensions.portrait',
                                })}
                              </figcaption>
                            </figure>
                            <figure className='figure w-50'>
                              <img
                                src='/media/svg/image-dimensions/short-edge-landscape.svg'
                                className='figure-img img-fixed-height rounded'
                                alt='Landscape-long-edge'
                              />
                              <figcaption className='figure-caption text-center'>
                                {intl.formatMessage({
                                  id: 'Download.setting.image_dimensions.landscape',
                                })}
                              </figcaption>
                            </figure>
                          </>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
              {/*End: dimensions section*/}

              {/*Start: File size section*/}
              <div className='fv-row mb-10 mt-15'>
                <div className='d-flex justify-content-between mt-n5'>
                  <div className='d-flex flex-stack mb-2'>
                    <label className='form-label mb-5 fw-bolder text-dark fs-6 mb-0'>
                      {intl.formatMessage({id: 'Download.setting.file_size'})}
                    </label>
                  </div>
                </div>

                {/* Options: Original, under 1MB, under 5MB */}
                <div className='row ms-2'>
                  {/* Original file-size */}
                  <div className='col form-check form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='file-size'
                      onChange={() => formik.setFieldValue('maxFileSize', undefined)}
                      defaultChecked
                    />
                    <label className='form-check-label'>
                      {intl.formatMessage({id: 'Download.setting.file_size.original'})}
                    </label>
                  </div>

                  {/* Under 1MB */}
                  <div className='col form-check form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='file-size'
                      onChange={() => formik.setFieldValue('maxFileSize', 1048576)}
                    />
                    <label className='form-check-label'>
                      {intl.formatMessage({id: 'Download.setting.file_size.under_1MB'})}
                    </label>
                  </div>

                  {/* Under 5MB */}
                  <div className='col form-check form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='file-size'
                      onChange={() => formik.setFieldValue('maxFileSize', 5242880)}
                    />
                    <label className='form-check-label'>
                      {intl.formatMessage({id: 'Download.setting.file_size.under_5MB'})}
                    </label>
                  </div>
                </div>
              </div>
              {/*End: File size section*/}
            </div>
            <PaymentInformation
              totalOrder={downloadOrderCredit?.paidRequestAmount ?? 0}
              className='col col-md-5'
              fiatAmount={downloadOrderCredit?.fiatAmount ?? 0}
              currency={downloadOrderCredit?.currency ?? CurrencyCode.USD}
              onPaymentMethodChange={onPaymentMethodChange}
              orderDetails={[
                {
                  itemName: intl.formatMessage({
                    id: 'SERVICE.SERVICE.' + downloadOrderCredit?.serviceName,
                  }),
                  unitPrice: downloadOrderCredit?.itemUniqueAmount ?? 0,
                  quantity: downloadOrderCredit?.paidRequestItems ?? 0,
                },
              ]}
            />
            {(downloadOrderCredit?.paidRequestAmount ?? 0) > 0 && (
              <div
                className='col col-md-12 text-end mt-3'
                style={{
                  fontStyle: 'italic',
                  fontSize: '0.9rem',
                }}
              >
                {intl.formatMessage({id: 'Download.setting.note'})}
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='d-flex justify-content-end flex-row-fluid'>
            <Button
              type='button'
              variant='secondary'
              onClick={onClose}
              className='btn btn-light me-3'
            >
              {intl.formatMessage({id: 'MODAL.PLAN.cancel_btn'})}
            </Button>

            <Button
              disabled={
                !formik.isValid ||
                (!isAllowToDownload && formik.values.paymentMethod === OrderPaymentMethod.CREDIT)
              }
              type='submit'
              variant='primary'
            >
              {loading ? (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {intl.formatMessage({id: 'Login.form.WAITING'})}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              ) : (
                intl.formatMessage({id: 'Download.button.download'})
              )}
            </Button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  )
}
export default DownloadOrderModal
