import React, {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {requestPassword} from '../redux/AuthAPI'
import {useIntl} from 'react-intl'

const initialValues = {
  email: '',
}

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const intl = useIntl()
  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({id: 'Login.email.FORMAT'}))
      .min(1, intl.formatMessage({id: 'Login.email.MIN'}))
      .max(50, intl.formatMessage({id: 'Login.email.MAX'}))
      .required(intl.formatMessage({id: 'Login.email.REQUIRED'})),
  })
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {setStatus, setErrors, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        requestPassword(values.email)
          .then(({data}) => {
            if (data.result) {
              setHasErrors(false)
            } else {
              setErrors({email: intl.formatMessage({id: 'SERVER.' + data.message})})
            }

            setLoading(false)
          })
          .catch(
            ({
              response: {
                data: {message},
              },
            }) => {
              setHasErrors(true)
              setLoading(false)
              setSubmitting(false)
              setStatus(intl.formatMessage({id: 'SERVER.' + message}))
            }
          )
      }, 1000)
    },
  })

  return (
    <>
      <form
        className='w-lg-500px form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark mb-3'>
            {intl.formatMessage({id: 'ForgotPassword.header.TITLE'})}
          </h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-400 fw-bold fs-4'>
            {intl.formatMessage({id: 'ForgotPassword.header.SUB_TITLE'})}
          </div>
          {/* end::Link */}
        </div>

        {/* begin::Title */}
        {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
              {intl.formatMessage({id: 'ForgotPassword.header.FAIL'})}
            </div>
          </div>
        )}

        {hasErrors === false && (
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-info'>
              {intl.formatMessage({id: 'ForgotPassword.header.SUCCESS'})}
            </div>
          </div>
        )}
        {/* end::Title */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>
            {intl.formatMessage({id: 'Login.form.EMAIL'})}
          </label>
          <input
            type='email'
            placeholder={intl.formatMessage({id: 'Login.form.EMAIL_HOLDER'})}
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button
            type='submit'
            id='kt_password_reset_submit'
            className='btn btn-lg btn-primary fw-bolder me-4'
          >
            <span className='indicator-label'>
              {intl.formatMessage({id: 'Registration.form.SUBMIT'})}
            </span>
            {loading && (
              <span className='indicator-progress'>
                {intl.formatMessage({id: 'Login.form.WAITING'})}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-lg btn-light-primary fw-bolder'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {intl.formatMessage({id: 'Registration.form.CANCEL'})}
            </button>
          </Link>
        </div>
        {/* end::Form group */}
      </form>
    </>
  )
}
