import { ref, onValue } from 'firebase/database'
import { database } from '../../../../setup/firebase'
import store from '../../../../setup/redux/Store'

export const listenOrderChange = (cb: (values: any) => void) => {
  const {
    auth: { user },
  } = store.getState()
  if (!user) return
  const url = `server/users/${user.id}/orders`
  const dbReference = ref(database, url);
  onValue(dbReference, (snapshot) => {
    const data = snapshot.val()
    cb(data)
  })
  return dbReference;
}
export const listenOrderDetailChange = (id: string, cb: (values: any) => void) => {
  const {
    auth: { user },
  } = store.getState()
  if (!user) return
  const url = `server/users/${user.id}/orders/${id}`
  const dbReference = ref(database, url);
  onValue(dbReference, (snapshot) => {
    const data = snapshot.val()
    cb(data)
  })
  return dbReference;
}
export const listenOrderItemsChange = (id: string, cb: (values: any) => void) => {
  const {
    auth: { user },
  } = store.getState()
  if (!user) return
  const url = `server/users/${user.id}/orders/${id}/items`
  const dbReference = ref(database, url);
  onValue(dbReference, (snapshot) => {
    const data = snapshot.val()
    cb(data)
  })
  return dbReference;
}

export const listenItemChange = (id: string, itemId: string, cb: (values: any) => void) => {
  const {
    auth: { user },
  } = store.getState()
  if (!user) return
  const url = `server/users/${user.id}/orders/${id}/items/${itemId}`
  const dbReference = ref(database, url);
  onValue(dbReference, (snapshot) => {
    const data = snapshot.val()
    cb(data)
  })
  return dbReference;
}