import {FC, useState} from 'react'
import {PaymentItemModel} from '../shared/models/PaymentItemModel'
import {useIntl} from 'react-intl'
import {useAppSelector} from '../../setup/redux/hooks'
import {selectUser} from '../modules/auth'
import {CurrencyCode, PriceType} from '../pages/pricing/pricing.constant'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import {PAYMENT_METHOD} from '../shared/constants'
import {USER_TYPE, USER_VERIFY_STATUS} from '../modules/profile/constants'
import {displayAmountBaseOnCurrency} from '../pages/pricing/utils'
import React from 'react'

type Props = {
  className?: string
  isShow: boolean
  onClose: any
  onOk: any
  paymentDetail: PaymentItemModel[]
}
const paymentMethods = [
  {
    icon: 'bi-credit-card',
    name: PAYMENT_METHOD.VISA,
    currency: [CurrencyCode.USD, CurrencyCode.JPY],
  },
  {
    icon: 'bi-bank',
    name: PAYMENT_METHOD.BANK,
    currency: [CurrencyCode.JPY],
  },
]
const PaymentModal: FC<Props> = (props) => {
  const intl = useIntl()
  const user = useAppSelector(selectUser)
  const totalAmount = props.paymentDetail.reduce(
    (accumulator, item) => accumulator + item.unitPrice * item.quantity,
    0
  )

  const [paymentMethod, setPaymentMethod] = useState(PAYMENT_METHOD.VISA)

  return (
    <Modal
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      show={props.isShow}
      onHide={props.onClose}
      backdrop='static'
    >
      <Modal.Header closeButton>
        <h2 className='fw-bolder'>{intl.formatMessage({id: 'payment.header'})}</h2>
      </Modal.Header>

      <Modal.Body>
        <div
          style={{backgroundColor: '#f5f8fa'}}
          className={props.className + ' rounded border p-5'}
        >
          <div className='pb-5 '>
            <h3 className='pb-3 fw-bolder d-flex align-items-center text-dark'>
              {intl.formatMessage({id: 'Download.setting.payment'})}
            </h3>
            <table className='table mb-5 table-row-bordered table-row-gray-100 align-middle gs-0 gy-3 text-center'>
              <thead>
                <tr className='fw-bolder text-muted'>
                  <th className='min-w-130px text-center'>
                    {intl.formatMessage({id: 'Order.payment.service'})}
                  </th>
                  <th className='min-w-80px'>{intl.formatMessage({id: 'Order.payment.unit'})}</th>
                  <th className='min-w-80px'>
                    {intl.formatMessage({id: 'Order.payment.currency'})}
                  </th>
                  <th className='min-w-80px'>{intl.formatMessage({id: 'Order.payment.type'})}</th>
                  <th className='min-w-80px'>
                    {intl.formatMessage({id: 'Order.payment.quantity'})}
                  </th>
                  <th className='min-w-100px '>
                    {intl.formatMessage({id: 'Order.payment.total'})}
                  </th>
                </tr>
              </thead>

              <tbody>
                {props.paymentDetail &&
                  props.paymentDetail.map((order: PaymentItemModel, index: number) => (
                    <tr key={index}>
                      <td className='text-left'>{order.itemName}</td>
                      <td>{displayAmountBaseOnCurrency(order.unitPrice, order.currency)}</td>
                      <td>{order.currency}</td>
                      <td>{intl.formatMessage({id: order.paymentType ?? 'UNKNOWN'})}</td>
                      <td>{order.quantity}</td>
                      <td>
                        {displayAmountBaseOnCurrency(
                          order.unitPrice * order.quantity,
                          order.currency
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <div className='d-flex justify-content-between'>
              <div className='fs-4'> {intl.formatMessage({id: 'Download.payment.total'})}</div>
              <div className='fw-bolder fs-3'>
                {displayAmountBaseOnCurrency(totalAmount, props.paymentDetail[0].currency)}
              </div>
            </div>
            <div className='d-flex justify-content-between'>
              <div className='fs-4'> </div>
              <div className='fs-7 text-danger'>
                {props.paymentDetail[0].currency === CurrencyCode.JPY &&
                  intl.formatMessage({id: 'MODAL.PLAN.tax_guide'})}
              </div>
            </div>
          </div>
          <div className='fv-row'>
            <div className='pb-5 fs-4 d-flex fw-bolder justify-content-between'>
              <div> {intl.formatMessage({id: 'Download.payment'})}</div>
            </div>
            <div className='row'>
              {paymentMethods.map((method, index) => {
                const isDisableBank =
                  method.name === PAYMENT_METHOD.BANK &&
                  user &&
                  (user.verifyStatus !== USER_VERIFY_STATUS.VERIFIED ||
                    props.paymentDetail[0].currency !== CurrencyCode.JPY ||
                    user.userType !== USER_TYPE.ENTERPRISE ||
                    props.paymentDetail[0].paymentType !== PriceType.SUBSCRIPTION)
                return (
                  <div
                    className='col-lg-12'
                    key={index}
                    onClick={() => {
                      if (isDisableBank && method.name === PAYMENT_METHOD.BANK) {
                        return
                      }
                      setPaymentMethod(method.name)
                    }}
                  >
                    <label
                      className={
                        (paymentMethod === method.name
                          ? 'active'
                          : isDisableBank
                          ? 'disabled'
                          : '') +
                        ' btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10'
                      }
                    >
                      <i className={'bi ' + method.icon + ' fs-3x me-5'}></i>

                      <span className='d-block fw-bold text-start'>
                        <span className='text-dark fw-bolder d-block fs-4 mb-2'>
                          {intl.formatMessage({id: 'payment.' + method.name + '.title'})}
                        </span>
                        <span className='text-gray-400 fw-bold fs-6'>
                          {intl
                            .formatMessage({id: 'payment.' + method.name + '.info'})
                            .split('\n')
                            .map((line, ind) => (
                              <React.Fragment key={ind}>
                                {line}
                                <br />
                              </React.Fragment>
                            ))}
                        </span>
                      </span>
                    </label>
                  </div>
                )
              })}
              {paymentMethod === PAYMENT_METHOD.BANK && (
                <div className='mx-1'>
                  <div className='d-flex bg-light-warning rounded border-warning border border-dashed p-6'>
                    <i className='bi bi-exclamation-circle-fill fs-2tx text-warning me-4'></i>
                    <div className='d-flex flex-stack flex-grow-1'>
                      <div className='fw-bold'>
                        <h4 className='text-gray-800 fw-bolder'>
                          {intl.formatMessage({id: 'payment.BANK.notice'})}
                        </h4>
                        <div className='fs-6 text-gray-600'>
                          {intl.formatMessage({id: 'payment.BANK.notice.des'})}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className='text-danger mt-2'></div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className='d-flex justify-content-end flex-row-fluid'>
          <Button
            type='button'
            variant='secondary'
            onClick={props.onClose}
            className='btn btn-light me-3'
          >
            {intl.formatMessage({id: 'MODAL.PLAN.cancel_btn'})}
          </Button>

          <Button onClick={() => props.onOk(paymentMethod)} type='submit' variant='primary'>
            {intl.formatMessage({id: 'Order.payment.paid'})}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}
export default PaymentModal
